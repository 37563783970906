import React from 'react';
import {fips} from './StateFIPS';
import { DropdownForm } from "./SelectionComponents";

export default function TopNav(props) {
	if (props.type === "national") {
		return NationalTopNav();
	}
	else if (props.type === "state") {
		return StateTopNav(props);
	}
	else if (props.type === "tribal"){
		return TribalTopNav(props);
	}
	else{
		return PlainTopNav(props)
	}
}

function NationalTopNav(){
	return (<div className = "topnav">
		<a href="#top"><img id = "banner-logo" src="/maplogo.png" alt="Technology Policy Institute Logo"/></a>
		{/* <button id="pdf"> Export PDF</button> */}
		<a id = "link" href="#scatter">Data Correlation</a>
		<a id = "link" href="#top">Map and Time Series</a>
		<a id = "link" href="/sources">Dataset Descriptions</a>
		{/* <a id = "link" href="https://tpibroadband.tribeplatform.com/">User Forum</a> */}
	</div>)
}

function StateTopNav(props){
	return (<div className = "topnav">
		<a id = "title" className="active" href="/"><img id = "banner-logo" src="/maplogo.png" alt="Technology Policy Institute Logo"/></a>
		{/* <button id="pdf"> Export PDF</button> */}
		<DropdownForm id={"state-change"} value={props.stateId.name} data={fips} onChangeFunction={props.handleStateChange}/>
		<a id = "link" href="#scatter">Data Correlation</a>
		<a id = "link" href="#top">Map and Time Series</a>
		<a id = "link" href="/sources">Dataset Descriptions</a>
		<a id = "link" href={"/state/" + props.stateId.name}>Back to Overview</a>
	</div>)
}

function TribalTopNav(props){
	return (<div className = "topnav">
		<a id = "title" className="active" href="/"><img id = "banner-logo" src="/maplogo.png" alt="Technology Policy Institute Logo"/></a>
		{/* <button id="pdf"> Export PDF</button> */}
		<DropdownForm id={"tribal-change"} value={props.tribalId.name} data={fips} onChangeFunction={props.handleTribalChange}/>
		<a id = "link" href="#scatter">Data Correlation</a>
		<a id = "link" href="#top">Map and Time Series</a>
		<a id = "link" href="/sources">Dataset Descriptions</a>
		<a id = "link" href={"/tribal/" + props.tribalId.name}>Back to Overview</a>
	</div>)
}

function PlainTopNav(props){
	return (<div className = "topnav">
		<a id = "title" className="active" href="/"><img id = "banner-logo" src="/maplogo.png" alt="Technology Policy Institute Logo"/></a>
		</div>)
}
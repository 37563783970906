import React from "react";
import { VictoryLine, VictoryChart, VictoryAxis, VictoryTooltip, VictoryVoronoiContainer, VictoryLegend } from "victory";
import { timeParse } from "d3-time-format";
import { Form477Data, consumerDownload, percentBroadband } from "./Form477";
import { fabricData, served253 } from "./FCCFabricData";
import { usState, county, nation, aggregatedAmericanIndianArea2024} from "./GeographicAreaObjects";
import { getBaseURL } from "./getQuery";

import PropTypes from "prop-types";
import { stateEBB, zipEBB, countyEBB } from "./EmergencyBroadbandBenefit";
import { stateACP, zipACP, countyACP } from "./AffordableConnectivityProgram";
import { getTickFormat, monthTick } from "./GraphControls";
const saveSvgAsPng = require("save-svg-as-png");

const colors = ["black", "red", "green", "blue", "yellow", "orange", "navy", "purple", "pink", "brown", "grey"];

const imageOptions = {
	scale: 5,
	encoderOptions: 1,
	backgroundColor: "white"
  };
  export const Timeseries = React.forwardRef(function Timeseries(props, ref) {
	// console.log(props.data);
	return (<div ref={ref}>
		<VictoryChart 
			//I'm pretty sure this needs to be dynamic
			height={props.height} width={props.height}
			domainPadding={{ x: 20 }}
			minDomain={{ y: 0 }}
			scale={{ x: "time" }}
			containerComponent={<VictoryVoronoiContainer/>}>
			{props.ebbacp ? <VictoryAxis label={props.xLabel} tickFormat={(t) => monthTick(t)} style={{tickLabels: { fill: (!props.data.length ? "transparent" : "black")}}}/>
			: <VictoryAxis label={props.xLabel} tickFormat={(t) => monthTick(t)} style={{tickLabels: { fill: (!props.data.length ? "transparent" : "black")}}}/>}
			<VictoryAxis label={props.yLabel} tickFormat={(t) => getTickFormat(t)} dependentAxis style={{ tickLabels: { fill: (!props.data.length ? "transparent" : "black"), angle: 270 } }} />
			{props.data.map((dataElement, index) => <VictoryLine key={dataElement.id} data={dataElement.data}
				labels={({ datum }) => (datum.label)}
				labelComponent={<VictoryTooltip/>}
				style={{data: {stroke: colors[index % 11]}}} />)}
		</VictoryChart>
		<VictoryLegend
			orientation="vertical"
			gutter={20}
			data={props.data.map((dataElement, index) => ({"name": dataElement.name, "symbol": {"fill": colors[index % 11], "type": "square" } }))} />
	</div>);
});

Timeseries.propTypes = {
	data: PropTypes.object.isRequired,
	height:PropTypes.number,
	xLabel: PropTypes.object.isRequired,
	yLabel: PropTypes.object.isRequired,
};

export class TimeseriesComponent extends React.Component {
	static propTypes = {
		graphType: PropTypes.object.isRequired
	};

	constructor(props) {
		super(props);	
		this.state = {
			area: props.stateId ? county : props.tribalId ? aggregatedAmericanIndianArea2024 : usState,
			dataSource: fabricData,
			variable: served253,
			fetchedData: [],
			nationalData: [],
			graphType: props.graphType,
			areaIds: [],
			broadbandDownload: 25,
			broadbandUpload: 3,
			loading: false,
			stateId: props.stateId,
			tribalId: props.tribalId,
		};
		this.containerRef = React.createRef();
		this.fetchData = this.fetchData.bind(this);
		this.parseGraphData = this.parseGraphData.bind(this);
		this.updateState = this.updateState.bind(this);
		this.updateBroadbandStates = this.updateBroadbandStates.bind(this);
		this.clearAll = this.clearAll.bind(this);
		this.fetchNewData =this.fetchNewData.bind(this);
		this.fetchAllData = this.fetchAllData.bind(this);
		this.fetchNationalData = this.fetchNationalData.bind(this);
		this.fetchStateData = this.fetchStateData.bind(this);
		this.handleExport = this.handleExport.bind(this);
		this.checkForEBBACP = this.checkForEBBACP.bind(this);
	}

	componentDidMount() {
		this.fetchAllData();
	}

	parseGraphData(data, areaName, variable, dataSource, area) {
		console.log(area);
		console.log(data);
		console.log(data[area.plural]);
		if (variable === percentBroadband){
			return Object.keys(data[0]).map((key) => ({ "x": timeParse("%b%Y")(key), "y": data[0][key], "label": areaName}));
		}
		const retrivedData = Object.keys(data[area.plural][0]).map((key) => ({ "x": timeParse(variable.code + dataSource.parser)(key), "y": data[area.plural][0][key], "label":  areaName}));
		return retrivedData;
	}

	updateState(variable, object){
		console.log("Hello!")
		console.log(variable)
		console.log(object)
		if(variable === "area"){
			this.setState({areaIds: []});
		}
		this.setState({[variable] : object}, this.fetchAllData);
	}

	updateBroadbandStates(download, upload){
		this.setState({broadbandDownload: download, broadbandUpload: upload}, this.fetchAllData);
	}

	dataYearReducer(query, month) {
		return ((month.code.slice(-2) === "5y" || (month.code !== "fixedBroadbandSubscribers20201y" && month.code.slice(-6) === "20201y") || (this.state.variable.code.slice(0, 16) === "mobile5gCoverage" && month.code.slice(-7) !== "Dec2019" && month.code.slice(-7) !== "Jun2020" && month.code.slice(-7) !== "Dec2020")) ? query : query + this.state.variable.code + month.code + " ");
	};

	async fetchData(model, loc, dataSource1, variable, download, upload, dataParser){
		console.log(upload);
		console.log(download);
		if (loc.id) {
			loc.stateId = loc.id;
		}
		console.log(loc);
		if(!model.dataSources.some(o => o.name === dataSource1.name)){
			return [];
		} 
		const dataSource = model.dataSources.filter(o => o.name === dataSource1.name)[0];
		if(!dataSource.dataTypes.some(o => o.name === variable.name)){
			return [];
		}
		const queriesTemp = this.state.variable === percentBroadband ? (getBaseURL() + "/broadbandPercent?model=" + model.schemaName + "&loc=" + loc[model.id] + "&download=" + download + "&upload=" + upload) : 
			(dataSource.dataYears.reduce((query, month) => this.dataYearReducer(query, month), getBaseURL() + "/graphql?query={ " + model.plural + (model !== nation ? " (" + model.id + ":\"" + loc[model.id] + "\")" : "") + " { "));
		const query = this.state.variable === percentBroadband ? queriesTemp : queriesTemp + "}}";
		console.log(query);
		const response = await fetch(query);
		console.log(response);
		const results = await response.json();
		console.log(results.data);
		const organized = {"id": loc[model.id], "name": loc.name, "data": dataParser(results.data, loc.name, variable, dataSource, model)};
		console.log(organized);
		return organized;
	}

	async fetchNationalData() {
		if (this.checkForEBBACP()){
			this.setState({nationalData: []});
			return;
		}
		const loc = {"geoid": "0100000US", "name": "National"};
		const data = await this.fetchData(nation, loc, this.state.dataSource, this.state.variable, this.state.broadbandDownload, this.state.broadbandUpload, this.parseGraphData);
		console.log(data);
		this.setState({ nationalData: [data] });
	}

	async fetchStateData() {
		const data = await this.fetchData(usState, this.state.stateId, this.state.dataSource, this.state.variable, this.state.broadbandDownload, this.state.broadbandUpload, this.parseGraphData);
		this.setState({nationalData: [data]});
	}

	async fetchAllData() {
		this.setState({loading: true});
		this.state.stateId != null ? this.fetchStateData(this.state.stateId) : this.fetchNationalData();
		if (this.state.areaIds.length != 0) {
			const data = await Promise.all(this.state.areaIds.map(async (areaId) => {
				try {
					const idData = await this.fetchData(this.state.area, areaId, this.state.dataSource, this.state.variable, this.state.broadbandDownload, this.state.broadbandUpload, this.parseGraphData);
					return idData;
				} catch(err) {
					throw err;
				 }
			}));
			this.setState({ fetchedData: data, loading: false });
		}
		else{
			this.setState({fetchedData: []});
		}
	}

	async fetchNewData(newId){
		const newData = await this.fetchData(this.state.area, newId, this.state.dataSource, this.state.variable, this.state.broadbandDownload, this.state.broadbandUpload, this.parseGraphData);
		this.setState({fetchedData: [...this.state.fetchedData, newData]});
	}

	organizeBroadbandPercentData(result, areaId) {
		const rawData = result.map((monthData, i) => ({ "x": timeParse(Form477Data.parser)(Form477Data.dataYears[i].code), "y": monthData.data.censusBlocks.reduce((acc, area) => (area.population + acc), 0) }));
		const popQuery = getBaseURL() + "/graphql?query={ " + this.state.area.plural + " (" + this.state.area.id + ": \"" + areaId[this.state.area.id] + "\"){ population }}";
		fetch(popQuery, { method: "get" })
			.then((response) => response.json())
			.then((results) => this.setState({ fetchedData: [...this.state.fetchedData, { "name": areaId.name, "id": areaId[this.state.area.id], "data": rawData.map((rawMonth) => ({ "x": rawMonth.x, "y": rawMonth.y * 1.0 / (results.data[this.state.area.plural][0].population) * 100, "label": areaId.name, "id": areaId[this.state.area.id] })) }] }));
	}
	addLine(newId){
		// console.log(this.state.areaIds.some(o => newId[this.state.area.id] === o[this.state.area.id]));
		const newIds = this.state.areaIds.some(o => newId[this.state.area.id] === o[this.state.area.id]) ? this.state.areaIds.filter(o => newId[this.state.area.id] !== o[this.state.area.id]) : [...this.state.areaIds, newId];
		this.state.areaIds.some(o => newId[this.state.area.id] === o[this.state.area.id]) ? this.setState({fetchedData: this.state.fetchedData.filter(o => newId[this.state.area.id] !== o["id"])}) : this.fetchNewData(newId);
		this.setState({ areaIds: newIds }, console.log(this.state.areaIds.length));
	}
	clearAll(){this.setState({areaIds: [], fetchedData: []});}

	handleExport() {
		console.log(this.containerRef.current);
		saveSvgAsPng.saveSvgAsPng(this.containerRef.current.firstChild.firstChild, "broadband_timeseries.png", imageOptions);
	}

	checkForEBBACP(){
		return (this.state.dataSource === stateEBB || this.state.dataSource === zipEBB || this.state.dataSource === countyEBB || this.state.dataSource === stateACP || this.state.dataSource === zipACP || this.state.dataSource === countyACP) ? true : false;
	}

	render() {
		console.log([...this.state.nationalData, ...this.state.fetchedData]);
		return (
			this.state.dataSource.dataYears.length <= 1 ? <div className="timeseries-chart-grid"></div> :
			<div className="timeseries-chart-grid">
				{/*pass height of window as prop to chart*/}
				<div className="timeseries-chart">
					{this.state.areaIds.length === 0 ? <div className="map-click-tip-container" style={{height: window.innerWidth*0.28}}>
						<div className="map-click-tip">Click on areas on the map to see variable change over time.</div>
					</div> : <></>}
					<Timeseries ref={this.containerRef} graphType={this.state.graphType} height={window.innerWidth*0.28} data={[...this.state.nationalData, ...this.state.fetchedData]} ebbacp={this.checkForEBBACP()} xLabel="Time" yLabel={this.state.variable.name + (this.state.variable.label === "%" ? "" : (" (" + this.state.variable.label + ")")) + (this.state.variable === percentBroadband ? (" (" + this.state.broadbandDownload + "/" + this.state.broadbandUpload + ")") : "")} />
				</div>
				<div className="timeseries-selector-title"><p>Data Over Time</p></div>
				<div className="timeseries-selector-description"><p>Click on areas on the map to see variable change over time</p><p> (click again to remove area). </p></div>
				<div className="timeseries-clear"><button onClick={this.clearAll}>Clear All Selections</button></div>
				{/* <button className="timeseries-download-button" onClick={this.handleExport}>Download Timeseries</button> */}
			</div>
		);
	}

}
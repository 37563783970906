import React, { useEffect } from "react";

export default function Coffee() {
  // const
  useEffect(() => {
  //   const button = document.getElementsByClassName("bmc-btn-container")[0];
  //   console.log(button);
  //   const div = document.getElementById("supportByBMC");
  //   div.appendChild(button);
  //   console.log(div);
  //   // oldParent.removeChild(button);
  // }, []);
      const script = document.createElement("script");
    const div = document.getElementById("supportByBMC");
    script.setAttribute(
      "src",
      "https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js"
    );
    script.setAttribute("data-name", "BMC-Widget");
    script.setAttribute("data-cfasync", "false");
    script.setAttribute("data-id", "tpibroadband");
    script.setAttribute("data-description", "Support me on Buy me a coffee!");
    script.setAttribute(
      "data-message",
      "This mapping project is a labor of love. We have no direct government, foundation, or industry financial support for it. Please consider donating to help us keep it running."
    );
    script.setAttribute("data-color", "#FF5F5F");
    script.setAttribute("data-position", "Right");
    script.setAttribute("data-x_margin", "18");
    script.setAttribute("data-y_margin", "18");

    script.onload = function () {
    	var evt = document.createEvent("Event");
    	evt.initEvent("DOMContentLoaded", false, false);
    	window.dispatchEvent(evt);

		// Show this div on mouseover:
		// <div style="position: fixed; right: 102px; bottom: 16px; background-color: rgb(255, 255, 255); z-index: 9999; box-shadow: rgba(13, 12, 34, 0.1) -6px 0px 30px; padding: 23px 24px; border-top-left-radius: 4px; border-top-right-radius: 4px; border-bottom-right-radius: 4px; border-bottom-left-radius: 4px; font-size: 18px; color: rgb(13, 12, 34); width: auto; max-width: 260px; line-height: 1.5; font-family: &quot;Avenir Book1&quot;, &quot;Avenir Book2&quot;, &quot;Avenir Book3&quot;, &quot;Avenir Book4&quot;, &quot;Avenir Book5&quot;, &quot;Avenir Book6&quot;, sans-serif; display: none; opacity: 0;">Buying a single coffee for me is 1000 times worth than a Thankyou </div>
		const buttonDiv = document.getElementById("bmc-wbtn");
		const div2 = document.createElement("div");
		div2.setAttribute("style", "position: fixed; right: 102px; bottom: 16px; background-color: rgb(255, 255, 255); z-index: 9999; box-shadow: rgba(13, 12, 34, 0.1) -6px 0px 30px; padding: 23px 24px; border-top-left-radius: 4px; border-top-right-radius: 4px; border-bottom-right-radius: 4px; border-bottom-left-radius: 4px; font-size: 18px; color: rgb(13, 12, 34); width: auto; max-width: 260px; line-height: 1.5; font-family: 'Avenir Book1', 'Avenir Book2', 'Avenir Book3', 'Avenir Book4', 'Avenir Book5', 'Avenir Book6', sans-serif;");
		div2.innerHTML = "This mapping project is a labor of love. We have no direct government, foundation, or industry financial support for it. Please consider donating to help us keep it running.";
		buttonDiv.onmouseover = function() {
			console.log("div mouseover");
			buttonDiv.appendChild(div2);
		}
		buttonDiv.onmouseout = function() {
			buttonDiv.removeChild(div2);
		}
	};
  
  div.appendChild(script);
}, []);

  return <div id="supportByBMC"></div>;
}

import React from "react";
import { VictoryLine, VictoryChart, VictoryScatter, VictoryAxis, VictoryTooltip } from "victory";
import { Form477Data, consumerDownload, consumerUpload, percentBroadband, MobileForm477Data, percent4g, dec2015, jun2016, dec2016, dec2017, jun2018, dec2018, jun2019, dec2019, jun2020, dec2020, jun2021, dec2021 } from "./Form477";
import { locations, stateLocations, usState, county, dataTypes, tribalLocations, tribalTract2024 } from "./GeographicAreaObjects";
import { getBaseURL, getQuery } from "./getQuery";
import { DropdownForm } from "./SelectionComponents";
import { BroadbandFields, BroadbandFieldsSimple } from "./GeographicForm";
import {fixedBroadbandSubscribers, stateACS, oneYear2020, oneYear2019} from "./ACSInternetSubscribers";
import { fabricData, served253 } from "./FCCFabricData";
import { getTickFormat } from "./GraphControls";
import PropTypes from "prop-types";

const saveSvgAsPng = require("save-svg-as-png");

// class xVariable {
// 	constructor(datasource, variable, time) {
// 		this.datasource = datasource;
// 		this.variable = variable;
// 		this.time = time;
// 	}
// }

const imageOptions = {
	scale: 5,
	encoderOptions: 1,
	backgroundColor: "white"
  };

//Actually display graphs
const Scatter = React.forwardRef(function Scatter(props, ref) {
	const xMax = props.data.reduce((acc, curr) => (curr.x > acc ? curr.x : acc), 0);
	const xStart = xMax * 0.05;
	const yStart = xStart*props.ols.beta_1 + props.ols.beta_0;
	const xEnd = xMax * 1.05;
	const yEnd = xEnd*props.ols.beta_1 + props.ols.beta_0;
	const count = props.data.length;
	const dotSize = 30/Math.sqrt(count);
	return (<div className="scatter">
		<div className="scatterInfo">
			<p>R^2: {props.r2.toFixed(3)}</p>
			<p>Best Fit: {props.yVariable + " ="}</p>
			<p>{props.ols.beta_1.toFixed(3) + "*" + props.xVariable + " + " + props.ols.beta_0.toFixed(3)}</p>
		</div>
		<div className="scatterPlot" ref={ref}>
			<VictoryChart 
			// height={400} width={400}
				domainPadding={{ x: 20 }}
				minDomain={{ x: 0, y: 0 }}>
				<VictoryAxis label={props.xLabel} tickFormat={(t) => getTickFormat(t)} />
				<VictoryAxis label={props.yLabel} tickFormat={(t) => getTickFormat(t)} dependentAxis style={{ tickLabels: { angle: 270 } }} />
				<VictoryScatter data={props.data} size={dotSize} labels={({ datum }) => (datum.label)} labelComponent={<VictoryTooltip/>} />
				<VictoryLine style={{data: {stroke: "#A4CAFA"}}} data={[{x: xStart, y: yStart}, {x:xEnd, y: yEnd}]} />
			</VictoryChart>
		</div>
	</div>);
});


Scatter.propTypes = {
	data: PropTypes.object.isRequired,
	ols: PropTypes.object.isRequired,
	r2: PropTypes.object.isRequired,
	yVariable: PropTypes.object.isRequired,
	xVariable: PropTypes.object.isRequired,
	yLabel: PropTypes.object.isRequired,
	xLabel: PropTypes.object.isRequired,
};

//Organize Graphs and Graph Data
export class ScatterComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dataTypes: dataTypes,
			locations: props.stateId != null ? stateLocations : props.tribalId != null ? tribalLocations : locations,
			regArea: props.stateId != null ? county : props.tribalId != null ? tribalTract2024 : usState,
			xDataSource: Form477Data,
			xVariable: consumerDownload,
			xTime: Form477Data.dataYears[Form477Data.dataYears.length - 1],
			yDataName: "Broadband Fabric Locations",
			yDataSource: fabricData,
			yVariable: served253,
			yTime: fabricData.dataYears[fabricData.dataYears.length - 1],
			r2: 1.0,
			ols: {beta_1: 1.111111, beta_0: 0.111111},
			se: {beta_1: 1.111111, beta_0: 0.111111},
			t: {beta_1: 0, beta_0: 0},
			p: {beta_0: 1, beta_1: 1},
			fetchedData: [],
			xDownload: 25,
			xUpload: 3,
			yDownload: 25,
			yUpload: 3,
			variableView: 0,
			xVariables: [],
			stateId: props.stateId,
			tribalId: props.tribalId
		};
		this.containerRef = React.createRef();
		this.fetchData = this.fetchData.bind(this);
		this.organizeScatterData = this.organizeScatterData.bind(this);
		this.handleXDataChange = this.handleXDataChange.bind(this);
		this.handleXVariableChange = this.handleXVariableChange.bind(this);
		this.handleXTimeChange = this.handleXTimeChange.bind(this);
		this.handleYDataChange = this.handleYDataChange.bind(this);
		this.handleRegAreaChange = this.handleRegAreaChange.bind(this);
		this.handleYVariableChange = this.handleYVariableChange.bind(this);
		this.handleYTimeChange = this.handleYTimeChange.bind(this);
		this.fetchOLS = this.fetchOLS.bind(this);
		this.handleXDownloadChange = this.handleXDownloadChange.bind(this);
		this.handleXUploadChange = this.handleXUploadChange.bind(this);
		this.handleYDownloadChange = this.handleYDownloadChange.bind(this);
		this.handleYUploadChange = this.handleYUploadChange.bind(this);
		this.mergeScatterData = this.mergeScatterData.bind(this);
		this.handleVariableView = this.handleVariableView.bind(this);
		this.handleExport = this.handleExport.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData() {
		this.fetchOLS();
		if(this.state.xVariable !== percentBroadband && this.state.yVariable !== percentBroadband){
			const query = "{ " + this.state.regArea.plural + (this.state.stateId != null? '(stateId: "' + this.state.stateId.id + '")' : '') + (this.state.tribalId != null? '(AIANNHCE: "' + this.state.tribalId.id + '")' : '') + "{" + this.state.xVariable.code + this.state.xTime.code + " " + this.state.yVariable.code + this.state.yTime.code + " name }}";
			console.log(query);
			getQuery(query)
				.then(results => this.setState({ fetchedData: results["data"][this.state.regArea.plural].map((element) => this.organizeScatterData(element)) }));
		}
		else{
			const query = getBaseURL() + "/graphql?query={ " + this.state.regArea.plural + (this.state.stateId != null? '(stateId: "' + this.state.stateId.id + '")' : '') + (this.state.tribalId != null? '(AIANNHCE: "' + this.state.tribalId.id + '")' : '') + "{" + this.state.regArea.id + " name " + (this.state.xVariable.code !== "percentBroadband" ? this.state.xVariable.code + this.state.xTime.code + " " : "") + (this.state.yVariable.code !== "percentBroadband" ? this.state.yVariable.code + this.state.yTime.code + " " : "") + "}}";
			console.log(query);
			//If both are percent broadband for the same year, but with different down/up
			//We can't distingish which is which
			if(this.state.xVariable === percentBroadband && this.state.yVariable === percentBroadband){
				const xQuery = `${getBaseURL()}/broadband_percent?area=${this.state.regArea.name}${this.state.stateId != null ? "&state=" + this.state.stateId.id : ""}${this.state.tribalId != null ? "&tribe=" + this.state.tribalId.id : ""}&download=${this.state.xDownload}&upload=${this.state.xUpload}&time=${this.state.xTime.code}${this.state.xTime.code === this.state.yTime.code ? "&same=true" : ""}`;
				const yQuery = `${getBaseURL()}/broadband_percent?area=${this.state.regArea.name}${this.state.stateId != null ? "&state=" + this.state.stateId.id : ""}${this.state.tribalId != null ? "&tribe=" + this.state.tribalId.id : ""}&download=${this.state.yDownload}&upload=${this.state.yUpload}&time=${this.state.yTime.code}${this.state.xTime.code === this.state.yTime.code ? "&same=true" : ""}`;
				Promise.all([query, xQuery, yQuery].map(u => fetch(u, { method: "get" })))
					.then(function (responses) {
						return Promise.all(responses.map(function (response) {
							return response.json();
						}));
					}).then(results => this.mergeScatterData(results.map(e => e.data)));
			}
			else if(this.state.xVariable === percentBroadband && this.state.yVariable !== percentBroadband){
				const xQuery = `${getBaseURL()}/broadband_percent?area=${this.state.regArea.name}${this.state.stateId != null ? "&state=" + this.state.stateId.id : ""}${this.state.tribalId != null ? "&tribe=" + this.state.tribalId.id : ""}&download=${this.state.xDownload}&upload=${this.state.xUpload}&time=${this.state.xTime.code}`;
				Promise.all([query, xQuery].map(u => fetch(u, { method: "get" })))
					.then(function (responses) {
						return Promise.all(responses.map(function (response) {
							return response.json();
						}));
					}).then(results => this.mergeScatterData(results.map(e => e.data)));
			}
			else if(this.state.yVariable === percentBroadband && this.state.xVariable !== percentBroadband){
				const yQuery = `${getBaseURL()}/broadband_percent?area=${this.state.regArea.name}${this.state.stateId != null ? "&state=" + this.state.stateId.id : ""}${this.state.tribalId != null ? "&tribe=" + this.state.tribalId.id : ""}&download=${this.state.yDownload}&upload=${this.state.yUpload}&time=${this.state.yTime.code}`;
				console.log(yQuery);
				Promise.all([query, yQuery].map(u => fetch(u, { method: "get" })))
					.then(function (responses) {
						return Promise.all(responses.map(function (response) {
							const responseJson = response.json();
							return responseJson;
						}));
					}).then(results => this.mergeScatterData(results.map(e => e.data)));
			} 
		}
	}

	mergeScatterData(data){
		console.log(data);
		const firstData = data[0][this.state.regArea.plural];
		console.log(firstData);
		const self = this;
		const dataDict = firstData.reduce(function(map, obj) {
			map[obj[self.state.regArea.id]] = obj;
			return map;
		}, {});
		console.log(data.slice(1));
		const mergedData = data.slice(1).reduce(function(fullData, dataTable){
			return dataTable.reduce(function(map, obj){
				for(const key in obj){
					map[obj[self.state.regArea.id]][key] = obj[key];
				}
				console.log(map);
				return map;
			}, fullData);}, dataDict);
		this.setState({ fetchedData: Object.values(mergedData).map(e => this.organizeScatterData(e))});
	}

	organizeScatterData(data) {
		const broadbandSame = this.state.xTime.code === this.state.yTime.code && this.state.xVariable === percentBroadband && this.state.yVariable === percentBroadband;
		return { "x": data[this.state.xVariable.code + this.state.xTime.code + (broadbandSame ? "d" + this.state.xDownload + "u" + this.state.xUpload : "")], "y": data[this.state.yVariable.code + this.state.yTime.code + (broadbandSame ? "d" + this.state.yDownload + "u" + this.state.yUpload : "")], "label": data.name };
	}

	handleXDataChange(event) {
		const newData = this.state.regArea.dataSources.find(e => e.name === event.target.value);
		this.setState({
			xDataSource: newData,
			xVariable: newData.dataTypes[0],
			xTime: newData.dataYears[newData.dataYears.length - 1]
		}, this.fetchData);
	}

	//This works but is too messy, I need to refactor it (and probably this whole file) soon (defintely before )
	handleYDataChange(event) {
		const allLocations = this.state.stateId != null ? stateLocations : this.state.tribalId != null ? tribalLocations : locations;
		const newLocations = allLocations.filter(e => e.dataSources.filter(f => f.name === event.target.value).length > 0);
		const contains = newLocations.some(e => e.name === this.state.regArea.name);
		const newArea = contains ? this.state.regArea : newLocations[0];
		const newData = contains ? this.state.regArea.dataSources.find(e => e.name === event.target.value) : newArea.dataSources.find(e => e.name === event.target.value);
		if(!contains){
			const containsX = newArea.dataSources.some(e => e.name === this.state.xDataSource.name);
			const newXData = containsX ? this.state.xDataSource : newArea.dataSources[0];
			const containsXVariable = newXData.dataTypes.some(e => e.name === this.state.xVariable.name);
			const newXVariable = containsXVariable ? this.state.xVariable : newXData.dataTypes[0];
			const containsXTime = newXData.dataYears.some(e => e.name === this.state.xTime.name);
			const newXTime = containsXTime ? this.state.xTime : newXData.dataYears[newXData.dataYears.length - 1];
			console.log(newXData);
			this.setState({
				regArea: newArea,
				locations: newLocations,
				yDataName: event.target.value,
				yDataSource: newData,
				yVariable: newData.dataTypes[0],
				yTime: newData.dataYears[newData.dataYears.length - 1],
				xDataSource: newXData,
				xVariable: newXVariable,
				xTime: newXTime,	
			}, this.fetchData);
		}
		else {
			this.setState({
				regArea: newArea,
				locations: newLocations,
				yDataName: event.target.value,
				yDataSource: newData,
				yVariable: newData.dataTypes[0],
				yTime: newData.dataYears[newData.dataYears.length - 1]
			}, this.fetchData);
			this.checkNewAreaXData(newArea);
		}
	}

	handleRegAreaChange(event) {
		console.log(event);
		const newArea = this.state.locations.find(e => e.name === event.target.value);
		const newData = newArea.dataSources.find(e => e.name === this.state.yDataName);
		const containsYVariable = newData.dataTypes.some(e => e.name === this.state.yVariable.name);
		const newYVariable = containsYVariable ? this.state.yVariable : newData.dataTypes[0];
		const containsYTime = newData.dataYears.some(e => e.name === this.state.yTime.name);
		const newYTime = containsYTime ? this.state.yTime : newData.dataYears[newData.dataYears.length - 1];
		const containsX = newArea.dataSources.some(e => e.name === this.state.xDataSource.name);
		console.log(containsX);
		const newXData = containsX ? newArea.dataSources.find(e => e.name === this.state.xDataSource.name) : newArea.dataSources[0];
		console.log(newXData);
		const containsXVariable = newXData.dataTypes.some(e => e.name === this.state.xVariable.name);
		const newXVariable = containsXVariable ? this.state.xVariable : newXData.dataTypes[0];
		const containsXTime = newXData.dataYears.some(e => e.name === this.state.xTime.name);
		const newXTime = containsXTime ? this.state.xTime : newXData.dataYears[newXData.dataYears.length - 1];
		console.log(newXTime);
		this.setState({
			regArea: newArea,
			yDataSource: newData,
			yVariable: newYVariable,
			yTime: newYTime,
			xDataSource: newXData,
			xVariable: newXVariable,
			xTime: newXTime,
			fetchData: null
		}, this.fetchData);
	}

	handleXVariableChange(event) {
		this.setState({ xVariable: this.state.xDataSource.dataTypes.find(e => e.name === event.target.value), xTime: this.state.xDataSource === stateACS && this.state.xTime === oneYear2020 ? oneYear2019 : this.state.xDataSource === MobileForm477Data && [dec2015, jun2016, dec2016, dec2017, jun2018, dec2018, jun2019].includes(this.state.xTime) ? dec2019 : this.state.xTime }, this.fetchData);
	}

	handleXTimeChange(event) {
		this.setState({ xTime: this.state.xDataSource.dataYears.find(e => e.name === event.target.value) }, this.fetchData);
	}

	handleYVariableChange(event) {
		this.setState({ yVariable: this.state.yDataSource.dataTypes.find(e => e.name === event.target.value),
			yTime: this.state.yDataSource === stateACS && this.state.yTime === oneYear2020 ? oneYear2019 : this.state.yDataSource === MobileForm477Data && [dec2015, jun2016, dec2016, dec2017, jun2018, dec2018, jun2019].includes(this.state.yTime) ? dec2019 : this.state.yTime }, this.fetchData);
	}

	handleYTimeChange(event) {
		this.setState({ yTime: this.state.yDataSource.dataYears.find(e => e.name === event.target.value) }, this.fetchData);
	}

	fetchOLS() {
		const query = `${getBaseURL()}/ols?source=${this.state.regArea.schemaName}${this.state.stateId != null ? "&state=" + this.state.stateId.id : ""}${this.state.tribalId != null ? "&tribe=" + this.state.tribalId.id : ""}&xVariable=${this.state.xVariable.code}${this.state.xTime.code}${this.state.xVariable === percentBroadband ? "d" + this.state.xDownload + "u" + this.state.xUpload : ""}
							&yVariable=${this.state.yVariable.code}${this.state.yTime.code}${this.state.yVariable === percentBroadband ? "d" + this.state.yDownload + "u" + this.state.yUpload : ""}`;
		fetch(query, { method: "get" }).then((response) => response.json()).then((results) => this.setState({ ols: results.ols, r2: results.r2, se: results.errors, t:results.tvalues, p:results.pvalues }));
	}

	handleXDownloadChange(event) {
		this.setState({ xDownload: event.target.value }, this.fetchData);
	}

	handleXUploadChange(event) {
		this.setState({ xUpload: event.target.value }, this.fetchData);
	}

	handleYDownloadChange(event) {
		this.setState({ yDownload: event.target.value }, this.fetchData);
	}

	handleYUploadChange(event) {
		this.setState({ yUpload: event.target.value }, this.fetchData);
	}

	handleVariableView(level) {
		this.setState({ variableView: level});
		console.log(this.state.variableView);
	}

	handleExport() {
		console.log(this.containerRef.current);
		saveSvgAsPng.saveSvgAsPng(this.containerRef.current.firstChild.firstChild, "broadband_scatter_plot.png", imageOptions);
	}

	render() {
		return (
			<div className={"scatter-chart-grid" + (this.state.xVariable === percentBroadband ? " xBroadband" : "") + (this.state.yVariable === percentBroadband ? " yBroadband" : "")}>
				<div className="scatter-selector-title"><p>Data Correlations</p></div>
				<div className="variable-view-navigator">
					<button onClick={() => this.handleVariableView(0)} className={this.state.variableView === 0 ? "selected-difficulty" : ""}>Simple</button>
					<button onClick={() => this.handleVariableView(1)} className={this.state.variableView === 1 ? "selected-difficulty" : ""}>Complicated</button>
					<button onClick={() => this.handleVariableView(2)} className={this.state.variableView === 2 ? "selected-difficulty" : ""}>Advanced</button>
					{/* <button onClick={() => this.handleVariableView(3)} className={this.state.variableView === 3 ? "selected-difficulty" : ""}>Multivariate</button> */}
				</div>
				{this.state.variableView === 1 ? 
					//Complicated Variable View
					<div className="x-y-variables">
						<div className={this.state.yVariable === percentBroadband ? "scatter-y-variable broadband" : "scatter-y-variable"}>
							<p className="scatter-variable-title">Y Variable</p>
							<p className="scatter-y-data-title">Data Type</p>
							<div className="scatter-y-data-dropdown"><DropdownForm id="yData" value={this.state.yDataSource.name} onChangeFunction={this.handleYDataChange} data={this.state.dataTypes} /></div>
							<p className="scatter-y-area-title">Area Type</p>
							<div className="scatter-y-area-dropdown"><DropdownForm id="regArea" value={this.state.regArea.name} onChangeFunction={this.handleRegAreaChange} data={this.state.locations} /></div>
							<p className="scatter-y-variable-title">Data Variable</p>
							<div className="scatter-y-variable-dropdown"><DropdownForm id="yVariable" onChangeFunction={this.handleYVariableChange} data={this.state.yDataSource.dataTypes} value={this.state.yVariable.name} keyCode={4} /></div>
							<p className="scatter-y-time-title">Data Time Period</p>
							<div className="scatter-y-time-dropdown"><DropdownForm id="yTime" onChangeFunction={this.handleYTimeChange} data={this.state.yDataSource !== stateACS || this.state.yVariable === fixedBroadbandSubscribers ? ((this.state.yDataSource !== MobileForm477Data || this.state.yVariable === percent4g) ? this.state.yDataSource.dataYears : [dec2019, jun2020, dec2020, jun2021, dec2021]) : this.state.yDataSource.dataYears.filter(year => year !== oneYear2020)} value={this.state.yTime.name} keyCode={3} /></div>
							{this.state.yVariable === percentBroadband && <div className={"scatter-updown"}><BroadbandFields downloadSpeed={this.state.yDownload} uploadSpeed={this.state.yUpload} handleBroadbandDownloadChange={this.handleYDownloadChange} handleBroadbandUploadChange={this.handleYUploadChange} class={"scatter-broadband"} /></div>}
						</div>
						<div className={this.state.xVariable === percentBroadband ? "scatter-x-variable broadband" : "scatter-x-variable"}>
							<p className="scatter-variable-title">X Variable</p>
							<p className="scatter-x-data-title">Data Source</p>
							<div className="scatter-x-data-dropdown"><DropdownForm id="xData" value={this.state.xDataSource.name} onChangeFunction={this.handleXDataChange} data={this.state.regArea.dataSources} /></div>
							<p className="scatter-x-variable-title">Data Variable</p>
							<div className="scatter-x-variable-dropdown"><DropdownForm id="xVariable" onChangeFunction={this.handleXVariableChange} data={this.state.xDataSource.dataTypes} value={this.state.xVariable.name} keyCode={3} /></div>
							<p className="scatter-x-time-title">Data Time Period</p>
							<div className="scatter-x-time-dropdown"><DropdownForm id="xTime" onChangeFunction={this.handleXTimeChange} data={this.state.xDataSource !== stateACS || this.state.xVariable === fixedBroadbandSubscribers ? ((this.state.xDataSource !== MobileForm477Data || this.state.xVariable === percent4g) ? this.state.xDataSource.dataYears : [dec2019, jun2020, dec2020, jun2021, dec2021]) : this.state.xDataSource.dataYears.filter(year => year !== oneYear2020)} value={this.state.xTime.name} keyCode={2} /></div>
							{this.state.xVariable === percentBroadband && <div className={"scatter-updown"}><BroadbandFields downloadSpeed={this.state.xDownload} uploadSpeed={this.state.xUpload} handleBroadbandDownloadChange={this.handleXDownloadChange} handleBroadbandUploadChange={this.handleXUploadChange} class={"scatter-broadband"} /></div>}
						</div>
					</div> 
					: this.state.variableView === 0 ? 
						//Easy Variable View
						<div className="x-y-variables">
							{/* <div className={this.state.xVariable == percentBroadband ? "scatter-x-variable broadband" : "scatter-x-variable"}>
								<p className="scatter-variable-title">X Variable</p>
								<p className="scatter-x-data-title">Data Source</p>
								<div className="scatter-x-data-dropdown"><DropdownForm id="xData" value={this.state.xDataSource.name} onChangeFunction={this.handleXDataChange} data={this.state.regArea.dataSources} /></div>
								<p className="scatter-x-variable-title">Data Variable</p>
								<div className="scatter-x-variable-dropdown"><DropdownForm id="xVariable" onChangeFunction={this.handleXVariableChange} data={this.state.xDataSource.dataTypes} value={this.state.xVariable.name} keyCode={3} /></div>
								<p className="scatter-x-time-title">Data Time Period</p>
								<div className="scatter-x-time-dropdown"><DropdownForm id="xTime" onChangeFunction={this.handleXTimeChange} data={this.state.xDataSource.dataYears} value={this.state.xTime.name} keyCode={2} /></div>
								{this.state.xVariable == percentBroadband && <div className={"scatter-updown"}><BroadbandFields downloadSpeed={this.state.xDownload} uploadSpeed={this.state.xUpload} handleBroadbandDownloadChange={this.handleXDownloadChange} handleBroadbandUploadChange={this.handleXUploadChange} class={"scatter-broadband"} /></div>}
							</div> */}
							
							<p className='simple-variable'>
								How does the&nbsp;
								<div className="scatter-y-variable-dropdown"><DropdownForm id="yVariable" onChangeFunction={this.handleYVariableChange} data={this.state.yDataSource.dataTypes} value={this.state.yVariable.name} keyCode={4} /></div>
								, as measured by the&nbsp;
								<div className="scatter-y-data-dropdown"><DropdownForm id="yData" value={this.state.yDataSource.name} onChangeFunction={this.handleYDataChange} data={this.state.dataTypes} /></div>
								&nbsp;data source at the&nbsp; 
								<div className="scatter-y-area-dropdown"><DropdownForm id="regArea" value={this.state.regArea.name} onChangeFunction={this.handleRegAreaChange} data={this.state.locations} /></div>
								&nbsp;level during &nbsp;
								<div className="scatter-y-time-dropdown"><DropdownForm id="yTime" onChangeFunction={this.handleYTimeChange} data={this.state.yDataSource !== stateACS || this.state.yVariable === fixedBroadbandSubscribers ? ((this.state.yDataSource !== MobileForm477Data || this.state.yVariable === percent4g) ? this.state.yDataSource.dataYears : [dec2019, jun2020, dec2020, jun2021, dec2021]) : this.state.yDataSource.dataYears.filter(year => year !== oneYear2020)} value={this.state.yTime.name} keyCode={3} /></div>
								&nbsp;
								{this.state.yVariable === percentBroadband && <><BroadbandFieldsSimple downloadSpeed={this.state.yDownload} uploadSpeed={this.state.yUpload} handleBroadbandDownloadChange={this.handleYDownloadChange} handleBroadbandUploadChange={this.handleYUploadChange} class={"scatter-broadband"} />&nbsp;</>}
								correlate with&nbsp;
								<div className="scatter-x-variable-dropdown"><DropdownForm id="xVariable" onChangeFunction={this.handleXVariableChange} data={this.state.xDataSource.dataTypes} value={this.state.xVariable.name} keyCode={3} /></div>
								, as measured by the&nbsp; 
								<div className="scatter-x-data-dropdown"><DropdownForm id="xData" value={this.state.xDataSource.name} onChangeFunction={this.handleXDataChange} data={this.state.regArea.dataSources} /></div>
								&nbsp;data source during&nbsp; 
								<div className="scatter-x-time-dropdown"><DropdownForm id="xTime" onChangeFunction={this.handleXTimeChange} data={this.state.xDataSource !== stateACS || this.state.xVariable === fixedBroadbandSubscribers ? ((this.state.xDataSource !== MobileForm477Data || this.state.xVariable === percent4g) ? this.state.xDataSource.dataYears : [dec2019, jun2020, dec2020, jun2021, dec2021]) : this.state.xDataSource.dataYears.filter(year => year !== oneYear2020)} value={this.state.xTime.name} keyCode={2} /></div>
								&nbsp;{this.state.xVariable === percentBroadband && <BroadbandFieldsSimple downloadSpeed={this.state.xDownload} uploadSpeed={this.state.xUpload} handleBroadbandDownloadChange={this.handleXDownloadChange} handleBroadbandUploadChange={this.handleXUploadChange} class={"scatter-broadband"} />}?
								.
							</p>
							{/* <div className={this.state.yVariable == percentBroadband ? "scatter-y-variable broadband" : "scatter-y-variable"}>
								<p className="scatter-variable-title">Y Variable</p>
								<p className="scatter-y-data-title">Data Type</p>
								<div className="scatter-y-data-dropdown"><DropdownForm id="yData" value={this.state.yDataSource.name} onChangeFunction={this.handleYDataChange} data={this.state.dataTypes} /></div>
								<p className="scatter-y-area-title">Area Type</p>
								<div className="scatter-y-area-dropdown"><DropdownForm id="regArea" value={this.state.regArea.name} onChangeFunction={this.handleRegAreaChange} data={this.state.locations} /></div>
								<p className="scatter-y-variable-title">Data Variable</p>
								<div className="scatter-y-variable-dropdown"><DropdownForm id="yVariable" onChangeFunction={this.handleYVariableChange} data={this.state.yDataSource.dataTypes} value={this.state.yVariable.name} keyCode={4} /></div>
								<p className="scatter-y-time-title">Data Time Period</p>
								<div className="scatter-y-time-dropdown"><DropdownForm id="yTime" onChangeFunction={this.handleYTimeChange} data={this.state.yDataSource.dataYears} value={this.state.yTime.name} keyCode={3} /></div>
								{this.state.yVariable == percentBroadband && <div className={"scatter-updown"}><BroadbandFields downloadSpeed={this.state.yDownload} uploadSpeed={this.state.yUpload} handleBroadbandDownloadChange={this.handleYDownloadChange} handleBroadbandUploadChange={this.handleYUploadChange} class={"scatter-broadband"} /></div>}
							</div> */}
						</div> 
						: 
						//Advanced Variable View
						<div className="x-y-variables">
							<div className={this.state.yVariable === percentBroadband ? "scatter-y-variable broadband" : "scatter-y-variable"}>
								<p className="scatter-variable-title">Y Variable</p>
								<p className="scatter-y-data-title">Data Type</p>
								<div className="scatter-y-data-dropdown"><DropdownForm id="yData" value={this.state.yDataSource.name} onChangeFunction={this.handleYDataChange} data={this.state.dataTypes} /></div>
								<p className="scatter-y-area-title">Area Type</p>
								<div className="scatter-y-area-dropdown"><DropdownForm id="regArea" value={this.state.regArea.name} onChangeFunction={this.handleRegAreaChange} data={this.state.locations} /></div>
								<p className="scatter-y-variable-title">Data Variable</p>
								<div className="scatter-y-variable-dropdown"><DropdownForm id="yVariable" onChangeFunction={this.handleYVariableChange} data={this.state.yDataSource.dataTypes} value={this.state.yVariable.name} keyCode={4} /></div>
								<p className="scatter-y-time-title">Data Time Period</p>
								<div className="scatter-y-time-dropdown"><DropdownForm id="yTime" onChangeFunction={this.handleYTimeChange} data={this.state.yDataSource !== stateACS || this.state.yVariable === fixedBroadbandSubscribers ? this.state.yDataSource.dataYears : this.state.yDataSource.dataYears.filter(year => year !== oneYear2020)} value={this.state.yTime.name} keyCode={3} /></div>
								{this.state.yVariable === percentBroadband && <div className={"scatter-updown"}><BroadbandFields downloadSpeed={this.state.yDownload} uploadSpeed={this.state.yUpload} handleBroadbandDownloadChange={this.handleYDownloadChange} handleBroadbandUploadChange={this.handleYUploadChange} class={"scatter-broadband"} /></div>}
							</div>
							<div className={this.state.xVariable === percentBroadband ? "scatter-x-variable broadband" : "scatter-x-variable"}>
								<p className="scatter-variable-title">X Variable</p>
								<p className="scatter-x-data-title">Data Source</p>
								<div className="scatter-x-data-dropdown"><DropdownForm id="xData" value={this.state.xDataSource.name} onChangeFunction={this.handleXDataChange} data={this.state.regArea.dataSources} /></div>
								<p className="scatter-x-variable-title">Data Variable</p>
								<div className="scatter-x-variable-dropdown"><DropdownForm id="xVariable" onChangeFunction={this.handleXVariableChange} data={this.state.xDataSource.dataTypes} value={this.state.xVariable.name} keyCode={3} /></div>
								<p className="scatter-x-time-title">Data Time Period</p>
								<div className="scatter-x-time-dropdown"><DropdownForm id="xTime" onChangeFunction={this.handleXTimeChange} data={this.state.xDataSource !== stateACS || this.state.xVariable === fixedBroadbandSubscribers ? this.state.xDataSource.dataYears : this.state.xDataSource.dataYears.filter(year => year !== oneYear2020)} value={this.state.xTime.name} keyCode={2} /></div>
								{this.state.xVariable === percentBroadband && <div className={"scatter-updown"}><BroadbandFields downloadSpeed={this.state.xDownload} uploadSpeed={this.state.xUpload} handleBroadbandDownloadChange={this.handleXDownloadChange} handleBroadbandUploadChange={this.handleXUploadChange} class={"scatter-broadband"} /></div>}
							</div>
							<div className="regression-table">
								<div className="regression-table-title">Regression Table</div>
								<div className="regression-table-body">
									<table className="table table-striped table-bordered">
										<tbody>
											<tr>
												<th>Variable</th>
												<th>Coeff</th>
												<th>Std. Error</th>
												<th>t</th>
												<th>P</th>
											</tr>
											<tr>
												<td>Constant</td>
												<td>{this.state.ols.beta_0.toFixed(2)}</td>
												<td>{this.state.se.beta_0.toFixed(2)}</td>
												<td>{this.state.t.beta_0.toFixed(2)}</td>
												<td>{this.state.p.beta_0.toFixed(2)}</td>
											</tr>
											<tr>
												<td>{this.state.xVariable.name + " (" + this.state.xTime.name + ")"}</td>
												<td>{this.state.ols.beta_1.toFixed(2)}</td>
												<td>{this.state.se.beta_1.toFixed(2)}</td>
												<td>{this.state.t.beta_1.toFixed(2)}</td>
												<td>{this.state.p.beta_1.toFixed(2)}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
				}
				<div className="scatter-chart"><Scatter ref={this.containerRef} graphType={this.state.graphType} data={this.state.fetchedData} r2={this.state.r2} ols={this.state.ols}
					xLabel={this.state.xVariable.name + " " + this.state.xTime.name + (this.state.xVariable.label === "%" ? "" : (" (" + this.state.xVariable.label + ")"))}
					yLabel={this.state.yVariable.name + " " + this.state.yTime.name + (this.state.yVariable.label === "%" ? "" : (" (" + this.state.yVariable.label + ")"))}
					xVariable={this.state.xVariable.name + " (" + this.state.xTime.name + ")"}
					yVariable={this.state.yVariable.name + " (" + this.state.yTime.name + ")"}/>
				</div>
				{/* <button className="scatter-download-button" onClick={this.handleExport}>Download Scatter Plot</button> */}
			</div>
		);
	}
}

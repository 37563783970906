import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { GeographicForm } from "./GeographicForm";
import { Sources }from "./sources";
import { StateForm, StateSelector } from "./StateForm";
import { TribeSelector } from "./TribeForm";
import { SimpleForm } from "./SimpleForm";
import { DataDownload } from "./DataDownload";

if(document.getElementById('root')){
	ReactDOM.render(
		<GeographicForm type="national" />,
		document.getElementById("root")
	);
  }
  
if(document.getElementById('sources-root')){
	ReactDOM.render(
		<Sources />,
		document.getElementById("sources-root")
	);
}

if(document.getElementById('states-root')){
	ReactDOM.render(
		<GeographicForm type="state" />,
		document.getElementById("states-root")
	);
}

if(document.getElementById('statesSelector-root')){
	ReactDOM.render(
		<StateSelector />,
		document.getElementById("statesSelector-root")
	);
}

if(document.getElementById('tribes-root')){
	ReactDOM.render(
		<GeographicForm type="tribe" />,
		document.getElementById("tribes-root")
	);
}

if(document.getElementById('tribeSelector-root')){
	ReactDOM.render(
		<TribeSelector />,
		document.getElementById("tribeSelector-root")
	);
}

if(document.getElementById('simple-root')){
	ReactDOM.render(
		<SimpleForm type="simple" />,
		document.getElementById("simple-root")
  );
}

if(document.getElementById('data-download-root')){
	ReactDOM.render(
		<DataDownload />,
		document.getElementById("data-download-root")
	);
}


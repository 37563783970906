import { DataVariable, CodeAndEnglish, DataSource } from "./ModelClasses";

//Fabric Aggregation
//["served253", "unserved253", "served10020", "unserved10020", "underserved", "lowLatencyServed253", "lowLatencyUnserved253", "lowLatencyServed10020", "lowLatencyUnserved10020", "lowLatencyUnderserved"]
export const served253 = new DataVariable("served253", "Locations served at 25/3", "%")
export const unserved253 = new DataVariable("unserved253", "Locations unserved at 25/3", "%")
export const served10020 = new DataVariable("served10020", "Locations served at 100/20", "%")
export const unserved10020 = new DataVariable("unserved10020", "Locations unserved at 100/20", "%")
export const underserved = new DataVariable("underserved", "Underserved locations", "%")
export const lowLatencyServed253 = new DataVariable("lowLatencyServed253", "Locations served at 25/3 with low latency", "%")
export const lowLatencyUnserved253 = new DataVariable("lowLatencyUnserved253", "Locations unserved at 25/3 with low latency", "%")
export const lowLatencyServed10020 = new DataVariable("lowLatencyServed10020", "Locations served at 100/20 with low latency", "%")
export const lowLatencyUnserved10020 = new DataVariable("lowLatencyUnserved10020", "Locations unserved at 100/20 with low latency", "%")
export const lowLatencyUnderserved = new DataVariable("lowLatencyUnderserved", "Underserved locations with low latency", "%")
export const fabricDsl = new DataVariable("fabricDsl", "Locations with DSL Access", "%")
export const fabricCable = new DataVariable("fabricCable", "Locations with Cable Access", "%")
export const fabricFiber = new DataVariable("fabricFiber", "Locations with Fiber Access", "%")
export const fabricCompanies = new DataVariable("fabricCompanies", "Avg. Number of Companies", "%")
//["served_locations_25_3", "unserved_locations_25_3", "served_locations_100_20", "served_locations_100_100", "unserved_locations_100_20", "underserved_locations", "low_latency_served_locations_25_3", "low_latency_unserved_locations_25_3", "low_latency_served_locations_100_20", "low_latency_unserved_locations_100_20", "low_latency_underserved_locations"]
//To camel case
//["servedLocations253", "unservedLocations253", "servedLocations10020", "servedLocations100100", "unservedLocations10020", "underservedLocations", "lowLatencyServedLocations253", "lowLatencyUnservedLocations253", "lowLatencyServedLocations10020", "lowLatencyUnservedLocations10020", "lowLatencyUnderservedLocations"]
export const servedLocations253 = new DataVariable("servedLocations253", "# Locations served at 25/3", "")
export const unservedLocations253 = new DataVariable("unservedLocations253", "# Locations unserved at 25/3", "")
export const servedLocations10020 = new DataVariable("servedLocations10020", "# Locations served at 100/20", "")
export const servedLocations100100 = new DataVariable("servedLocations100100", "# Locations served at 100/100", "")
export const unservedLocations10020 = new DataVariable("unservedLocations10020", "# Locations unserved at 100/20", "")
export const underservedLocations = new DataVariable("underservedLocations", "# Underserved locations", "")
export const lowLatencyServedLocations253 = new DataVariable("lowLatencyServedLocations253", "# Locations served at 25/3 with low latency", "")
export const lowLatencyUnservedLocations253 = new DataVariable("lowLatencyUnservedLocations253", "# Locations unserved at 25/3 with low latency", "")
export const lowLatencyServedLocations10020 = new DataVariable("lowLatencyServedLocations10020", "# Locations served at 100/20 with low latency", "")
export const lowLatencyUnservedLocations10020 = new DataVariable("lowLatencyUnservedLocations10020", "# Locations unserved at 100/20 with low latency", "")
export const lowLatencyUnderservedLocations = new DataVariable("lowLatencyUnderservedLocations", "# Underserved locations with low latency", "")

//FCC Aggregation
//['fixedResDl0Ul0Pct', 'fixedBusDl0Ul0Pct', 'fixedResDl10Ul1Pct', 'fixedBusDl10Ul1Pct', 'fixedResDl25Ul3Pct', 'fixedBusDl25Ul3Pct', 'fixedResDl100Ul20Pct', 'fixedBusDl100Ul20Pct', 'fixedResDl250Ul25Pct', 'fixedBusDl250Ul25Pct', 'fixedResDl1000Ul100Pct', 'fixedBusDl1000Ul100Pct', 'mobileSt3gAreaPct', 'mobileIv3gAreaPct', 'mobileSt4gAreaPct', 'mobileIv4gAreaPct', 'mobileSt5gDl7Ul1AreaPct', 'mobileIv5gDl7Ul1AreaPct', 'mobileSt5gDl35Ul3AreaPct', 'mobileIv5gDl35Ul3AreaPct']
// export const fixedResDl0Ul0Pct = new DataVariable("fixedResDl0Ul0Pct", "Residential units with fixed broadband with 0.2/0.2 Mbps", "%")
// export const fixedBusDl0Ul0Pct = new DataVariable("fixedBusDl0Ul0Pct", "Business units fixed broadband with 0.2/0.2 Mbps", "%")
// export const fixedResDl10Ul1Pct = new DataVariable("fixedResDl10Ul1Pct", "Residential units with fixed broadband with 10/1 Mbps", "%")
// export const fixedBusDl10Ul1Pct = new DataVariable("fixedBusDl10Ul1Pct", "Business units fixed broadband with 10/1 Mbps", "%")
// export const fixedResDl25Ul3Pct = new DataVariable("fixedResDl25Ul3Pct", "Residential units with fixed broadband with 25/3 Mbps", "%")
// export const fixedBusDl25Ul3Pct = new DataVariable("fixedBusDl25Ul3Pct", "Business units fixed broadband with 25/3 Mbps", "%")
// export const fixedResDl100Ul20Pct = new DataVariable("fixedResDl100Ul20Pct", "Residential units with fixed broadband with 100/20 Mbps", "%")
// export const fixedBusDl100Ul20Pct = new DataVariable("fixedBusDl100Ul20Pct", "Business units fixed broadband with 100/20 Mbps", "%")
// export const fixedResDl250Ul25Pct = new DataVariable("fixedResDl250Ul25Pct", "Residential units with fixed broadband with 250/25 Mbps", "%")
// export const fixedBusDl250Ul25Pct = new DataVariable("fixedBusDl250Ul25Pct", "Business units fixed broadband with 250/25 Mbps", "%")
// export const fixedResDl1000Ul100Pct = new DataVariable("fixedResDl1000Ul100Pct", "Residential units with fixed broadband with 1000/100 Mbps", "%")
// export const fixedBusDl1000Ul100Pct = new DataVariable("fixedBusDl1000Ul100Pct", "Business units fixed broadband with 1000/100 Mbps", "%")
export const beadEligibleTechnologyDl02Ul02R = new DataVariable("beadEligibleTechnologyDl02Ul02R", "Residential units with broadband at 0.2/0.2 Mbps", "%")
export const beadEligibleTechnologyDl10Ul1R = new DataVariable("beadEligibleTechnologyDl10Ul1R", "Residential units with broadband at 10/1 Mbps", "%")
export const beadEligibleTechnologyDl25Ul3R = new DataVariable("beadEligibleTechnologyDl25Ul3R", "Residential units with broadband at 25/3 Mbps", "%")
export const beadEligibleTechnologyDl100Ul20R = new DataVariable("beadEligibleTechnologyDl100Ul20R", "Residential units with broadband at 100/20 Mbps", "%")
export const beadEligibleTechnologyDl250Ul25R = new DataVariable("beadEligibleTechnologyDl250Ul25R", "Residential units with broadband at 250/25 Mbps", "%")
export const beadEligibleTechnologyDl1000Ul100R = new DataVariable("beadEligibleTechnologyDl1000Ul100R", "Residential units with broadband at 1000/100 Mbps", "%")
export const beadEligibleTechnologyDl50Ul5R = new DataVariable("beadEligibleTechnologyDl50Ul5R", "Residential units with broadband at 50/5 Mbps", "%")
export const beadEligibleTechnologyDl02Ul02B = new DataVariable("beadEligibleTechnologyDl02Ul02B", "Business units with broadband at 0.2/0.2 Mbps", "%")
export const beadEligibleTechnologyDl10Ul1B = new DataVariable("beadEligibleTechnologyDl10Ul1B", "Business units with broadband at 10/1 Mbps", "%")
export const beadEligibleTechnologyDl25Ul3B = new DataVariable("beadEligibleTechnologyDl25Ul3B", "Business units with broadband at 25/3 Mbps", "%")
export const beadEligibleTechnologyDl100Ul20B = new DataVariable("beadEligibleTechnologyDl100Ul20B", "Business units with broadband at 100/20 Mbps", "%")
export const beadEligibleTechnologyDl250Ul25B = new DataVariable("beadEligibleTechnologyDl250Ul25B", "Business units with broadband at 250/25 Mbps", "%")
export const beadEligibleTechnologyDl1000Ul100B = new DataVariable("beadEligibleTechnologyDl1000Ul100B", "Business units with broadband at 1000/100 Mbps", "%")
export const beadEligibleTechnologyDl50Ul5B = new DataVariable("beadEligibleTechnologyDl50Ul5B", "Business units with broadband at 50/5 Mbps", "%")
export const mobileSt3gAreaPct = new DataVariable("mobilebb3gAreaStPct", "Outdoor Mobile 3G coverage", "%")
export const mobileIv3gAreaPct = new DataVariable("mobilebb3gAreaIvPct", "In-vehicle Mobile 3G coverage", "%")
export const mobileSt4gAreaPct = new DataVariable("mobilebb4gAreaStPct", "Outdoor Mobile 4G coverage", "%")
export const mobileIv4gAreaPct = new DataVariable("mobilebb4gAreaIvPct", "In-vehicle Mobile 4G coverage", "%")
export const mobileSt5gDl7Ul1AreaPct = new DataVariable("mobilebb5gDl7Ul1AreaStPct", "Outdoor Mobile 5G coverage with 7/1 Mbps", "%")
export const mobileIv5gDl7Ul1AreaPct = new DataVariable("mobilebb5gDl7Ul1AreaIvPct", "In-vehicle Mobile 5G coverage with 7/1 Mbps", "%")
export const mobileSt5gDl35Ul3AreaPct = new DataVariable("mobilebb5gDl35Ul3AreaStPct", "Outdoor Mobile 5G coverage with 35/3 Mbps", "%")
export const mobileIv5gDl35Ul3AreaPct = new DataVariable("mobilebb5gDl35Ul3AreaIvPct", "In-vehicle Mobile 5G coverage with 35/3 Mbps", "%")

//TODO: Add the rest of the summary data to the downloader exclusively

//Mobile Aggregation
//mobile_fabric_3g_outdoor, mobile_fabric_3g_in_vehicle, mobile_fabric_4g_outdoor, mobile_fabric_4g_in_vehicle, mobile_fabric_5g_7_1_outdoor, mobile_fabric_5g_7_1_in_vehicle, mobile_fabric_5g_35_3_outdoor, mobile_fabric_5g_35_3_in_vehicle
export const mobileFabric3gOutdoor = new DataVariable("mobileFabric3gOutdoor", "Outdoor Mobile 3G coverage", "%")
export const mobileFabric3gInVehicle = new DataVariable("mobileFabric3gInVehicle", "In-vehicle Mobile 3G coverage", "%")
export const mobileFabric4gOutdoor = new DataVariable("mobileFabric4gOutdoor", "Outdoor Mobile 4G coverage", "%")
export const mobileFabric4gInVehicle = new DataVariable("mobileFabric4gInVehicle", "In-vehicle Mobile 4G coverage", "%")
export const mobileFabric5g71Outdoor = new DataVariable("mobileFabric5g71Outdoor", "Outdoor Mobile 5G coverage with 7/1 Mbps", "%")
export const mobileFabric5g71InVehicle = new DataVariable("mobileFabric5g71InVehicle", "In-vehicle Mobile 5G coverage with 7/1 Mbps", "%")
export const mobileFabric5g353Outdoor = new DataVariable("mobileFabric5g353Outdoor", "Outdoor Mobile 5G coverage with 35/3 Mbps", "%")
export const mobileFabric5g353InVehicle = new DataVariable("mobileFabric5g353InVehicle", "In-vehicle Mobile 5G coverage with 35/3 Mbps", "%")

export const jun2022 = new CodeAndEnglish("Jun2022", "Jun 2022")
export const dec2022 = new CodeAndEnglish("Dec2022", "Dec 2022")
export const jun2023 = new CodeAndEnglish("Jun2023", "Jun 2023")
export const dec2023 = new CodeAndEnglish("Dec2022", "Dec 2023")

export const _063022 = new CodeAndEnglish("063022", "Jun 2022")
export const _123122 = new CodeAndEnglish("123122", "Dec 2022")
export const _063023 = new CodeAndEnglish("063023", "Jun 2023")
export const _123123 = new CodeAndEnglish("123123", "Dec 2023")

export const fabricData = new DataSource("Broadband Fabric Locations", [served253, unserved253, served10020, unserved10020, underserved, lowLatencyServed253, lowLatencyUnserved253, lowLatencyServed10020, lowLatencyUnserved10020, lowLatencyUnderserved, servedLocations253, unservedLocations253, servedLocations10020, servedLocations100100, unservedLocations10020, underservedLocations, lowLatencyServedLocations253, lowLatencyUnservedLocations253, lowLatencyServedLocations10020, lowLatencyUnservedLocations10020, lowLatencyUnderservedLocations, fabricDsl, fabricCable, fabricFiber, fabricCompanies], [jun2022, dec2022, jun2023, dec2023], "%b%Y");
export const fccFabricFixed = new DataSource("FCC Broadband Fabric Units", [beadEligibleTechnologyDl02Ul02R, beadEligibleTechnologyDl10Ul1R, beadEligibleTechnologyDl25Ul3R, beadEligibleTechnologyDl100Ul20R, beadEligibleTechnologyDl250Ul25R, beadEligibleTechnologyDl1000Ul100R, beadEligibleTechnologyDl50Ul5R, beadEligibleTechnologyDl02Ul02B, beadEligibleTechnologyDl10Ul1B, beadEligibleTechnologyDl25Ul3B, beadEligibleTechnologyDl100Ul20B, beadEligibleTechnologyDl250Ul25B, beadEligibleTechnologyDl1000Ul100B, beadEligibleTechnologyDl50Ul5B], [_063022, _123122, _063023, _123123], "%b%Y");
export const fccFabricMobile = new DataSource("FCC Broadband Fabric Mobile Coverage", [mobileSt3gAreaPct, mobileIv3gAreaPct, mobileSt4gAreaPct, mobileIv4gAreaPct, mobileSt5gDl7Ul1AreaPct, mobileIv5gDl7Ul1AreaPct, mobileSt5gDl35Ul3AreaPct, mobileIv5gDl35Ul3AreaPct], [_063022, _123122, _063023, _123123], "%b%Y");
export const simpleFabric = new DataSource("Availability", [served253, served10020], [jun2022, dec2022, jun2023, dec2023], "%b%Y");
export const mobileFabricData = new DataSource("BDC Mobile Coverage", [mobileFabric3gOutdoor, mobileFabric3gInVehicle, mobileFabric4gOutdoor, mobileFabric4gInVehicle, mobileFabric5g71Outdoor, mobileFabric5g71InVehicle, mobileFabric5g353Outdoor, mobileFabric5g353InVehicle], [jun2022, dec2022, jun2023, dec2023], "%b%Y");

export const beadFunding = new DataVariable("beadFunding", "BEAD Assigned Funding", "$")
export const emptyTime = new CodeAndEnglish("", "");
export const beadFundingData = new DataSource("BEAD Assigned Funding", [beadFunding], [emptyTime], "");

import React from "react";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
import {Popup, Map, NavigationControl } from "mapbox-gl";
import { Form477Data, consumerDownload, percentBroadband } from "./Form477";
import { served253, jun2022, fabricData} from "./FCCFabricData";
import { censusBlock, dataTypes, locations, usState, statesAdditional, county, aggregatedAmericanIndianArea2024, censusTract, censusBlockGroup, stateLocations, tribalLocations } from "./GeographicAreaObjects";
import { joinLayer, layerSource, outlineLayer, mapClickerOn, PopupText, paintMap } from "./MapLayerControls";
import { getBaseURL } from "./getQuery";
import { DropdownForm } from "./SelectionComponents";
import { ScatterComponent } from "./GraphComponent";
import { TimeseriesComponent } from "./TimeseriesGraph";
import Geocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import forwardGeocoder from "./congressional_districts";
import PropTypes from "prop-types";
import { fips, fipsToZoom, stateCenter, fipsToGraph, fipsToGraphHeight, fipsToBroadbandAvailabilityChart, fipsToBroadbandAdoptionChart, fipsToBroadbandSpeedChart } from "./StateFIPS";
import { tribes, tribalBounds, tribeCenter, tribeToGraph, tribeToGraphHeight, tribeToBlockGraph, tribeToBlockGraphHeight, tribeToBroadbandAvailabilityChart, tribeToBroadbandAdoptionChart, tribeToBroadbandSpeedChart } from "./TribalDict";
import { changeArea, changeData, changeVariable } from "./DataChange";
import {timeFilter} from "./VariableFilters";
import chroma from "chroma-js";
import Coffee from "./Coffee";
import TopNav from "./TopNav";

mapboxgl.accessToken = "pk.eyJ1IjoibmF0aGFuaWVsbG92aW4iLCJhIjoiY2tjYjR2cGE1MTIwbjJzcGZwb2FpcGJhMCJ9.aqPwkUChkKBq0rN7Q7sGoA";

function MapKey(props){
	return (
		<div className="key">
			<div className="keyColor1" ></div>
			<div className="keyColor2" ></div>
			<div className="keyColor3" ></div>
			<div className="keyColor4" ></div>
			<div className="keyMin">0</div>
			<div className="keyMax">{props.max + (props.variable.label !== "%" ? " " : "") + props.variable.label}</div>
		</div>
	);
}

BroadbandFields.propTypes = {
	class: PropTypes.string,
	downloadSpeed: PropTypes.number.isRequired,
	uploadSpeed: PropTypes.number.isRequired,
	handleBroadbandDownloadChange: PropTypes.func.isRequired,
	handleBroadbandUploadChange: PropTypes.func.isRequired,
};

BroadbandFieldsSimple.propTypes = {
	class: PropTypes.string,
	downloadSpeed: PropTypes.number.isRequired,
	uploadSpeed: PropTypes.number.isRequired,
	handleBroadbandDownloadChange: PropTypes.func.isRequired,
	handleBroadbandUploadChange: PropTypes.func.isRequired,
};

function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

function zoomArea(zoom, area, dataName) {
	const zoomArea = zoom <= 6 ? county : zoom <= 8 ? censusTract : zoom <= 13 ? censusBlockGroup : censusBlock;
	const dataArea = (area === censusBlock && !(dataName === "Rural Digital Opportunity Fund" || dataName === "NTIA Proposed Areas")) ?  zoomArea : area;
	return dataArea;
}

const LoadingIndicator = (props) => {
	return (
		props.loading &&
		<div className="loading-indicator">
			<img src="/Loading_icon_cropped.gif" alt="Loading..."/>
		</div>
	);
};

export function BroadbandFields(props) {
	return <div className={props.class}>
		<div className={props.class + "-left"}>
			<p style={{margin: 0}}>Minimum Download Speed for Broadband: 
				<input value={props.downloadSpeed} style={{width: 30}} onChange={props.handleBroadbandDownloadChange} /></p>
		</div>
		<div className={props.class + "-right"}>
			<p style={{margin: 0}}>Minimum Upload Speed for Broadband: 
				<input value={props.uploadSpeed} style={{width: 30}} onChange={props.handleBroadbandUploadChange} /></p>
		</div>
	</div>;
}

export function BroadbandFieldsSimple(props) {
	return <>&nbsp;with a minimum download speed for broadband of&nbsp;<input value={props.downloadSpeed} onChange={props.handleBroadbandDownloadChange} />
		&nbsp;and a minimum upload speed for broadband of&nbsp;<input value={props.uploadSpeed} onChange={props.handleBroadbandUploadChange} />
	</>;
}

//Form for geographic area
export class GeographicForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			type: props.type,
			dataTypes: dataTypes,
			dataName: "Broadband Fabric Locations",
			locations: props.type === "national" ? locations : props.type === "state" ? stateLocations : tribalLocations,
			area: props.type === "national" ? usState : props.type === "state" ? county : aggregatedAmericanIndianArea2024,
			data: fabricData,
			variable: served253,
			time: fabricData.dataYears[fabricData.dataYears.length - 1],
			fetchedData: [],
			max: 1000,
			map: null,
			geocoder: null,
			lng: -84.6820,
			lat: 37.9148,
			zoom: 4,
			download: 25,
			upload: 3,
			stateId: {"name": null, "id": null},
			tribalId: {"name": null, "id": null},
			states: fips,
			tribes: tribes,
			loading: false,
			areas: [],
			fetching: false,
			staticSite: props.type === "national" ? false : true,
			mapDataURL: "",
			tribalMapView: "tract"
		};
		this.popUpRef = React.createRef();
		this.graphElement = React.createRef();
		this.scatterElement = React.createRef();
		this.handleAreaChange = this.handleAreaChange.bind(this);
		this.handleDataChange = this.handleDataChange.bind(this);
		this.handleVariableChange = this.handleVariableChange.bind(this);
		this.handleTimeChange = this.handleTimeChange.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.fetchDataMax = this.fetchDataMax.bind(this);
		this.replaceJoinLayer = this.replaceJoinLayer.bind(this);
		this.replaceSource = this.replaceSource.bind(this);
		this.handleMapClick = this.handleMapClick.bind(this);
		this.dataMax = this.dataMax.bind(this);
		this.handleBroadbandDownloadChange = this.handleBroadbandDownloadChange.bind(this);
		this.handleBroadbandUploadChange = this.handleBroadbandUploadChange.bind(this);
		this.handleStateIDChange = this.handleStateIDChange.bind(this);
		this.handleBroadbandGoButton = this.handleBroadbandGoButton.bind(this);
		this.afterLoad = this.afterLoad.bind(this);
		this.censusBlockZoomChange = this.censusBlockZoomChange.bind(this);
		this.getStateDict = this.getStateDict.bind(this);
		this.getTribeDict = this.getTribeDict.bind(this);
		this.cleanSource = this.cleanSource.bind(this);
		this.censusBlockSources = this.censusBlockSources.bind(this);
		this.showFullSite = this.showFullSite.bind(this);
		this.handleFullsiteClick = this.handleFullsiteClick.bind(this);
		this.handleStaticSiteClick = this.handleStaticSiteClick.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);
		this.handleTribeChange = this.handleTribeChange.bind(this);
		this.handleTribalMapViewToggle = this.handleTribalMapViewToggle.bind(this);
	}

	async fetchData() {
		while(this.state.fetching === true){
			await sleep(1);
		}
		this.setState({fetching: true});
		this.setState({loading: true});
		if(this.state.variable === percentBroadband){
			const queryURL = `${getBaseURL()}/broadband_percent?area=${this.state.area.schemaName}&download=${this.state.download}&upload=${this.state.upload}&time=${this.state.time.code}`;
			return fetch(queryURL, { method: "get" })
				.then((response) => response.json())

				.then(function (results) {
					console.log(results);
					return results;
				})
				.then(results => this.setState({ fetchedData: results.data, fetching: false }, this.replaceJoinLayer))

				.catch(function (error) {
					console.log(error);
				});
		}
	}

	replaceJoinLayer() {
		if (this.state.map) {
      this.setState({loading: true});
			const variable = this.state.variable.code + this.state.time.code;
			if (this.state.variable === percentBroadband) {
				//console.log(this.state.fetchedData);
				this.state.fetchedData.forEach(d => {
					this.state.map.setFeatureState(
						{
							source: this.state.area.source,
							sourceLayer: "data",
							id: d[this.state.area.id]
						}
						, {
							[variable]: d[variable]
						}
					);
				});
			}
			this.state.map.setPaintProperty("join", "fill-opacity", 0.6);
			this.state.map.setPaintProperty("join", "fill-color", paintMap(variable, this.state.max));
			this.state.map.once("idle", () => {
				this.setState({mapDataURL: this.state.map.getCanvas().toDataURL()});
			});
		}
		this.setState({loading: false});
	}

	censusBlockSources(zoom) {
		if ((this.state.dataName === "Rural Digital Opportunity Fund" || this.state.dataName === "NTIA Proposed Areas")){
			return;
		}
		if(zoom <= 6){
			this.state.map.addLayer(outlineLayer(county));
			this.state.map.addLayer(joinLayer(county, this.state.stateId, this.state.tribalId));
		}
		else if(zoom > 6 && zoom <= 8){
			this.state.map.addLayer(outlineLayer(censusTract));
			this.state.map.addLayer(joinLayer(censusTract, this.state.stateId, this.state.tribalId));
		}
		else if(zoom > 8 && zoom <= 13){
			this.state.map.addLayer(outlineLayer(censusBlockGroup));
			this.state.map.addLayer(joinLayer(censusBlockGroup, this.state.stateId, this.state.tribalId));
		}
		else{
			this.state.map.addLayer(outlineLayer(this.state.area));
			this.state.map.addLayer(joinLayer(this.state.area, this.state.stateId, this.state.tribalId));
		}
	}


	replaceSource() {
		if (this.state.map) {
			this.state.map.addSource(this.state.area.source, layerSource(this.state.area));
			if(this.state.area === censusBlock){
				this.state.map.addSource(county.source, layerSource(county));
				this.state.map.addSource(censusTract.source, layerSource(censusTract));
				this.state.map.addSource(censusBlockGroup.source, layerSource(censusBlockGroup));
			}
			if(this.state.area !== censusBlock || (this.state.dataName === "Rural Digital Opportunity Fund" || this.state.dataName === "NTIA Proposed Areas")){
				this.state.map.addLayer(outlineLayer(this.state.area));
				this.state.map.addLayer(joinLayer(this.state.area, this.state.stateId, this.state.tribalId));
			}
			if(this.state.area === censusBlock && !(this.state.dataName === "Rural Digital Opportunity Fund" || this.state.dataName === "NTIA Proposed Areas")){
				this.censusBlockSources(this.state.zoom)
			}
			this.state.map.on("click", "join", this.handleMapClick);
			this.state.map.on('render', this.afterLoad)
		}
	}

	censusBlockZoomChange(oldzoom,newzoom) {
		if ((this.state.dataName === "Rural Digital Opportunity Fund" || this.state.dataName === "NTIA Proposed Areas")){
			return;
		}
		this.setState({loading: true});
		//Remove old layers
		if((oldzoom <= 6 && newzoom > 6) || (oldzoom > 6 && newzoom <= 6) || (oldzoom <= 8 && newzoom > 8) || (oldzoom > 8 && newzoom <= 8) || (oldzoom <= 13 && newzoom > 13) || (oldzoom > 13 && newzoom <= 13)){
			this.state.map.off("click", "join", this.handleMapClick);
			this.state.map.removeLayer("outline");
			this.state.map.removeLayer("join");
		}
		//Add new layers
		this.censusBlockSources(newzoom)
		this.state.map.on("click", "join", this.handleMapClick);
		this.state.map.on('render', this.afterLoad);
	}

	cleanSource(currentSource) {
		if (this.state.map) {
			this.state.map.off("click", "join", this.handleMapClick);
			this.state.map.removeLayer("join");
			this.state.map.removeLayer("outline");
			this.state.map.removeSource(currentSource.source);
			if(currentSource === censusBlock){
				this.state.map.removeSource(county.source);
				this.state.map.removeSource(censusTract.source);
				this.state.map.removeSource(censusBlockGroup.source);
			}
		}
	}

	handleAreaChange(event) {
		this.cleanSource(this.state.area);
		const newSelection = changeArea(event.target.value, this.state.time, this.state.variable, this.state.data);
		this.setState({
			dataName: newSelection.dataName,
			area: newSelection.area,
			data: newSelection.data,
			variable: newSelection.variable,
			time: newSelection.time,
			fetchedData: null
		}, this.replaceSource);
		this.graphElement.current.updateState("dataSource", newSelection.data);
		this.graphElement.current.updateState("area", newSelection.area);
		this.graphElement.current.updateState("variable", newSelection.variable);
	}

	handleDataChange(event) {
		this.setState({loading: true});
		const newLocations = locations.filter(e => e.dataSources.filter(f => f.name === event.target.value).length > 0);
		const contains = newLocations.some(e => e.name === this.state.area.name);
		if (!contains) {
			this.cleanSource(this.state.area);
		}
		const newSelection = changeData(event.target.value, this.state.time, this.state.variable, this.state.area);
		this.setState({
			dataName: newSelection.dataName,
			locations: newLocations,
			area: newSelection.area,
			data: newSelection.data,
			variable: newSelection.variable,
			time: newSelection.time,
			fetchedData: null
		}, contains ? this.fetchDataMax : this.replaceSource);
		this.graphElement.current.updateState("dataSource", newSelection.data);
		this.graphElement.current.updateState("area", newSelection.area);
		this.graphElement.current.updateState("variable", newSelection.variable);
	}

	handleVariableChange(event) {
		this.setState({loading: true});
		const newSelection = changeVariable(event.target.value, this.state.data, this.state.time);
		this.setState({ variable: newSelection.variable, time: newSelection.time, fetchedData: null }, this.fetchDataMax); 
		this.graphElement.current.updateState("variable", this.state.data.dataTypes.find(e => e.name === event.target.value));
	}

	handleTimeChange(event) {
		this.setState({loading: true});
		this.setState({ time: this.state.data.dataYears.find(e => e.name === event.target.value),
			fetchedData: null }, this.fetchDataMax);
	}

	async handleMapClick(event) {
		if (event.features.length) {
			//Set up Map pop-up
			const eventProp = event.features[0].properties;
			const popupNode = document.createElement("div");
			console.log(eventProp);
			// const popData = this.state.fetchedData.reduce((found, dataElement) => 
			// 	(dataElement[this.state.area.id] === eventProp[this.state.area.match] 
			// 		? dataElement[this.state.variable.code + this.state.time.code] : found), 0);
			// console.log(this.state.variable.label);
			ReactDOM.render(<PopupText area={this.state.area}
				variable={this.state.variable} time={this.state.time}
				name={event.features[0].properties[this.state.area.polyName]}
				data={event.features[0].properties}
				areaId={eventProp[this.state.area.match]} />, popupNode);
			const marker = new Popup();
			marker.setDOMContent(popupNode)
				.setLngLat(event.lngLat)
				.addTo(this.state.map);
			//Add to selected areas for Graph
			const areaSelected = { [this.state.area.id]: event.features[0].properties[this.state.area.match], 
				name: event.features[0].properties["name"] };
			this.graphElement.current.addLine(areaSelected);
		}
	}

	getStateDict(){
		if(this.state.type === "national" || this.state.type === "tribe"){
			return null
		}
		const stateName = window.location.pathname.split("/")[2].replaceAll("_", " ");
		const stateDict = this.state.states.find(e => e.name.toLowerCase() === stateName.toLowerCase());
		return stateDict;
	}

	getTribeDict(){
		if(this.state.type === "national" || this.state.type === "state"){
			return null
		}
		const tribeName = window.location.pathname.split("/")[2].replaceAll("_", " ");
		const tribeDict = this.state.tribes.find(e => e.name.toLowerCase() === tribeName.toLowerCase());
		return tribeDict;
	}

	async componentDidMount() {
		const query = new URLSearchParams(window.location.search);
		if (this.state.type === "state") {
			const stateDict = this.getStateDict();
			this.setState({stateId: stateDict, lat: stateCenter[stateDict.id].lat, lng: stateCenter[stateDict.id].lng, zoom: fipsToZoom[stateDict.id]},
				query.get("full") === "true" ? this.setState({staticSite: false}, this.showFullSite) : null);
		}
		else if (this.state.type === "tribe") {
			const tribeDict = this.getTribeDict();
			console.log(tribeDict)
			console.log(tribeToGraph[tribeDict.id])
			this.setState({tribalId: tribeDict, lat: tribeCenter[tribeDict.id].lat, lng: tribeCenter[tribeDict.id].lng},
				query.get("full") === "true" ? this.setState({staticSite: false}, this.showFullSite) : null);
		}
		else{
			this.showFullSite();
		}
	}

	showFullSite() {
		this.fetchDataMax();
		//Set up Mapbox Map
		const map = new Map({
			container: this.mapContainer,
			style: "mapbox://styles/mapbox/light-v9",
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
			// Bound Map to United States
			maxBounds: [[-173, 16], [-64, 73]]
		});
		if(this.state.tribalId){
			const bounds = tribalBounds[this.state.tribalId.id]
			map.fitBounds([[bounds.xmin, bounds.ymin], [bounds.xmax, bounds.ymax]])
		}

		map.on("move", () => {
			if(this.state.zoom !== map.getZoom().toFixed(2) && this.state.area === censusBlock) {
				this.censusBlockZoomChange(this.state.zoom, map.getZoom().toFixed(2));
			}
			this.setState({
				lng: map.getCenter().lng.toFixed(4),
				lat: map.getCenter().lat.toFixed(4),
				zoom: map.getZoom().toFixed(2)
			}, console.log(this.state.zoom));
		});

		map.on("load", () => {
			map.addSource(this.state.area.source, layerSource(this.state.area));
			map.addLayer(joinLayer(this.state.area, this.state.stateId, this.state.tribalId));
			map.addLayer(outlineLayer(this.state.area));
			map.addControl(new NavigationControl());
			this.setState({ map: map });
			map.on('render', this.afterLoad)
		});

		mapClickerOn(map);
		map.on("click", "join", this.handleMapClick);

		const geocoder = new Geocoder({
			accessToken: mapboxgl.accessToken,
			mapboxgl: mapboxgl,
			autocomplete: true,
			proximity: {longitude: this.state.lng, latitude: this.state.lat},
			localGeocoder: forwardGeocoder,
			bbox: [-173, 16, -64, 73]
		});

		this.setState({ geocoder: geocoder});

		map.addControl(geocoder);
	}

	afterLoad() {
		if (!this.state.map.loaded()) { return }
		if(this.state.variable === percentBroadband) {
			const dataArea = zoomArea(this.state.zoom, this.state.area);
			const features = this.state.map.queryRenderedFeatures({ layers: ["join"] });
			const areas = features.map(e => e.properties[dataArea.match]);
			this.setState({ areas: [...new Set(areas)], fetchedData: null }, this.fetchDataMax);
		}
		else{	
			this.fetchDataMax();
		}
		this.state.map.off('render', this.afterLoad);
	}

	fetchDataMax() {
		const query = getBaseURL() + "/max?source=" + this.state.area.schemaName + "&variable=" + this.state.variable.code + this.state.time.code;
		fetch(query)
		.then(res => res.json())
		.then(data => {
			console.log(data)
			this.setState({ max: data.max }, this.state.variable === percentBroadband ? this.fetchData : this.replaceJoinLayer);
		});
	}

	dataMax(){
		const dataMax = this.state.max;
		// console.log(dataMax);
		return dataMax === true ? "True" : this.state.variable.label === "%" && dataMax <= 1 ? (dataMax * 100).toFixed(0) : dataMax.toFixed(0);
	}

	handleBroadbandDownloadChange(event) {
		this.setState({ download: event.target.value });
	}

	handleBroadbandUploadChange(event) {
		this.setState({ upload: event.target.value });
	}

	handleBroadbandGoButton(event) {
		// console.log("Go!");
		this.setState({fetchedData: null}, this.fetchDataMax);
		this.graphElement.current.updateBroadbandStates(this.state.download, this.state.upload);
	}

	handleStateIDChange(event) {
		this.setState({ stateId: this.state.states.find(e => e.name === event.target.value) }, this.fetchDataMax);
	}

	handleStateChange(event) {
		document.location.href = getBaseURL() + "/state/" + event.target.value.replaceAll(" ", "_") + (!this.state.staticSite ? "?full=true" : "");
	}

	handleTribeChange(event) {
		document.location.href = getBaseURL() + "/tribe/" + event.target.value.replaceAll(" ", "_") + (!this.state.staticSite ? "?full=true" : "");
	}

	handleFullsiteClick() {
		this.setState({staticSite: !this.state.staticSite}, this.showFullSite);
	}

	handleStaticSiteClick() {
		if (this.state.type === "state") {
			document.location.href = getBaseURL() + "/state/" + this.state.stateId.name.replaceAll(" ", "_");
		}
		else {
			document.location.href = getBaseURL() + "/tribe/" + this.state.tribalId.name.replaceAll(" ", "_");
		}
	}

	handleTribalMapViewToggle() {
		this.setState({tribalMapView: this.state.tribalMapView === 'tract' ? 'block' : 'tract'});
	}

	render() {
		//State Static Page
		if(this.state.staticSite){
			if (this.state.type == "state") {
				return (
					<div className={"grid-static"}>
						<div className = "topnav">
						<a id = "title" className="active" href="/"><img id = "banner-logo" src="/maplogo.png" alt="Technology Policy Institute Logo"/></a>
							<a id = "title" className="active" href="/">{this.state.stateId.name}</a>
						</div>
						<div className={"staticstate"}>
							<div className="header-state"><DropdownForm className={"state-slect"} id={"state-change"} value={this.state.stateId.name} data={fips} onChangeFunction={this.handleStateChange} style={{ border: 'none', "text-align-last":"center" }}/></div><div className={"header-title"}><h1>Broadband Snapshot</h1></div>
							<div className={"state-map"}><iframe title={this.state.stateId.name} aria-label={"Map"} id={"datawrapper-chart-" + fipsToGraph[this.state.stateId.id]} src={"https://datawrapper.dwcdn.net/" + fipsToGraph[this.state.stateId.id]} scrolling={"no"} style={{"border": "none", "z-index": "1"}} width={600} height={fipsToGraphHeight[this.state.stateId.id]*1.15}></iframe></div>
							{/* <img className={"state-map"} src={`/stateMap/${this.state.stateId.id}`} alt={"A map of counties of " + this.state.stateId.name + " by max average broadband download speed by Form 477"}></img> */}
							<div className={"availability-graph"}><iframe title={this.state.stateId.name} aria-label={"Availability"} id={"datawrapper-chart-" + fipsToBroadbandAvailabilityChart[this.state.stateId.id]} src={"https://datawrapper.dwcdn.net/" + fipsToBroadbandAvailabilityChart[this.state.stateId.id]} scrolling={"no"} style={{"border": "none", "z-index": "1"}} width={"100%"} height={"100%"}></iframe></div>
							<div className={"adoption-graph"}><iframe title={this.state.stateId.name} aria-label={"Adoption"} id={"datawrapper-chart-" + fipsToBroadbandAdoptionChart[this.state.stateId.id]} src={"https://datawrapper.dwcdn.net/"  + fipsToBroadbandAdoptionChart[this.state.stateId.id]} scrolling={"no"} style={{"border": "none", "z-index": "1"}} width={"100%"} height={"100%"}></iframe></div>
							<div className={"broadband-graph"}><iframe title={this.state.stateId.name} aria-label={"Speed"} id={"datawrapper-chart-" + fipsToBroadbandSpeedChart[this.state.stateId.id]} src={"https://datawrapper.dwcdn.net/" + fipsToBroadbandSpeedChart[this.state.stateId.id]} scrolling={"no"} style={{"border": "none", "z-index": "1"}} width={"100%"} height={"100%"}></iframe></div>
							<h2 className={"availability-header"}> Availability</h2>
							<h2 className={"adoption-header"}> Adoption</h2>
							<h2 className={"broadband-header"}> Speeds</h2>
							{/* <h2 className={"state-map-title"}>Maximum Download Speed by County</h2> */}
							<button className={"full-site"} onClick={this.handleFullsiteClick}>View the Detailed, Interactive State Map</button>
							<div className={"notes"}><p><i>Availability graph is calculated based on FCC Form 477 data for 2015-2021, calculated as the population weighted average maximum up/down speeds, and FCC Broadband Fabric served locations data for June of 2022 on. Adoption data is taken from the 1 year American Community Survey Estimates. Speed data comes from Ookla Speedtest Open Data <a href="https://www.ookla.com/ookla-for-good/open-data">avaliable at this link.</a></i></p></div>
						</div>
						<div className="buyMeACoffee"><Coffee /></div>
						<div ref={el => this.mapContainer = el} className="map" />
					</div>
				);
			}
			return (
				<div className={"grid-static"}>
					<div className = "topnav">
					<a id = "title" className="active" href="/"><img id = "banner-logo" src="/maplogo.png" alt="Technology Policy Institute Logo"/></a>
					    <a id = "title" className="active" href="/">{this.state.tribalId.name}</a>
					</div>
					<div className={"staticstate"}>
						<div className="header-state"><DropdownForm className={"state-slect"} id={"state-change"} value={this.state.tribalId.name} data={tribes} onChangeFunction={this.handleTribeChange} style={{ border: 'none', "text-align-last":"center" }}/></div><div className={"header-title"}><h1>Broadband Snapshot</h1></div>
						<div className={"state-map"}>
							<iframe title={this.state.tribalId.name} aria-label={"Map"} id={"datawrapper-chart-" + this.state.tribalMapView === "tract" ? tribeToGraph[this.state.tribalId.id] : tribeToBlockGraph[this.state.tribalId.id]} src={"https://datawrapper.dwcdn.net/" + (this.state.tribalMapView === "tract" ? tribeToGraph[this.state.tribalId.id] : tribeToBlockGraph[this.state.tribalId.id]) + "/?fitchart=true"} scrolling={"no"} style={{"border": "none", "z-index": "1"}} width={800} height={this.state.tribalMapView === "tract" ? tribeToGraphHeight[this.state.tribalId.id]*1.53 : tribeToBlockGraphHeight[this.state.tribalId.id]*1.53}></iframe>
							<button className={"tribal-view-toggle"}onClick={this.handleTribalMapViewToggle}>View by {this.state.tribalMapView === 'tract' ? 'Block Groups' : 'Tracts'}</button>
						</div> 
						{/* <img className={"state-map"} src={`/stateMap/${this.state.tribalId.id}`} alt={"A map of counties of " + this.state.tribalId.name + " by max average broadband download speed by Form 477"}></img> */}
						<div className={"availability-graph"}><iframe title={this.state.tribalId.name} aria-label={"Availability"} id={"datawrapper-chart-" + tribeToBroadbandAvailabilityChart[this.state.tribalId.id]} src={"https://datawrapper.dwcdn.net/" + tribeToBroadbandAvailabilityChart[this.state.tribalId.id]} scrolling={"no"} style={{"border": "none", "z-index": "1"}} width={"100%"} height={"100%"}></iframe></div>
						<div className={"adoption-graph"}><iframe title={this.state.tribalId.name} aria-label={"Adoption"} id={"datawrapper-chart-" + tribeToBroadbandAdoptionChart[this.state.tribalId.id]} src={"https://datawrapper.dwcdn.net/"  + tribeToBroadbandAdoptionChart[this.state.tribalId.id]} scrolling={"no"} style={{"border": "none", "z-index": "1"}} width={"100%"} height={"100%"}></iframe></div>
						<div className={"broadband-graph"}><iframe title={this.state.tribalId.name} aria-label={"Speed"} id={"datawrapper-chart-" + tribeToBroadbandSpeedChart[this.state.tribalId.id]} src={"https://datawrapper.dwcdn.net/" + tribeToBroadbandSpeedChart[this.state.tribalId.id]} scrolling={"no"} style={{"border": "none", "z-index": "1"}} width={"100%"} height={"100%"}></iframe></div>
						<h2 className={"availability-header"}> Availability</h2>
						<h2 className={"adoption-header"}> Adoption</h2>
						<h2 className={"broadband-header"}> Speeds</h2>
						{/* <h2 className={"state-map-title"}>Maximum Download Speed by County</h2> */}
						<button className={"full-site"} onClick={this.handleFullsiteClick}>View the Detailed, Interactive Tribe Map</button>
						<div className={"notes"}><p><i>Availability graph is calculated based on FCC Form 477 data for 2015-2021, calculated as the population weighted average maximum up/down speeds, and FCC Broadband Fabric served locations data for June of 2022 on. Adoption data is taken from the 1 year American Community Survey Estimates. Speed data comes from Ookla Speedtest Open Data <a href="https://www.ookla.com/ookla-for-good/open-data">avaliable at this link.</a></i></p></div>
					</div>
					<div className="buyMeACoffee"><Coffee /></div>
					<div ref={el => this.mapContainer = el} className="map" />
				</div>
			);
		}
		return (
			<div className={"grid"}>
				<TopNav type={this.state.type} stateId={this.getStateDict()} tribalId={this.getTribeDict()} handleStateChange={this.handleStateChange} handleTribeChange={this.handleTribeChange}/>
				<div className={this.state.variable === percentBroadband || this.state.area === censusBlock ? "area-selector broadband" : "area-selector"}>
					<div className={"loading-icon"}><LoadingIndicator loading={this.state.loading} /></div>
					<div className={"area-selector-title"}><p>Select Map Data:</p></div>
					<div className={"area-selector-label-datatype"}><p>Data Source:</p></div>
					<div className={"area-selector-label-geography"}><p>Type of Area:</p></div>
					<div className={"area-selector-label-variable"}><p>Data Variable:</p></div>
					<div className={"area-selector-label-year"}><p>Data Time:</p></div>
					{this.state.area === censusBlock && <div className={"area-selector-label-state"}><p><b>Census Blocks not visible at national scale; showing counties/tracts/block groups instead. Zoom in to see Census Blocks</b></p></div>}
					<div className={"area-selector-dropdown-datatype"}><DropdownForm id="mapData" value={this.state.dataName} 
						onChangeFunction={this.handleDataChange} data={this.state.dataTypes} /></div>
					<div className={"area-selector-dropdown-geography"}><DropdownForm id="geographicArea" value={this.state.area.name} 
						onChangeFunction={this.handleAreaChange} data={this.state.locations} /></div>
					<div className={"area-selector-dropdown-variable"}><DropdownForm id="variable" value={this.state.variable.name} 
						onChangeFunction={this.handleVariableChange} data={this.state.data.dataTypes} /></div>
					<div className={"area-selector-dropdown-year"}><DropdownForm id="time" value={this.state.time.name} 
						onChangeFunction={this.handleTimeChange} data={timeFilter(this.state.data, this.state.variable)} /></div>
					{/* {this.state.area === censusBlock && <div className={"area-selector-dropdown-state"}><DropdownForm id="state" value={this.state.stateId.name}
						onChangeFunction={this.handleStateIDChange} data={this.state.states} /></div>} */}
					{this.state.variable === percentBroadband && <div className={"area-selector-updown"}><BroadbandFields downloadSpeed={this.state.download} uploadSpeed={this.state.upload} handleBroadbandDownloadChange={this.handleBroadbandDownloadChange} handleBroadbandUploadChange={this.handleBroadbandUploadChange} class={"map-broadband"} /><div className={"map-broadband-button"}><button type="button" style={{width: "35px"}} onClick={this.handleBroadbandGoButton}>Go</button></div></div>}
				</div>

				<div className="mapAndKey">
					<div ref={el => this.mapContainer = el} className="map" />
					<MapKey max={this.dataMax()} variable={this.state.variable} />
					{/* <a id="link" href={this.state.mapDataURL} download="map.png">Download Current Map Canvas</a> */}
				</div>
				<div className="chart-container-left"> <TimeseriesComponent ref={this.graphElement} stateId={this.getStateDict()} tribalId={this.getTribeDict()} /> </div>
				<div className="chart-container-right" id = "scatter">
					<ScatterComponent ref={this.scatterElement} stateId={this.getStateDict()} tribalId={this.getTribeDict()}/>
				</div>
				<div className="buyMeACoffee"><Coffee /></div>
				<div className="footer"><p>Email nlovin@techpolicyinstitute.org with any bug reports and email soh@techpolicyinstitute.org or swallsten@techpolicyinstitute.org with any other questions</p></div>
			</div>
		);
	}
}
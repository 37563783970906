import { GeographicArea } from "./ModelClasses";
import { censusBlockForm477, Form477Data, MobileForm477Data } from "./Form477";
import { allACS, fiveYearACS, stateACS, fiveYearACSUpto2020, allACSUpto2020, acs2021, acs2022, allACS2022, allACSAdditional, fiveYearACSUpto2020Additional, allACSUpto2020Additional, fiveYearACSAdditional, acs2021Additional, acs2022Additional, allACS2022Additional } from "./ACSInternetSubscribers";
// import { usacData } from "./USACData";
import { OoklaData, OoklaMobileData } from "./Ookla";
import { timeSeries, scatter } from "./GraphsDescriptions";
import { microCounty, microZip } from "./MicrosoftUsage";
import { zipEBB, stateEBB, countyEBB } from "./EmergencyBroadbandBenefit";
import { rdofData, rdofAssignedSupportData, rdofEligibleAndSupportData, ntiaProposedAreasData } from "./RDOFData";
import { stateACP, countyACP, zipACP } from "./AffordableConnectivityProgram";
import { usacData } from "./USACData";
import {fabricData, mobileFabricData, fccFabricFixed, fccFabricMobile, beadFundingData} from "./FCCFabricData";

//Geographic Area Objects
//Removing USAC data for now
export const usState = new GeographicArea("State", "states", "states", "GEOID", "states", "stateId", "NAME", "State", [Form477Data, MobileForm477Data, OoklaData, stateACS, OoklaMobileData, usacData, stateEBB, stateACP, rdofAssignedSupportData, fabricData, mobileFabricData, fccFabricFixed, fccFabricMobile, allACSAdditional, beadFundingData], [timeSeries, scatter]);
export const county = new GeographicArea("County", "county", "counties", "GEOID", "counties", "countyId", "NAME", "County", [Form477Data, MobileForm477Data, OoklaData, allACS,  OoklaMobileData, microCounty, usacData, countyEBB, countyACP, rdofEligibleAndSupportData, fabricData, mobileFabricData, fccFabricFixed, fccFabricMobile, allACSAdditional], [timeSeries, scatter]);
export const censusTract = new GeographicArea("Census Tract", "census_tracts", "census-tracts", "GEOID", "censusTracts", "tractId", "NAME", "Census_Tract", [Form477Data, MobileForm477Data, OoklaData, fiveYearACSUpto2020, OoklaMobileData, usacData, rdofData, fabricData, mobileFabricData, fiveYearACSUpto2020Additional], [timeSeries, scatter]);
export const censusBlockGroup = new GeographicArea("Census Block Group", "census_block_groups", "census-block-groups", "GEOID", "censusBlockGroups", "blockGroupId", "NAME", "Census_Block_Group", [Form477Data, MobileForm477Data, fiveYearACSUpto2020, rdofData, fabricData, mobileFabricData, fiveYearACSUpto2020Additional], [timeSeries, scatter]);
export const censusBlock = new GeographicArea("Census Block", "census_blocks", "census-blocks", "GEOID10", "censusBlocks", "censusBlockCode", "NAME10", "Census_Block", [censusBlockForm477, MobileForm477Data, rdofData, ntiaProposedAreasData], [timeSeries, scatter]);
export const censusBlock2020 = new GeographicArea("Census Block (2020)", "census_blocks_2020", "census-blocks_2020", "GEOID20", "censusBlocks", "censusBlockCode", "NAME20", "Census_Block_2020", [censusBlockForm477, MobileForm477Data, fabricData], [timeSeries, scatter]);
export const tribalArea = new GeographicArea("American Indian Area", "american_indian_areas", "tribal-areas", "GEOID", "tribalAreas", "tribalAreaId", "NAME", "Tribal_Area", [Form477Data, MobileForm477Data, fiveYearACS, OoklaData, OoklaMobileData, usacData, fabricData], [timeSeries, scatter]);
export const tribalTract = new GeographicArea("Tribal Tract", "tribal_census_tracts", "tribal-tracts", "GEOID", "tribalTracts", "tribalCensusTractId", "NAMELSAD", "Tribal_Tract", [Form477Data, MobileForm477Data, fiveYearACS, OoklaData, OoklaMobileData, usacData, fabricData], [timeSeries, scatter]);
export const zipCode = new GeographicArea("Zip Code", "zip_code_areas", "zip-code-areas", "GEOID10", "zipCodes", "zipcodeId", "ZCTA5CE10", "Zip_Code", [Form477Data, MobileForm477Data, OoklaData, fiveYearACS, OoklaMobileData, microZip, zipEBB, zipACP, fabricData, mobileFabricData, fiveYearACSUpto2020Additional], [timeSeries, scatter]);
export const combinedStatisticalArea = new GeographicArea("Combined Statistical Area", "combined_statistical_areas", "csas", "GEOID", "combinedStatisticalAreas", "combinedStatisticalAreasId", "NAME", "Combined_Statistical_Area", [Form477Data, MobileForm477Data, OoklaData, allACS, OoklaMobileData, fabricData, mobileFabricData, allACSAdditional], [timeSeries, scatter]);
export const metropolitanStatisticalArea = new GeographicArea("Metropolitan Statistical Area", "metropolitan_statistical_areas", "msas", "GEOID", "metropolitanStatisticalAreas", "metropolitanStatisticalAreasId", "NAME", "Metropolitan_Statistical_Area", [Form477Data, MobileForm477Data, OoklaData, allACS, OoklaMobileData, fabricData, mobileFabricData, fccFabricFixed, fccFabricMobile, allACSAdditional], [timeSeries, scatter]);
export const metropolitanDivision = new GeographicArea("Metropolitan Division", "metropolitan_division", "metropolitan-divisions", "GEOID", "metropolitanDivisions", "metropolitanDivisionsId", "NAME", "Metropolitan_Division", [Form477Data, MobileForm477Data, OoklaData, allACS, OoklaMobileData, fabricData, mobileFabricData, allACSAdditional], [timeSeries, scatter]);
export const tribalBlockGroup = new GeographicArea("Tribal Block Group", "tribal_block_groups", "tribal-block-groups", "GEOID", "tribalBlockGroups", "tribalBlockGroupId", "NAMELSAD", "Tribal_Block_Group", [Form477Data, MobileForm477Data, fabricData], [timeSeries, scatter]);
export const congressionalDistrict = new GeographicArea("Congressional District", "congressional_districts", "congressionalDistricts", "GEOID", "congressionalDistricts", "congressionalDistrict", "NAMELSAD", "Congressional_District", [Form477Data, MobileForm477Data, OoklaData, allACSUpto2020, OoklaMobileData, usacData, fabricData, mobileFabricData, fccFabricFixed, fccFabricMobile, allACSUpto2020Additional], [timeSeries, scatter]);
export const congressionalDistrict2022 = new GeographicArea("Congressional District (2022)", "congressional_districts_2022", "congressionalDistricts2022", "congressional_district", "congressionalDistricts2022", "congressionalDistrict", "name", "Congressional_District_2022", [Form477Data, MobileForm477Data, OoklaData, OoklaMobileData, usacData, zipACP, fabricData, mobileFabricData, allACS2022, allACS2022Additional], [timeSeries, scatter]);
export const studyArea = new GeographicArea("FCC Study Area", "study_areas", "study-areas", "SAC", "studyAreas", "studyArea", "HOCO", "Study_Area", [Form477Data, MobileForm477Data, OoklaData], [timeSeries, scatter]);
export const places = new GeographicArea("Incorporated and Census-Designated Place", "places", "places", "GEOID", "places", "placeId", "NAME", "Place", [Form477Data, MobileForm477Data, OoklaData, OoklaMobileData, allACS, fabricData, mobileFabricData, fccFabricFixed, fccFabricMobile], [timeSeries, scatter]);
export const stateLegislaturesLower = new GeographicArea("State Legislative Distict, Lower House", "state_legislatures_lower", "state-legislatures-lower", "GEOID", "stateLegislaturesLowers", "stateLegislaturesLowerId", "GEOID", "State_Legislatures_Lower", [Form477Data, MobileForm477Data, OoklaData, fiveYearACS, OoklaMobileData, usacData, fabricData, mobileFabricData, fiveYearACSAdditional], [timeSeries, scatter]);
export const stateLegislaturesUpper = new GeographicArea("State Legislative Distict, Upper House", "state_legislatures_upper", "state-legislatures-upper", "GEOID", "stateLegislaturesUppers", "stateLegislaturesUpperId", "GEOID", "State_Legislatures_Upper", [Form477Data, MobileForm477Data, OoklaData, fiveYearACS, OoklaMobileData, usacData, fabricData, mobileFabricData, fiveYearACSAdditional], [timeSeries, scatter]);
export const primarySchoolDistrict = new GeographicArea("Primary School District", "primary_school_districts", "primary-school-districts", "GEOID", "primarySchoolDistricts", "primarySchoolDistrictId", "NAME", "Primary_School_District", [Form477Data, MobileForm477Data, OoklaData, allACS, OoklaMobileData, fabricData, mobileFabricData, allACSAdditional], [timeSeries, scatter]);
export const secondarySchoolDistrict = new GeographicArea("Secondary School District", "secondary_school_districts", "secondary-school-districts", "GEOID", "secondarySchoolDistricts", "secondarySchoolDistrictId", "NAME", "Secondary_School_District", [Form477Data, MobileForm477Data, OoklaData, allACS, OoklaMobileData, fabricData, mobileFabricData, allACSAdditional], [timeSeries, scatter]);
export const unifiedSchoolDistrict = new GeographicArea("Unified School District", "unified_school_districts", "unified-school-districts", "GEOID", "unifiedSchoolDistricts", "unifiedSchoolDistrictId", "NAME", "Unified_School_District", [Form477Data, MobileForm477Data, OoklaData, allACS, OoklaMobileData, fabricData, mobileFabricData, allACSAdditional], [timeSeries, scatter]);
export const combinedSchoolDistrict = new GeographicArea("Combined School District", "combined_school_districts", "combined-school-districts", "GEOID", "combinedSchoolDistricts", "combinedSchoolDistrictId", "NAME", "Combined_School_District", [Form477Data, MobileForm477Data, OoklaData, OoklaMobileData, fabricData], [timeSeries, scatter]);
export const nation = new GeographicArea("United States", "nation", "nation", "GEOID", "nation", "geoid", "NAME", "Nation", [Form477Data, MobileForm477Data, OoklaData, OoklaMobileData, allACS, fabricData, mobileFabricData, fccFabricFixed, fccFabricMobile], [timeSeries, scatter]);
export const censusTract2022 = new GeographicArea("Census Tract (2022)", "census_tracts_2022", "census-tracts-2022", "GEOID", "censusTracts2022", "tractId", "NAME", "Census_Tract_2022", [Form477Data, MobileForm477Data, OoklaData, OoklaMobileData, acs2021, fabricData, mobileFabricData, acs2021Additional], [timeSeries, scatter]);
export const censusBlockGroup2022 = new GeographicArea("Census Block Group (2022)", "census_block_groups_2022", "census-block-groups-2022", "GEOID", "censusBlockGroup2022", "blockGroupId", "NAME", "Census_Block_Group_2022", [Form477Data, MobileForm477Data, acs2021, fabricData], [timeSeries, scatter]);
export const zipCode2022 = new GeographicArea("Zip Code (2022)", "zip_code_areas_2022", "zip-code-areas-2022", "GEOID20", "ZipCodeTabulationArea2022", "zipCodeId", "ZCTA5CE20", "Zip_Code_Tabulation_Area_2022", [Form477Data, MobileForm477Data, OoklaData, OoklaMobileData, acs2021, fabricData, mobileFabricData, acs2021Additional], [timeSeries, scatter]);
export const stateLegislaturesLower2022 = new GeographicArea("State Legislative Distict, Lower House (2022)", "state_legislatures_lower_2022", "state-legislatures-lower-2022", "GEOID", "stateLegislativeDistrictLower2022", "stateLegislativeDistrictLowerId", "GEOID", "State_Legislative_District_Lower_2022", [Form477Data, MobileForm477Data, OoklaData, OoklaMobileData, fabricData, mobileFabricData, acs2022, acs2022Additional], [timeSeries, scatter]);
export const stateLegislaturesUpper2022 = new GeographicArea("State Legislative Distict, Upper House (2022)", "state_legislatures_upper_2022", "state-legislatures-upper-2022", "GEOID", "stateLegislativeDistrictUpper2022", "stateLegislativeDistrictUpperId", "GEOID", "State_Legislative_District_Upper_2022", [Form477Data, MobileForm477Data, OoklaData, OoklaMobileData, fabricData, mobileFabricData, acs2022, acs2022Additional], [timeSeries, scatter]);
export const tribalArea2024 = new GeographicArea("American Indian Area 2024", "american_indian_areas_2024", "american-indian-areas-2024", "GEOID", "americanIndianaAreas2024", "americanIndianAreaId", "NAME", "American_Indian_Area_2024", [Form477Data, MobileForm477Data, fiveYearACS, OoklaData, OoklaMobileData, usacData, fabricData], [timeSeries, scatter]);
export const tribalTract2024 = new GeographicArea("Tribal Tract 2024", "tribal_tracts_2024", "tribal-tracts-2024", "GEOID", "tribalTracts2024", "tribalTractId", "NAMELSAD", "Tribal_Tracts_2024", [Form477Data, MobileForm477Data, fiveYearACS, OoklaData, OoklaMobileData, usacData, fabricData], [timeSeries, scatter]);
export const tribalBlockGroup2024 = new GeographicArea("Tribal Block Group 2024", "tribal_block_groups_2024", "tribal-block-groups-2024", "GEOID", "tribalBlockGroups2024", "tribalBlockGroupId", "NAMELSAD", "Tribal_Block_Groups_2024", [Form477Data, MobileForm477Data, fabricData], [timeSeries, scatter]);
export const aggregatedAmericanIndianArea2024 = new GeographicArea("American Indian Areas (Aggregated) 2024", "aggregated_american_indian_areas", "aggregated-american-indian-areas-2024", "AIANNHCE", "aggregatedAmericanIndianaAreas2024", "AIANNHCE", "NAME", "Aggregated_American_Indian_Area_2024", [Form477Data, MobileForm477Data, fiveYearACS, OoklaData, OoklaMobileData, usacData, fabricData], [timeSeries, scatter]);

//array of geographic area objects
export const locations = [usState, county, censusTract, censusTract2022, censusBlockGroup, censusBlockGroup2022, tribalArea, tribalTract, zipCode, zipCode2022, combinedStatisticalArea, metropolitanStatisticalArea, metropolitanDivision, tribalBlockGroup, congressionalDistrict, congressionalDistrict2022, studyArea, places, stateLegislaturesLower, stateLegislaturesLower2022, stateLegislaturesUpper, stateLegislaturesUpper2022, primarySchoolDistrict, secondarySchoolDistrict, unifiedSchoolDistrict, combinedSchoolDistrict, aggregatedAmericanIndianArea2024, tribalArea2024, tribalTract2024, tribalBlockGroup2024];
export const stateLocations = [county, censusTract, censusTract2022, censusBlockGroup, censusBlockGroup2022, congressionalDistrict, congressionalDistrict2022, studyArea, places, stateLegislaturesLower, stateLegislaturesLower2022, stateLegislaturesUpper, stateLegislaturesUpper2022, primarySchoolDistrict, secondarySchoolDistrict, unifiedSchoolDistrict, combinedSchoolDistrict];
export const downloaderLocations = [usState, county, censusTract, censusTract2022, censusBlockGroup, censusBlockGroup2022, censusBlock, censusBlock2020, tribalArea, tribalTract, zipCode, zipCode2022, combinedStatisticalArea, metropolitanStatisticalArea, metropolitanDivision, tribalBlockGroup, congressionalDistrict, congressionalDistrict2022, studyArea, places, stateLegislaturesLower, stateLegislaturesLower2022, stateLegislaturesUpper, stateLegislaturesUpper2022, primarySchoolDistrict, secondarySchoolDistrict, unifiedSchoolDistrict, combinedSchoolDistrict, aggregatedAmericanIndianArea2024, tribalArea2024, tribalTract2024, tribalBlockGroup2024];
export const tribalLocations = [aggregatedAmericanIndianArea2024, tribalArea2024, tribalTract2024, tribalBlockGroup2024];
//Removing {"name": "Universal Service Program"} until we add more
export const dataTypes = [ {"name": "Broadband Fabric Locations"}, {"name": "FCC Broadband Fabric Units"}, {"name": "FCC Broadband Fabric Mobile Coverage"}, {"name": "Availability (FCC Form 477)"}, {"name": "Mobile Coverage (FCC Form 477)"}, {"name": "Adoption (American Community Survey)"}, {"name": "Speed Test (Ookla)"}, {"name": "Mobile Speed Test (Ookla)"}, {"name": "Speed Threshold (Microsoft)"}, {"name": "Emergency Broadband Benefit"}, {"name": "Affordable Connectivity Program"}, {"name": "Rural Digital Opportunity Fund"}, {"name": "Universal Service Programs"}];
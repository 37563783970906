/* eslint-disable react/prop-types */
import React from "react";
import mapboxgl from "mapbox-gl";
import {percentBroadband} from "./Form477";
import {getQuery} from "./getQuery";
import chroma from "chroma-js";
import { usState } from "./GeographicAreaObjects";

mapboxgl.accessToken = "pk.eyJ1IjoibmF0aGFuaWVsbG92aW4iLCJhIjoiY2tjYjR2cGE1MTIwbjJzcGZwb2FpcGJhMCJ9.aqPwkUChkKBq0rN7Q7sGoA";
//Calculate array of colors to paint map polygons
export function paintMap(variable, maxElement) {
	console.log(variable);
	//Capitalize last letter of variable name if it's Y
	const variableName = variable.charAt(variable.length - 2) == "q" ? variable.slice(0, variable.length - 2) + variable.charAt(variable.length - 2).toUpperCase() + variable.slice(variable.length - 1, variable.length) : variable.includes('3g') || variable.includes('4g') || variable.includes('5g') ? variable.replace('3g', '3G').replace('4g', '4G').replace('5g', '5G') : variable;
	console.log(variableName);
	//get max element from fetchedData
	// const maxElement = data.reduce((max, dataElement) => (dataElement[variable] > max ? dataElement[variable] : max), 0);
	//create paint map
	const chromaScale = chroma.scale(chroma.brewer.Blues).domain([0, maxElement]).mode("lab");
	const elements = [0, maxElement*.25, maxElement*.5, maxElement*.75, maxElement]
	const colors = elements.map(element => chromaScale(element)._rgb);
	// const colorrgb =colors.map(color => "rbga(" + color[0] + "," + color[1] + "," + color[2] + ",1)");
	//If rdofEligible, rdofAssigned, rdofUnassigned, rdofDefault, rdofNonDefault, we need a boolean paint
	if (variableName === "rdofEligible" || variableName === "rdofAssigned" || variableName === "rdofUnassigned" || variableName === "rdofDefaultOct122022" || variableName === "rdofNonDefaultOct122022" || variableName === "proposedServiceAreas") {
		const paint = ['case',
				['boolean', ['feature-state', variableName], false],
				['rgba', colors[0][0], colors[0][1], colors[0][2], colors[0][3]],
				['rgba', colors[4][0], colors[4][1], colors[4][2], colors[4][3]]
			];
		return paint;
	}
	console.log(variableName);
 	const paint = [
 		'interpolate-hcl',
 		['linear'],
 		(!variableName.startsWith("percentBroadband") ? ['get', variableName] : ["feature-state", variableName]),
 		0,
 		['rgba', colors[0][0], colors[0][1], colors[0][2], colors[0][3]],
		maxElement*.25, 
		['rgba', colors[1][0], colors[1][1], colors[1][2], colors[1][3]],
		maxElement*.50,
		['rgba', colors[2][0], colors[2][1], colors[2][2], colors[2][3]],
		maxElement*.75,
		['rgba', colors[3][0], colors[3][1], colors[3][2], colors[3][3]],
		maxElement,
 		['rgba', colors[4][0], colors[4][1], colors[4][2], colors[4][3]]
 	];
	console.log(paint);
	return paint;
}
//Calculate color to paint map polygon
function paintColor(max, current) {
	// const scale = chroma.scale(["lightblue", "blue"]).domain([0, max]).mode("lab");
	const scale = chroma.scale(chroma.brewer.Blues).domain([0, max]).mode("lab");
	const color = scale(current)._rgb;
	return "rgba(" + color[0]+ "," + color[1] + "," + color[2] + ",1)";
}
//Return join layer object
export function joinLayer(area, stateId, tribalId) {
	const layer = {
		"id": "join",
		"type": "fill",
		"source": area.source,
		"source-layer": "data",
		"paint": {
			//Array for colors for polygons
			"fill-color": "rgb(256, 0, 0)",
			"fill-opacity": 0.0
		}
	}
	if (stateId.id) {
		layer.filter = ["==", area.match === "GEOID" ? "STATEFP" : "STATEFP10", stateId.id];
	}
	if (tribalId.id) {
		layer.filter = ["==", "AIANNHCE", tribalId.id];
	}
	return layer;
}
//Return join layer object, filtering for specific state
export function joinLayerState(area, stateId) {
	const layerDesc = {...joinLayer(area), "filter": ["==", area.match === "GEOID" ? "STATEFP" : "STATEFP10", stateId]};
	console.log(layerDesc);
	return layerDesc;
}
//Return outline layer object
export function outlineLayer(area) {
	return {
		"id": "outline",
		"type": "line",
		"source": area.source,
		"source-layer": "data",
		"layout": {
			"line-cap": "round",
			"line-join": "round"
		},
		"paint": {
			"line-opacity": 0.9,
			"line-color": "rgb(256, 256, 256)",
			"line-width": 0.1
		}
	};
}
//returns source object
export function layerSource(area) {
	if (area === usState) {
		return {
			type: "vector",
			"tiles": ["https://storage.googleapis.com/tpi-broadband-map-public/states/{z}/{x}/{y}.pbf"]
		};
	}
	return {
		type: "vector",
		"url": "mapbox://nathaniellovin." + area.source,
		promoteId: area.match
	};
}
//Turn on clicker pointer over map
export function mapClickerOn(map) {
	map.on("mouseenter", "join", function () {
		map.getCanvas().style.cursor = "pointer";
	});

	// Change it back to a pointer when it leaves.
	map.on("mouseleave", "join", function () {
		map.getCanvas().style.cursor = "";
	});
}
//Mapbox information popup
export class PopupText extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			area: props.area,
			variable: props.variable,
			time: props.time,
			name: props.name,
			data: props.data,
			fetchedAdditionalData: [],
			areaId: props.areaId
		};
		this.getPopData = this.getPopData.bind(this);
	}

	componentDidMount(){
		this.getPopData();
	}

	getPopData() {
		console.log("getting data");
		const validData = this.state.area.dataSources.map((e) => ({"dataTypes":e.dataTypes, "lastYear": e.dataYears[e.dataYears.length - 1]}));
		const combinedData = validData.map(dataSources => dataSources.dataTypes.map(e => ({"code": e.code + dataSources.lastYear.code, "name": e.name + (dataSources.lastYear.name === "" ? "" : " (" + dataSources.lastYear.name + ")"), "label": e.label}))).flat();
		const combinedDataRevised = combinedData.filter(o => !o.code.includes("percentBroadband") && o.code !== "rdofEligible" && o.code !== "rdofAssigned" && o.code !== "rdofUnassigned");
		// const query = `{${this.state.area.plural}(${this.state.area.id}:"${this.state.areaId}"){${this.state.area.id} ${combinedDataRevised.reduce((queryString, e) => (queryString + e.code + " "), "")}}}`;
		// const self = this;
		// getQuery(query).then( function (results) {
		// 	self.setState({fetchedAdditionalData: combinedDataRevised.map(e => ({"name": e.name, "data": results.data[self.state.area.plural][0][e.code], "label": e.label}))}, console.log(self.state.fetchedAdditionalData));
		// });
		this.setState({fetchedAdditionalData: combinedDataRevised});
	}

	render(){
		return (
			<div id={this.state.areaId} className={"popup-node"}>
				<p className={"popup-p"}><b>{this.state.area.name}: {this.state.name}</b></p>
				<p className={"popup-p"}><b>{this.state.variable.name + (this.state.time.name === "" ? "" : " (" + this.state.time.name + ")")}</b>: {this.state.data[this.state.variable.code + this.state.time.code]}{this.state.variable.label}</p>
				<p className={"popup-p"}><b>Other Data Points:</b></p>
				{this.state.fetchedAdditionalData.map(e => (e.name !== this.state.variable.name + " (" + this.state.time.name + ")") && (this.state.data[e.code] != null) && (<p className={"popup-p"} key={e.name}>{e.name}: {e.label === "%" && this.state.data[e.code] <= 1 ? (this.state.data[e.code] * 100).toFixed(0) : this.state.data[e.code].toFixed(0)}{e.label !== "%" ? " " : ""}{e.label}</p>))}
			</div>
		);
	}
}
import { DataVariable, CodeAndEnglish, DataSource } from "./ModelClasses";

export const rdofEligible = new DataVariable("rdofEligible", "Eligible Census Blocks", "");
export const rdofAssigned = new DataVariable("rdofAssigned", "Assigned Census Blocks", "");
export const rdofUnassigned = new DataVariable("rdofUnassigned", "Unassigned Census Blocks", "");
//Not sure if we're going to keep this updated in one column or multiple
export const rdofDefault = new DataVariable("rdofDefaultDec202023", "Defaulted Census Blocks", "");
export const rdofNonDefault = new DataVariable("rdofNonDefaultDec202023", "Non-Defaulted Census Blocks", "");
export const ntiaProposedAreas = new DataVariable("proposedServiceAreas", "Proposed Service Areas", "");

export const rdofAssignedSupport = new DataVariable("rdofAssignedSupport", "RDOF Assigned Support (10 Year)", " Dollars");

export const ntiaProposed = new DataVariable("proposedServiceAreas", "NTIA Proposed Service Areas", "");

export const emptyTime = new CodeAndEnglish("", "");
export const rdofData = new DataSource("Rural Digital Opportunity Fund", [rdofEligible, rdofAssigned, rdofUnassigned, rdofDefault, rdofNonDefault], [emptyTime], "");
export const rdofAssignedSupportData = new DataSource("Rural Digital Opportunity Fund", [rdofAssignedSupport], [emptyTime], "");
export const rdofEligibleAndSupportData = new DataSource("Rural Digital Opportunity Fund", [rdofEligible, rdofAssigned, rdofUnassigned, rdofDefault, rdofNonDefault, rdofAssignedSupport], [emptyTime], "");
export const ntiaProposedAreasData = new DataSource("NTIA Proposed Areas", [ntiaProposedAreas], [emptyTime], "");

import { DataVariable, CodeAndEnglish, DataSource } from "./ModelClasses";

export const enrolledHouseholds = new DataVariable("acpEnrolledHouseholds", "Enrolled Households", "Households");
export const percentEnrolled = new DataVariable("acpPercentEnrolled", "Percent of Households Enrolled", "%");

export const enrolledHouseholdsState = new DataVariable("acpTotalEnrolledHouseholds", "Enrolled Households", "Households");
export const newlyEnrolledHouseholdsState = new DataVariable("acpNewlyEnrolledHouseholds", "Newly Enrolled Households", "Households");
export const percentEnrolledState = new DataVariable("acpPercentEnrolledHouseholds", "Percent of Households Enrolled", "%");

export const february072022 = new CodeAndEnglish("February072022", "Week Ending February 7, 2022");
export const february142022 = new CodeAndEnglish("February142022", "Week Ending February 14, 2022");
export const february212022 = new CodeAndEnglish("February212022", "Week Ending February 21, 2022");
export const february282022 = new CodeAndEnglish("February282022", "Week Ending February 28, 2022");
export const march072022 = new CodeAndEnglish("March072022", "Week Ending March 7, 2022");
export const march142022 = new CodeAndEnglish("March142022", "Week Ending March 14, 2022");
export const march212022 = new CodeAndEnglish("March212022", "Week Ending March 21, 2022");
export const march282022 = new CodeAndEnglish("March282022", "Week Ending March 28, 2022");
export const april042022 = new CodeAndEnglish("April042022", "Week Ending April 4, 2022");
export const april112022 = new CodeAndEnglish("April112022", "Week Ending April 11, 2022");
export const april182022 = new CodeAndEnglish("April182022", "Week Ending April 18, 2022");
export const april252022 = new CodeAndEnglish("April252022", "Week Ending April 25, 2022");
export const may022022 = new CodeAndEnglish("May022022", "Week Ending May 2, 2022");
export const may092022 = new CodeAndEnglish("May092022", "Week Ending May 9, 2022");
export const may162022 = new CodeAndEnglish("May092022", "Week Ending May 16, 2022");
export const may232022 = new CodeAndEnglish("May232022", "Week Ending May 23, 2022");
export const may302022 = new CodeAndEnglish("May302022", "Week Ending May 30, 2022");
export const june062022 = new CodeAndEnglish("June062022", "Week Ending June 6, 2022");
export const june132022 = new CodeAndEnglish("June132022", "Week Ending June 13, 2022");
export const june202022 = new CodeAndEnglish("June202022", "Week Ending June 20, 2022");
export const june272022 = new CodeAndEnglish("June272022", "Week Ending June 27, 2022");
export const july042022 = new CodeAndEnglish("July042022", "Week Ending July 4, 2022");
export const july112022 = new CodeAndEnglish("July112022", "Week Ending July 11, 2022");
export const july182022 = new CodeAndEnglish("July182022", "Week Ending July 18, 2022");
export const july252022 = new CodeAndEnglish("July252022", "Week Ending July 25, 2022");
export const august012022 = new CodeAndEnglish("August012022", "Week Ending August 1, 2022");
export const august082022 = new CodeAndEnglish("August082022", "Week Ending August 8, 2022");
export const august152022 = new CodeAndEnglish("August152022", "Week Ending August 15, 2022");
export const august222022 = new CodeAndEnglish("August222022", "Week Ending August 22, 2022");
export const august292022 = new CodeAndEnglish("August292022", "Week Ending August 29, 2022");
export const september052022 = new CodeAndEnglish("September052022", "Week Ending September 5, 2022");
export const september122022 = new CodeAndEnglish("September122022", "Week Ending September 12, 2022");
export const september192022 = new CodeAndEnglish("September192022", "Week Ending September 19, 2022");
export const september262022 = new CodeAndEnglish("September262022", "Week Ending September 26, 2022");
export const october032022 = new CodeAndEnglish("October032022", "Week Ending October 3, 2022");
export const october102022 = new CodeAndEnglish("October102022", "Week Ending October 10, 2022");
export const october172022 = new CodeAndEnglish("October172022", "Week Ending October 17, 2022");
export const october242022 = new CodeAndEnglish("October242022", "Week Ending October 24, 2022");
export const october312022 = new CodeAndEnglish("October312022", "Week Ending October 31, 2022");
export const november072022 = new CodeAndEnglish("November072022", "Week Ending November 7, 2022");
export const november142022 = new CodeAndEnglish("November142022", "Week Ending November 14, 2022");
export const november212022 = new CodeAndEnglish("November212022", "Week Ending November 21, 2022");
export const november282022 = new CodeAndEnglish("November282022", "Week Ending November 28, 2022");
export const december052022 = new CodeAndEnglish("December052022", "Week Ending December 5, 2022");
export const december122022 = new CodeAndEnglish("December122022", "Week Ending December 12, 2022");
export const december192022 = new CodeAndEnglish("December192022", "Week Ending December 19, 2022");
export const december262022 = new CodeAndEnglish("December262022", "Week Ending December 26, 2022");
export const january022023 = new CodeAndEnglish("January022023", "Week Ending January 2, 2023");
export const january092023 = new CodeAndEnglish("January092023", "Week Ending January 9, 2023");
export const january162023 = new CodeAndEnglish("January162023", "Week Ending January 16, 2023");
export const january232023 = new CodeAndEnglish("January232023", "Week Ending January 23, 2023");
export const january302023 = new CodeAndEnglish("January302023", "Week Ending January 30, 2023");
export const february062023 = new CodeAndEnglish("February062023", "Week Ending February 6, 2023");
export const february132023 = new CodeAndEnglish("February132023", "Week Ending February 13, 2023");
export const february202023 = new CodeAndEnglish("February202023", "Week Ending February 20, 2023");
export const february272023 = new CodeAndEnglish("February272023", "Week Ending February 27, 2023");
export const march062023 = new CodeAndEnglish("March062023", "Week Ending March 6, 2023");
export const march132023 = new CodeAndEnglish("March132023", "Week Ending March 13, 2023");
export const march202023 = new CodeAndEnglish("March202023", "Week Ending March 20, 2023");
export const march272023 = new CodeAndEnglish("March272023", "Week Ending March 27, 2023");
export const april032023 = new CodeAndEnglish("April032023", "Week Ending April 3, 2023");
export const april102023 = new CodeAndEnglish("April102023", "Week Ending April 10, 2023");
export const april172023 = new CodeAndEnglish("April172023", "Week Ending April 17, 2023");
export const april242023 = new CodeAndEnglish("April242023", "Week Ending April 24, 2023");
export const may012023 = new CodeAndEnglish("May012023", "Week Ending May 1, 2023");
export const may082023 = new CodeAndEnglish("May082023", "Week Ending May 8, 2023");
export const may152023 = new CodeAndEnglish("May152023", "Week Ending May 15, 2023");
export const may222023 = new CodeAndEnglish("May222023", "Week Ending May 22, 2023");
export const may292023 = new CodeAndEnglish("May292023", "Week Ending May 29, 2023");
export const june052023 = new CodeAndEnglish("June052023", "Week Ending June 5, 2023");
export const june122023 = new CodeAndEnglish("June122023", "Week Ending June 12, 2023");
export const june192023 = new CodeAndEnglish("June192023", "Week Ending June 19, 2023");
export const june262023 = new CodeAndEnglish("June262023", "Week Ending June 26, 2023");
export const july032023 = new CodeAndEnglish("July032023", "Week Ending July 3, 2023");
export const july102023 = new CodeAndEnglish("July102023", "Week Ending July 10, 2023");
export const july172023 = new CodeAndEnglish("July172023", "Week Ending July 17, 2023");
export const july242023 = new CodeAndEnglish("July172023", "Week Ending July 24, 2023");
export const july312023 = new CodeAndEnglish("July312023", "Week Ending July 31, 2023");
export const august072023 = new CodeAndEnglish("August072023", "Week Ending August 7, 2023");
export const august142023 = new CodeAndEnglish("August142023", "Week Ending August 14, 2023");
export const august212023 = new CodeAndEnglish("August212023", "Week Ending August 21, 2023");
export const august282023 = new CodeAndEnglish("August282023", "Week Ending August 28, 2023");
export const september042023 = new CodeAndEnglish("September042023", "Week Ending September 4, 2023");
export const september182023 = new CodeAndEnglish("September182023", "Week Ending September 18, 2023");
export const september252023 = new CodeAndEnglish("September252023", "Week Ending September 25, 2023");
export const october022023 = new CodeAndEnglish("October022023", "Week Ending October 2, 2023");
export const october092023 = new CodeAndEnglish("October092023", "Week Ending October 9, 2023");
export const october162023 = new CodeAndEnglish("October162023", "Week Ending October 16, 2023");
export const october232023 = new CodeAndEnglish("October232023", "Week Ending October 23, 2023");
export const october302023 = new CodeAndEnglish("October302023", "Week Ending October 30, 2023");
export const november062023 = new CodeAndEnglish("November062023", "Week Ending November 6, 2023");
export const november132023 = new CodeAndEnglish("November132023", "Week Ending November 13, 2023");
export const november202023 = new CodeAndEnglish("November202023", "Week Ending November 20, 2023");
export const november272023 = new CodeAndEnglish("November272023", "Week Ending November 27, 2023");
export const december042023 = new CodeAndEnglish("December042023", "Week Ending December 4, 2023");
export const december112023 = new CodeAndEnglish("December112023", "Week Ending December 11, 2023");
export const december182023 = new CodeAndEnglish("December182023", "Week Ending December 18, 2023");
export const december252023 = new CodeAndEnglish("December252023", "Week Ending December 25, 2023");
export const january012024 = new CodeAndEnglish("January012022", "Week Ending January 1, 2024");
export const january082024 = new CodeAndEnglish("January082024", "Week Ending January 8, 2024");
export const january152024 = new CodeAndEnglish("January152024", "Week Ending January 15, 2024");
export const january222024 = new CodeAndEnglish("January222024", "Week Ending January 22, 2024");
export const january292024 = new CodeAndEnglish("January222024", "Week Ending January 29, 2024");
export const february052024 = new CodeAndEnglish("February052024", "Week Ending February 5, 2024");
export const february082024 = new CodeAndEnglish("February052024", "End of Enrollment February 8, 2024");

export const february012022 = new CodeAndEnglish("February012022", "As of February 1, 2022");
export const march012022 = new CodeAndEnglish("March012022", "As of March 1, 2022");
export const may012022 = new CodeAndEnglish("May012022", "As of May 1, 2022");
export const june012022 = new CodeAndEnglish("June012022", "As of June 1, 2022");
export const july012022 = new CodeAndEnglish("July012022", "As of July 1, 2022");
export const september012022 = new CodeAndEnglish("September012022", "As of September 1, 2022");
export const october012022 = new CodeAndEnglish("October012022", "As of October 1, 2022");
export const november012022 = new CodeAndEnglish("November012022", "As of November 1, 2022");
export const december012022 = new CodeAndEnglish("December012022", "As of December 1, 2022");
export const january012023 = new CodeAndEnglish("January012023", "As of January 1, 2023");
export const february012023 = new CodeAndEnglish("February012023", "As of February 1, 2023");
export const march012023 = new CodeAndEnglish("March012023", "As of March 1, 2023");
export const april012023 = new CodeAndEnglish("April012023", "As of April 1, 2023");
export const _may012023 = new CodeAndEnglish("May012023", "As of May 1, 2023");
export const june012023 = new CodeAndEnglish("June012023", "As of June 1, 2023");
export const july012023 = new CodeAndEnglish("July012023", "As of July 1, 2023");
export const august012023 = new CodeAndEnglish("August012023", "As of August 1, 2023");
export const september012023 = new CodeAndEnglish("September012023", "As of September 1, 2023");
export const october012023 = new CodeAndEnglish("October012023", "As of October 1, 2023");
export const november012023 = new CodeAndEnglish("November012023", "As of November 1, 2023");
export const december012023 = new CodeAndEnglish("December012023", "As of December 1, 2023");
export const _january012024 = new CodeAndEnglish("January012023", "As of January 1, 2024");
export const _february082024 = new CodeAndEnglish("February012024", "As of February 8, 2024");

export const simpleStateACP = new DataSource("Affordable Connectivity Program", [percentEnrolledState], [february072022, march072022, april042022, may022022, june062022, july042022, august012022, september052022, october032022, november072022, december052022, january022023, february062023, march062023, april032023, may012023, june052023, july032023, august072023, september042023, october022023, november062023, december042023, january012024, february082024], "%B%d%Y");
export const simpleCountyACP = new DataSource("Affordable Connectivity Program", [percentEnrolled], [february012022, may012022, june012022, september012022, october012022, november012022, december012022, january012023, february012023, march012023, april012023, _may012023, june012023, july012023, august012023, september012023, october012023, november012023, december012023, _january012024, _february082024], "%B%d%Y");

export const stateACP = new DataSource("Affordable Connectivity Program", [enrolledHouseholdsState, newlyEnrolledHouseholdsState, percentEnrolledState], [february072022, february142022, february212022, february282022, march072022, march142022, march212022, march282022, april042022, april112022, april182022, april252022, may022022, may092022, may162022, may232022, may302022, june062022, june132022, june202022, june272022, july042022, july112022, july182022, july252022, august012022, august082022, august152022, august222022, august292022, september052022, september122022, september192022, september262022, october032022, october102022, october172022, october242022, october312022, november072022, november142022, november212022, november282022, december052022, december122022, december192022, december262022, january022023, january092023, january162023, january232023, january302023, february062023, february132023, february202023, february272023, march062023, march132023, march202023, march272023, april032023, april102023, april172023, april242023, may012023, may082023, may152023, may222023, may292023, june052023, june122023, june192023, june262023, july032023, july102023, july172023, july242023, july312023, august072023, august142023, august212023, august282023, september042023, september182023, september252023, october022023, october092023, october162023, october232023, october302023, november062023, november132023, november202023, november272023, december042023, december112023, december182023, december252023, january012024, january082024, january152024, january222024, january292024, february052024, february082024], "%B%d%Y");
export const zipACP = new DataSource("Affordable Connectivity Program", [enrolledHouseholds, percentEnrolled], [february012022, march012022, may012022, june012022, july012022, september012022, october012022, november012022, december012022, january012023, february012023, march012023, april012023, _may012023, june012023, july012023, august012023, september012023, october012023, november012023, december012023, _january012024, _february082024], "%B%d%Y");
export const countyACP = new DataSource("Affordable Connectivity Program", [enrolledHouseholds, percentEnrolled], [february012022, may012022, june012022, september012022, october012022, november012022, december012022, january012023, february012023, march012023, april012023, _may012023, june012023, july012023, august012023, september012023, october012023, november012023, december012023, _january012024, _february082024], "%B%d%Y");

export class CodeAndEnglish {
	//Store the english label and the code equivalent
	//For example "Consumer Download Speed" and "consumerDownload"
	constructor(code, name) {
		this.code = code;
		this.name = name;
	}
}
export class DataVariable extends CodeAndEnglish {
	constructor(code, name, label) {
		super(code, name);
		this.label = label;
	}
}
export class DataSource {
	//Store data related to data sources (Form 477, etc.)
	constructor(name, dataTypes, dataYears, parser) {
		this.name = name;
		this.dataTypes = dataTypes;
		this.dataYears = dataYears;
		//Time parser for dataYears
		this.parser = parser;
	}
}
export class GraphDescription {
	//Store a description of the graph
	constructor(name) {
		this.name = name;
	}
}
export class GeographicArea {
	//Store data related to geographic areas
	//TODO: When possible, I would like to redo the mapbox tiles so that this is simplier
	//I want source = layer = plural
	//polyName to be "NAME" consistantely (instead of NAME10 sometimes)
	//And match to be the id instead of GEOID/GEOID10
	//Which would reduce the size of this down to five instead of nine
	constructor(name, source, layer, match, plural, id, polyName, schemaName, dataSources, graphs) {
		//name is just the name of the area
		this.name = name;
		//Source is the name of the mapbox tile in storage
		this.source = source;
		//Layer is the name of the layer in the mapbox tiles
		this.layer = layer;
		//match is the id code that we match between the tiles and the data
		this.match = match;
		//Plural is the pluralized version of the geographic area name
		this.plural = plural;
		//id is the id name that replaced match in the data (so "stateId", or "countyId")
		this.id = id;
		//polyName is the location in the tiles of the english name for the areas
		this.polyName = polyName;
		//schemaName is the name in the graphql schema 
		this.schemaName = schemaName;
		//dataSources is a list of DataSource objects that the GeographicArea can use
		this.dataSources = dataSources;
		//graphs is a list of Graph objects that the GeographicArea can use
		this.graphs = graphs;
	}
}

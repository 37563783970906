import { DataVariable, CodeAndEnglish, DataSource } from "./ModelClasses";

//Form 477 data
//Data types
export const consumerDownload = new DataVariable("consumerDownload", "Avg. Max Advertised Download Speed", "Mbps");
export const consumerUpload = new DataVariable("consumerUpload", "Avg. Max Advertised Upload Speed", "Mbps");
export const percentBroadband = new DataVariable("percentBroadband", "Percent with Broadband Access", "%");
export const percent4g = new DataVariable("mobile4gCoverage", "Percent with 4G Coverage", "%");
export const percent5g = new DataVariable("mobile5gCoverage", "Percent with 5G Coverage", "%");
export const companies = new DataVariable("companies", "Avg. Number of Companies", "");
export const dsl = new DataVariable("dsl", "Percent with DSL Access", "%");
export const cable = new DataVariable("cable", "Percent with Cable Access", "%");
export const fiber = new DataVariable("fiber", "Percent with Fiber Access", "%");
//Data times
export const dec2015 = new CodeAndEnglish("Dec2015", "December 2015");
export const jun2016 = new CodeAndEnglish("Jun2016", "June 2016");
export const dec2016 = new CodeAndEnglish("Dec2016", "December 2016");
export const jun2017 = new CodeAndEnglish("Jun2017", "June 2017");
export const dec2017 = new CodeAndEnglish("Dec2017", "December 2017");
export const jun2018 = new CodeAndEnglish("Jun2018", "June 2018");
export const dec2018 = new CodeAndEnglish("Dec2018", "December 2018");
export const jun2019 = new CodeAndEnglish("Jun2019", "June 2019");
export const dec2019 = new CodeAndEnglish("Dec2019", "December 2019");
export const jun2020 = new CodeAndEnglish("Jun2020", "June 2020");
export const dec2020 = new CodeAndEnglish("Dec2020", "December 2020");
export const jun2021 = new CodeAndEnglish("Jun2021", "June 2021");
export const dec2021 = new CodeAndEnglish("Dec2021", "December 2021");
export const Form477Data = new DataSource("Availability (FCC Form 477)", [consumerDownload, consumerUpload, percentBroadband, companies, dsl, cable, fiber], [dec2015, jun2016, dec2016, jun2017, dec2017, jun2018, dec2018, jun2019, dec2019, jun2020, dec2020, jun2021, dec2021], "%b%Y");
export const broadbandPercent = new DataSource("Percent with Broadband", [percentBroadband], [dec2015, jun2016, dec2016, jun2017, dec2017, jun2018, dec2018, jun2019, dec2019, jun2020, dec2020, jun2021, dec2021], "%b%Y");
export const censusBlockForm477 = new DataSource("Availability (FCC Form 477)", [consumerDownload, consumerUpload, companies, dsl, cable, fiber], [dec2015, jun2016, dec2016, jun2017, dec2017, jun2018, dec2018, jun2019, dec2019, jun2020, dec2020, jun2021, dec2021], "%b%Y");
export const MobileForm477Data = new DataSource("Mobile Coverage (FCC Form 477)", [percent4g, percent5g], [dec2015, jun2016, dec2016, jun2017, dec2017, jun2018, dec2018, jun2019, dec2019, jun2020, dec2020, jun2021, dec2021], "%b%Y");
import { DataVariable, CodeAndEnglish, DataSource } from "./ModelClasses";

//USAC data types
// export const amountDisbursed = new DataVariable("amountDisbursed", "Amount Disbursed", "Dollars");
// export const locationsDeployed = new DataVariable("locationsDeployed", "Locations Deployed", "Locations");
// export const highCostDisbursements = new DataVariable("highCostDisbursements", "High Cost Disbursements", "Dollars");
export const lifeline = new DataVariable("lifeline", "Lifeline Disbursements", "Dollars");
export const highcost = new DataVariable("highCost", "High Cost Disbursements", "Dollars");
export const eRate = new DataVariable("erate", "E-Rate Disbursements", "Dollars");
export const ruralHeath = new DataVariable("ruralHealth", "Rural Health Care Disbursements", "Dollars");
//Data times
export const year2015 = new CodeAndEnglish("2015", "2015");
export const year2016 = new CodeAndEnglish("2016", "2016");
export const year2017 = new CodeAndEnglish("2017", "2017");
export const year2018 = new CodeAndEnglish("2018", "2018");
export const year2019 = new CodeAndEnglish("2019", "2019");
export const year2020 = new CodeAndEnglish("2020", "2020");
export const year2021 = new CodeAndEnglish("2021", "2021");
export const year2022 = new CodeAndEnglish("2022", "2022");
export const year2023 = new CodeAndEnglish("2023", "2023");

export const emptyTime = new CodeAndEnglish("", "2019");
export const usacYears = [year2015, year2016, year2017, year2018, year2019, year2020, year2021, year2022, year2023]
// export const usacData = new DataSource("Universal Service Program", [amountDisbursed, locationsDeployed], [emptyTime], "");
export const usacData = new DataSource("Universal Service Programs", [lifeline, highcost, eRate, ruralHeath], [year2015, year2016, year2017, year2018, year2019, year2020, year2021, year2022], "%Y");
// export const highCostCounty = new DataSource("Universal Service Program", [highCostDisbursements], [year2020], "");
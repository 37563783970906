import { DataVariable, CodeAndEnglish, DataSource } from "./ModelClasses";

export const enrolledHouseholds = new DataVariable("ebbEnrolledHouseholds", "Enrolled Households", "Households");
export const percentEnrolled = new DataVariable("ebbPercentEnrolled", "Percent of Households Enrolled", "%");

export const enrolledHouseholdsState = new DataVariable("ebbTotalEnrolledHouseholds", "Enrolled Households", "Households");
export const newlyEnrolledHouseholdsState = new DataVariable("ebbNewlyEnrolledHouseholds", "Newly Enrolled Households", "Households");
export const percentEnrolledState = new DataVariable("ebbPercentEnrolledHouseholds", "Percent of Households Enrolled", "%");

export const june292021 = new CodeAndEnglish("June292021", "As of June 29, 2021 (zip3 aggregated)");
export const june072021 = new CodeAndEnglish("June072021", "Week Ending June 7, 2021");
export const june142021 = new CodeAndEnglish("June142021", "Week Ending June 14, 2021");
export const june212021 = new CodeAndEnglish("June212021", "Week Ending June 21, 2021");
export const june282021 = new CodeAndEnglish("June282021", "Week Ending June 28, 2021");
export const july052021 = new CodeAndEnglish("July052021", "Week Ending July 05, 2021");
export const july122021 = new CodeAndEnglish("July122021", "Week Ending July 12, 2021");
export const july192021 = new CodeAndEnglish("July192021", "Week Ending July 19, 2021");
export const july262021 = new CodeAndEnglish("July262021", "Week Ending July 26, 2021");
export const july262021Zip3 = new CodeAndEnglish("July262021", "As of July 26, 2021 (zip3 aggregated)");
export const august022021 = new CodeAndEnglish("August022021", "Week Ending August 02, 2021");
export const august092021 = new CodeAndEnglish("August092021", "Week Ending August 09, 2021");
export const august162021 = new CodeAndEnglish("August162021", "Week Ending August 16, 2021");
export const august232021 = new CodeAndEnglish("August232021", "Week Ending August 23, 2021");
export const august302021 = new CodeAndEnglish("August302021", "Week Ending August 30, 2021");
export const august302021Zip3 = new CodeAndEnglish("August302021", "As of August 30, 2021 (zip3 aggregated)");
export const september272021Zip3 = new CodeAndEnglish("September272021", "As of September 27, 2021 (zip3 aggregated)");
export const september062021 = new CodeAndEnglish("September062021", "Week Ending September 06, 2021");
export const september132021 = new CodeAndEnglish("September132021", "Week Ending September 13, 2021");
export const september202021 = new CodeAndEnglish("September202021", "Week Ending September 20, 2021");
export const september272021 = new CodeAndEnglish("September272021", "Week Ending September 27, 2021");
export const october042021 = new CodeAndEnglish("October042021", "Week Ending October 04, 2021");
export const october112021 = new CodeAndEnglish("October112021", "Week Ending October 11, 2021");
export const october182021 = new CodeAndEnglish("October182021", "Week Ending October 18, 2021");
export const october252021 = new CodeAndEnglish("October252021", "Week Ending October 25, 2021");
export const october252021Zip3 = new CodeAndEnglish("October252021", "As of October 25, 2021 (zip3 aggregated)");
export const november012021 = new CodeAndEnglish("November012021", "Week Ending November 01, 2021");
export const november082021 = new CodeAndEnglish("November082021", "Week Ending November 08, 2021");
export const november152021 = new CodeAndEnglish("November152021", "Week Ending November 15, 2021");
export const november222021 = new CodeAndEnglish("November222021", "Week Ending November 22, 2021");
export const november222021Zip3 = new CodeAndEnglish("November222021", "As of November 22, 2021 (zip3 aggregated)");
export const november292021 = new CodeAndEnglish("November292021", "Week Ending November 29, 2021");
export const december062021 = new CodeAndEnglish("December062021", "Week Ending December 06, 2021");
export const december132021 = new CodeAndEnglish("December132021", "Week Ending December 13, 2021");
export const december132021Zip3 = new CodeAndEnglish("December132021", "As of December 13, 2021 (zip3 aggregated)");
export const december272021 = new CodeAndEnglish("December272021", "Week Ending December 27, 2021");
export const december272021Zip3 = new CodeAndEnglish("December272021", "As of December 27, 2021 (zip3 aggregated)");
export const january032022 = new CodeAndEnglish("January032022", "Week Ending January 03, 2022");


export const August012021 =  new CodeAndEnglish("August012021", "As of August 01, 2021");
export const September012021 =  new CodeAndEnglish("August012021", "As of September 01, 2021");
export const October012021 =  new CodeAndEnglish("August012021", "As of October 01, 2021");
export const November012021 =  new CodeAndEnglish("November012021", "As of November 01, 2021");
export const December302021 =  new CodeAndEnglish("December302021", "As of December 30, 2021");

export const zipEBB = new DataSource("Emergency Broadband Benefit", [enrolledHouseholds, percentEnrolled], [june292021, july262021Zip3, august302021Zip3, August012021, September012021, september272021Zip3, october252021Zip3, October012021, november222021Zip3, November012021, december132021Zip3, december272021Zip3, December302021], "%B%d%Y");
// export const zip5EBB = new DataSource("Emergency Broadband Benefit", [enrolledHouseholdsState, percentEnrolledState], [june292021, july262021, august302021], "%B%d%Y");
export const stateEBB = new DataSource("Emergency Broadband Benefit", [enrolledHouseholdsState, newlyEnrolledHouseholdsState, percentEnrolledState], [june072021, june142021, june212021, june282021, july052021, july122021, july192021, july262021, august022021, august092021, august162021, august232021, august302021, september062021, september132021, september202021, september272021, october042021, october112021, october182021, october252021, november012021, november082021, november152021, november222021, november292021, december062021, december132021, december272021, january032022], "%B%d%Y");
export const countyEBB = new DataSource("Emergency Broadband Benefit", [enrolledHouseholds, percentEnrolled], [November012021], "%B%d%Y");
import React from "react";
import { DropdownForm } from "./SelectionComponents";
import { downloaderLocations, usState, nation} from "./GeographicAreaObjects";
import PropTypes from 'prop-types';
import { fips } from "./StateFIPS"
import TopNav from "./TopNav";
import { getBaseURL } from "./getQuery";
// import {BroadbandFields} from "./GeographicForm";
import { Login, ChangePassword, } from "./SimpleForm";

const Checkbox = ({ type = 'checkbox', name, code, checked = false, onChange }) => (
	<div>
  		<p><input type={type} name={code} checked={checked} onChange={onChange} />
  		{name}</p>
	</div>
);

const yearOptions = [{"name": "All Available Years", code: false},{"name": "Most Recent Year Only", code: true}] 

BroadbandFields.propTypes = {
	class: PropTypes.string,
	downloadSpeed: PropTypes.number.isRequired,
	uploadSpeed: PropTypes.number.isRequired,
	handleBroadbandDownloadChange: PropTypes.func.isRequired,
	handleBroadbandUploadChange: PropTypes.func.isRequired,
	id: PropTypes.number.isRequired,
};

Checkbox.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

function variableCollapse(area) {
	return area.dataSources.reduce((acc, curr) => {
		curr.dataTypes.reduce((dict, type) => {
			dict[type.code] = false;
			return dict;
		}, acc);
		return acc;
	}, {});
}

function BroadbandFields(props) {
	return <div className={props.class}>
		<div className={props.class + "-left"}>
			<p style={{margin: 0}}>Minimum Download Speed for Broadband: 
				<input value={props.downloadSpeed} style={{width: 30}} onChange={props.handleBroadbandDownloadChange} id={props.id} /></p>
		</div>
		<div className={props.class + "-right"}>
			<p style={{margin: 0}}>Minimum Upload Speed for Broadband: 
				<input value={props.uploadSpeed} style={{width: 30}} onChange={props.handleBroadbandUploadChange} id={props.id} /></p>
		</div>
	</div>;
}

function tooltipText(name) {
	//{"name": "Broadband Fabric Locations"}, {"name": "FCC Broadband Fabric Units"}, {"name": "FCC Broadband Fabric Mobile Coverage"}, {"name": "Availability (FCC Form 477)"}, {"name": "Mobile Coverage (FCC Form 477)"}, {"name": "Adoption (American Community Survey)"}, {"name": "Speed Test (Ookla)"}, {"name": "Mobile Speed Test (Ookla)"}, {"name": "Speed Threshold (Microsoft)"}, {"name": "Emergency Broadband Benefit"}, {"name": "Affordable Connectivity Program"}, {"name": "Rural Digital Opportunity Fund"}];
	const tooltips = {
		"Broadband Fabric Locations": "Broadband Fabric Locations are physical structures that the FCC has identified as being capable of reciving broadband service. These can range from a single family home to a large office building.",
		"FCC Broadband Fabric Units": "Broadband Fabric Units are single customers for broadband service. A location may have multiple units, such as an apartment building which would be counted as a single location but multiple units.",
		"FCC Broadband Fabric Mobile Coverage": "Percent of area covered by mobile broadband service according to the FCC's Broadband Fabric.",
		"Availability (FCC Form 477)": "Form 477 is reported at the census block level, and aggregated up by us using a population weighted average of the maximum advertised download and upload speeds.",
		"Mobile Coverage (FCC Form 477)": "Percent of census blocks covered by mobile broadband service according to the FCC's Form 477, aggregated up by us.",
		"Adoption (American Community Survey)": "The American Community Survey is a survey of households conducted by the US Census Bureau. The survey asks questions about internet access, and is conducted every year.",
		"Speed Test (Ookla)": "Ookla collects speed test data from users. Released as part of the Ookla Open Data. Collected from wireless devices on fixed networks. The data is aggregated up by us from their hexagon tiles.",
		"Mobile Speed Test (Ookla)": "Ookla collects speed test data from users of their Speedtest.net application.  Released as part of the Ookla Open Data. Collected from wireless devices on mobile networks. The data is aggregated up by us from their hexagon tiles.",
		"Speed Threshold (Microsoft)": "Microsoft model of how many people are getting actual service about the broadband minimum, based on downloads from Windows Update and other sources.",
		"Emergency Broadband Benefit": "The Emergency Broadband Benefit Program provided a discount of up to $50 per month towards broadband service for eligible households and up to $75 per month for households on qualifying Tribal lands. The program was funded by the Consolidated Appropriations Act, 2021 and replaced by the Affordable Connectivity Program. This data comes from the Universal Service Administrative Company (USAC).",
		"Affordable Connectivity Program": "The Affordable Connectivity Program provides a discount of up to $30 per month towards broadband service for eligible households and up to $75 per month for households on qualifying Tribal lands. The program is funded by the Infrastructure Investment and Jobs Act and replaced the Emergency Broadband Benefit Program.  This data comes from the Universal Service Administrative Company (USAC).",
		"Rural Digital Opportunity Fund": "The Rural Digital Opportunity Fund (RDOF) is a $20.4 billion program to expand broadband service to unserved and underserved areas. This data comes from the FCC tracking of census blocks and funding awards, and aggregated up by us.",
		"Universal Service Programs": "Funding data for the four major universal service programs: Lifeline, Highcost, Erate, and Rural Health Care. This data comes from the Universal Service Administrative Company (USAC).",
		"Demographic Data from the American Community Survey": "Non-broadband data from the American Community Survey that maybe be useful to researchers."
	}
	return tooltips[name];
}

function Tooltip(props) {
	return (
		<div className="tooltip"><h3>{props.name}</h3>
			<span className="tooltiptext">{tooltipText(props.name)}</span>
		</div>
	)
}

export class DataDownload extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			locations: [...downloaderLocations, nation],
			area: usState,
			checked: variableCollapse(usState),
			stateOnly: false,
			state: {"name": "Alabama", "id": "01"},
			mostRecentOnly: {"name": "All Available Years", code: false},
			areaYear: false,
			broadbandDefintion: [{download:25, upload: 3}],
			acsSelected: [],
			loggedIn: false,
			changePassword: false
		}
		this.handleAreaChange = this.handleAreaChange.bind(this);
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.getVariables = this.getVariables.bind(this);
		this.handleDownload = this.handleDownload.bind(this);
		this.allUnchecked = this.allUnchecked.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);
		this.handleStateOnlyChange = this.handleStateOnlyChange.bind(this);
		this.handleMostRecentOnlyChange = this.handleMostRecentOnlyChange.bind(this);
		this.handleAreaYearChange = this.handleAreaYearChange.bind(this);
		this.handleBroadbandDownloadChange = this.handleBroadbandDownloadChange.bind(this);
		this.handleBroadbandUploadChange = this.handleBroadbandUploadChange.bind(this);
		this.handleRemoveBroadband = this.handleRemoveBroadband.bind(this);
		this.handleAddBroadband = this.handleAddBroadband.bind(this);
		this.handleSelectionChange = this.handleSelectionChange.bind(this);
		this.getcheckboxloc = this.getcheckboxloc.bind(this);
		this.logout = this.logout.bind(this);
		this.changePassword = this.changePassword.bind(this);

	}

	componentDidMount() {
		fetch(getBaseURL() + "/login-check").
			then(response => {
				if (response.status === 200) {
					this.setState({loggedIn: true}, this.fetchData);
				}					
			}
		);
	}

	handleAreaChange = (event) => {
		const newArea = this.state.locations.find(e => e.name === event.target.value);
		const newChecked = variableCollapse(newArea);
		const oldChecked = this.state.checked;
		Object.keys(this.state.checked).forEach(function(key) { 
			if (newChecked.hasOwnProperty(key)) {
				newChecked[key] = oldChecked[key]
			}
		})
		this.setState({
			area: this.state.locations.find(e => e.name === event.target.value),
			checked: newChecked
		})
	}

	handleCheckboxChange(event) {
		console.log(this.state.checked)
		console.log(event);
		const newChecked = { ...this.state.checked };
		newChecked[event.target.name] = event.target.checked;
		this.setState({
			checked: newChecked
		});
	}

	handleStateOnlyChange(event){
		this.setState({
			stateOnly: event.target.checked,
		})
	}

	handleStateChange(event){
		console.log(event.target.value);
		this.setState({
			state: fips.find(e => e.name === event.target.value)
		});
	}

	handleMostRecentOnlyChange(event){
		this.setState({
			mostRecentOnly: yearOptions.find(e => e.name === event.target.value)
		})
	}

	handleAreaYearChange(event){
		this.setState({
			areaYear: event.target.checked,
		})
	}

	getcheckboxloc(i){
		if (i < 6){
			return {"grid-column": "2/3", "grid-row": i-1 + "/" + i }
		}
		else{
			if (i % 2 != 0){
				return {"grid-column": "1/2", "grid-row": (i+7)/2 + "/" + ((i+7)/2 + 1) }
			}
			else{
				return {"grid-column": "2/3", "grid-row": (i+6)/2 + "/" + ((i+6)/2 + 1) }
			}
		}
	}

	getVariables() {
		const variables = Object.keys(this.state.checked);
		console.log(variables);
		return variables.reduce((acc, key) => (this.state.checked[key] ? acc + (acc.slice(-1) === "," ? "" : ",") + (key !== "percentBroadband" ? key : (this.state.broadbandDefintion.reduce((acc, e) => (acc + "percentBroadbandD" + e.download + "U" + e.upload + ","), ""))) : acc), "")
	}

	handleBroadbandDownloadChange(event) {
		const index = parseInt(event.target.id);
		const newBroadbandDefintion = this.state.broadbandDefintion.map((e,i) => {return {"download": i === index ? event.target.value : e.download, "upload": e.upload}});
		this.setState({ broadbandDefintion: newBroadbandDefintion });
	}

	handleBroadbandUploadChange(event) {
		const index = parseInt(event.target.id);
		const newBroadbandDefintion = this.state.broadbandDefintion.map((e,i) => {return {"download": e.download, "upload": i === index ? event.target.value : e.upload}});
		this.setState({ broadbandDefintion: newBroadbandDefintion });
	}

	handleRemoveBroadband(event) {
		const newBroadbandDefintion = this.state.broadbandDefintion.slice(0, -1);
		this.setState({ broadbandDefintion: newBroadbandDefintion });
	}

	handleAddBroadband(event) {
		const newBroadbandDefintion = [...this.state.broadbandDefintion, {download: 25, upload: 3}];
		this.setState({ broadbandDefintion: newBroadbandDefintion });
	}

	handleDownload(){
		const variables = this.getVariables();
		// console.log(variables);
		if (this.allUnchecked()) {
			alert("Please select at least one variable");
			return;
		}
		const currentTime = new Date(Date.now());
		var a = document.createElement('a');
		a.href = "/csv?area="+this.state.area.schemaName+"&variables="+variables.substring(1) + (this.state.stateOnly ? "&stateOnly=true&state=" + this.state.state.id : "&stateOnly=false") + (this.state.mostRecentOnly.code ? "&mostRecentOnly=true" : "&mostRecentOnly=false") + (this.state.areaYear ? "&areaYear=true" : "&areaYear=false");
		a.download = `${this.state.area.name}_broadband_data_${currentTime.toISOString().split('T')[0]}_${currentTime.toISOString().split('T')[1].split('.')[0].replaceAll(':', "-")}.csv`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	allUnchecked() {
		for (let key of Object.keys(this.state.checked)){
			if (this.state.checked[key] === true) return false;
		}
		return true;
	}

	handleSelectionChange(event) {
		console.log(event);
		const newSelected = this.state.acsSelected.includes(event.target.value) ? this.state.acsSelected.filter(e => e !== event.target.value) : [...this.state.acsSelected, event.target.value];
		this.setState({acsSelected: this.state.acsSelected.includes(event.target.value) ? this.state.acsSelected.filter(e => e !== event.target.value) : [...this.state.acsSelected, event.target.value] });
		const newChecked = { ...this.state.checked };
		newChecked[event.target.value] = !newChecked[event.target.value];
		this.setState({checked: newChecked	});
	}

	changePassword() {
		this.setState({changePassword: true});
	}

	logout() {
		fetch(getBaseURL() + "/logout").
			then(response => {
				if (response.status === 200) {
					this.setState({loggedIn: false, fetchedData: null, stateData: null});
				}					
			}
		);
	}


	render() {
		if (!this.state.loggedIn) {
			return (
				<Login setLogin={(e) => {this.setState({loggedIn: e})}}/>
			)
		} else if (this.state.changePassword) {
			return (
				<ChangePassword setChangePassword={(e) => {this.setState({changePassword: e})}}  />
			)
		} else {
			return (
				<div className={"data-download-state-only"}>
					<TopNav />
					<div className={"area-selector-title-downloader"}><p>Select Map Data:</p></div>
					<div className={"area-selector-signin-buttons"}><button onClick={this.logout}>Log Out</button><button onClick={this.changePassword}>Change Password</button></div>
					<div className={"area-selector-dropdown-geography-downloader"}><DropdownForm id="geographicArea" value={this.state.area.name} 
							onChangeFunction={this.handleAreaChange} data={this.state.locations} /></div>
					<div className={"area-selector-dropdown-state-downloader-title"}><Checkbox  name="Select Individual State (Optional)" code="stateOnly" checked={this.state.stateOnly} onChange={this.handleStateOnlyChange} /></div>
					{this.state.stateOnly && <div className={"area-selector-dropdown-state-downloader"}><DropdownForm id="state" value={this.state.state.name} onChangeFunction={this.handleStateChange} data={fips} /></div>}
					<hr class="top-rule"/>
					<div className={"data-download-state-only-checkboxes"}>{this.state.area.dataSources.map((e, i) => {
						if (e.name === "Availability (FCC Form 477)"){
							return (
								<div className="data-download-checkbox-container" style={{"grid-column": "1/2", "grid-row": "1/3"}}>
									<div className="data-download-checkbox-title"><Tooltip name={e.name} /></div>
									<div>
										<Checkbox name={"Percent with Broadband Access"} code={"percentBroadband"} checked={this.state.checked["percentBroadband"]} onChange={this.handleCheckboxChange} />
										<div>
											{this.state.broadbandDefintion.map((g, i) => {
												return (
													<BroadbandFields downloadSpeed={g.download} uploadSpeed={g.upload} handleBroadbandDownloadChange={this.handleBroadbandDownloadChange} handleBroadbandUploadChange={this.handleBroadbandUploadChange} id={i}/>
												)
											})}
											{this.state.broadbandDefintion.length > 1 && <button onClick={this.handleRemoveBroadband}>Remove Broadband Definition</button>}
											<button onClick={this.handleAddBroadband}>Add Broadband Definition</button>
										</div>
									</div>
									{e.dataTypes.map(f => {
										return (
											f.code !== "percentBroadband" && <div>
												<Checkbox name={f.name} code={f.code} checked={this.state.checked[f.code]} onChange={this.handleCheckboxChange} />
											</div>)})
									}
								</div>
								)
						}
						if (e.name === "Adoption (American Community Survey)"){
							return (
								<div className="data-download-checkbox-container" style={{"grid-column": "1/2", "grid-row": "4/7"}}>
									<div className="data-download-checkbox-title"><Tooltip datasource={e.code} name={e.name} /></div>
									{/* A selection box with every variable in the data source */}
									<select multiple={true} value={this.state.acsSelected} onChange={this.handleSelectionChange} style={{"height": "30vw", "font-size": 12}}>
										{e.dataTypes.map(f => {
											return (
												<option value={f.code}>{f.name}</option>
											)
										})}
									</select>
								</div>
							)
						}
						if (e.name === "Broadband Fabric Locations" || e.name === "FCC Broadband Fabric Units" || e.name === "FCC Broadband Fabric Mobile Coverage"){
							return (
								<div className="data-download-checkbox-container" style={this.getcheckboxloc(i)}>
									<div className="data-download-checkbox-title"><Tooltip datasource={e.code} name={e.name} /></div>
									<select multiple={true} value={this.state.acsSelected} onChange={this.handleSelectionChange} style={{"height": "12vw", "font-size": 12}}>
										{e.dataTypes.map(f => {
											return (
												<option value={f.code}>{f.name}</option>
											)
										}
										)}
									</select>
								</div>
							)
						}
						return (
							<div className="data-download-checkbox-container" style={this.getcheckboxloc(i)}>
								<div className="data-download-checkbox-title"><Tooltip datasource={e.code} name={e.name} /></div>
								{e.dataTypes.map(f => {
									return (
										<div>
											<Checkbox name={f.name} code={f.code} checked={this.state.checked[f.code]} onChange={this.handleCheckboxChange} />
										</div>)})
								}
							</div>
							)
						}
					)}</div>
					<hr class="bottom-rule"/>
					<div className={"data-download-state-only-options"}><h3>Data Options</h3>
					<DropdownForm id="mostRecentOnly" value={this.state.mostRecentOnly.name} onChangeFunction={this.handleMostRecentOnlyChange} data={yearOptions} />
					<p>Advanced <Checkbox name="Row for Each Area and Year (leave unchecked for column for each year and variable)" code="areaYear" checked={this.state.areaYear} onChange={this.handleAreaYearChange} /></p>
					</div>
					<button className={"data-download-state-only-button"} onClick={this.handleDownload}>Download Data</button>
				</div>
			);
		}
	}
}

//NOTE: THIS IS CREATING A HUGE AMOUNT OF TECHNICAL DEBT, BUT WE WANT TO DO THIS QUICKLY
//SO WE'RE STARTING BY JUST COPYING AND PAST STATEFORM AND WE'LL REFACTOR LATER
//INCLUDING THE "THIS IS CREATING DEBT" WARNING BUT WE'RE DOING IT ANYWAY
//MOST OF THAT DEBT HAS ALREADY BEEN PAID DOWN ANYWAYS
import React from "react";
import { getBaseURL } from "./getQuery";
import { DropdownForm } from "./SelectionComponents";
import { tribes } from "./TribalDict";
import Coffee from "./Coffee";

export class TribeSelector extends React.Component {
	constructor(props) {
		super(props);
		this.handleStateChange = this.handleStateChange.bind(this);
	}

	handleStateChange(event) {
		document.location.href = getBaseURL() + "/tribe/" + event.target.value.replaceAll(" ", "_");
	}

	render() {
		return (
			<div className={"grid-static"}>
				<div className = "topnav">
					<a href="#top"><img id = "banner-logo" src="/maplogo.png" alt="Technology Policy Institute Logo"/></a>
				</div>
				<div className={"stateSelector"}>
					<h1 className={"state-selector-program-title"}>Tribal Broadband Data</h1>
					<h1 className={"state-selector-title"}>Select Your Tribe</h1>
					<div className={"state-selector"}><DropdownForm className={"state-slect"} id={"state-change"} value={""} data={[{"name": " ", "code": "00"}, ...tribes]} onChangeFunction={this.handleStateChange} style={{ border: 'none', "text-align-last":"center" }}/></div>
				</div>
				<div className="buyMeACoffee"><Coffee /></div>
			</div>
		);
	}
}


export function getTickFormat(tick){
	if(tick < 10000){
		return tick;
	}
	if (tick < 1000000){
		return (tick/1000).toFixed(0) + "K";
	}
	if (tick < 1000000000){
		return (tick/1000000).toFixed(0) + "M";
	}
	return (tick/1000000000).toFixed(0) + "B";
};

export function monthTick(tick){
	//Return the month number and year for the tick ie 06/22
	const month = tick.getMonth() + 1;
	const year = tick.getFullYear();
	return month + "/" + year;
}
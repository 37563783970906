import {locations } from "./GeographicAreaObjects";
import {stateACS, oneYear2020, oneYear2019} from "./ACSInternetSubscribers";
import {MobileForm477Data, dec2015, jun2016, dec2016, jun2017, dec2017, jun2018, dec2018, jun2019, dec2019} from "./Form477";


export function changeArea(tartgetvalue, oldTime, oldVariable, currentData){
	const newArea = locations.find(e => e.name === tartgetvalue);
	const newData = newArea.dataSources.find(e => e.name === currentData.name);
	console.log(newData);
	return {dataName: newData.name,
		area: newArea,
		data: newData,
		variable: newVariable(oldVariable, newData),
		time: newTime(oldTime, newData)};
};

export function changeData(tartgetvalue, oldTime, oldVariable, oldArea){
	console.log(locations);
	console.log(oldArea.dataSources);
	const newLocations = locations.filter(e => e.dataSources.filter(f => f.name === tartgetvalue).length > 0);
	const contains = newLocations.some(e => e.name === oldArea.name);
	const newArea = contains ? oldArea : newLocations[0];
	console.log(newArea.dataSources);
	const newData = contains ? oldArea.dataSources.find(e => e.name === tartgetvalue) : newArea.dataSources.find(e => e.name === tartgetvalue);
	console.log(newData);
	return {dataName: newData.name,
		area: newArea,
		data: newData,
		variable: newVariable(oldVariable, newData),
		time: newTime(oldTime, newData)};
};

export function changeVariable(tartgetvalue, dataSource, oldtime){
	const newVariable = dataSource.dataTypes.find(e => e.name === tartgetvalue)
	return { variable: newVariable, 
		time: newTime(oldtime, dataSource)};
};

export function newVariable(oldVariable, dataSource){
	return dataSource.dataTypes.some(e => e === oldVariable) ? oldVariable : dataSource.dataTypes[0];
};

export function newTime(oldtime, dataSource){
	if (dataSource.dataYears.some(e => e.code === oldtime)){
		return oldtime;
	}
	//Is current variable the state level ACS for the year 2020? If so, we have to go back a year because there's only one variable for 2020
	const acs2020 = dataSource === stateACS && oldtime === oneYear2020;
	//Is current variable the Mobile Form 477 and the 4G coverage variable? We have to go to dec2019 then because 5G coverage is not available until dec2019
	const current4g = dataSource === MobileForm477Data && [dec2015, jun2016, dec2016, jun2017, dec2017, jun2018, dec2018, jun2019].includes(oldtime);
	const newTime = acs2020 ? oneYear2019 : current4g ? dec2019 : dataSource.dataYears[dataSource.dataYears.length - 1];
	return newTime;
};
import { DataVariable, CodeAndEnglish, DataSource } from "./ModelClasses";

//ACS data
//ACS Variables
export const internetSubscribers = new DataVariable("internetSubscribers", "ACS Internet Subscribers", "%");
export const internetSubscribersNoDialup = new DataVariable("internetSubscribersNoDialup", "ACS Broadband (any Type) Subscribers", "%");
export const internetSubscribersNoDialupNoMobile = new DataVariable("internetSubscribersNoDialupNoMobile", "ACS Broadband (No mobile-only) Subscribers", "%");
export const internetSubscribersNoDialupNoMobileNoSatt = new DataVariable("internetSubscribersNoDialupNoMobileNoSatt", "ACS Broadband (No mobile-only, no satellite) Subscribers", "%");
export const fixedBroadbandSubscribers = new DataVariable("fixedBroadbandSubscribers", "ACS DSL/Cable/Fiber Subscribers", "%");
//Demographic Variables
export const broadbandSubscribersUnder20000 = new DataVariable("broadbandSubscribersUnder20000", "Households under $20000 with Broadband", "%");
export const broadbandSubscribers20000To74999 = new DataVariable("broadbandSubscribers20000To74999", "Households between $20000 and $74999 with Broadband", "%");
export const broadbandSubscribersOver75000 = new DataVariable("broadbandSubscribersOver75000", "Households over $75000 with Broadband", "%");
export const broadbandSubscribersUnder18 = new DataVariable("broadbandSubscribersUnder18", "Population under 18 years old with Broadband", "%");
export const broadbandSubscribers18To64 = new DataVariable("broadbandSubscribers18To64", "Population between 18 and 64 years old with Broadband", "%");
export const broadbandSubscribersOver65 = new DataVariable("broadbandSubscribers65AndOver", "Population over 65 years old with Broadband", "%");
export const broadbandSubscribersWhite = new DataVariable("broadbandSubscribersWhite", "Percent of White population with Broadband", "%");
export const broadbandSubscribersBlack = new DataVariable("broadbandSubscribersBlack", "Percent of Black population with Broadband", "%");
export const broadbandSubscribersAmericanIndian = new DataVariable("broadbandSubscribersAmericanIndian", "Percent of Native American population with Broadband", "%");
export const broadbandSubscribersAsian = new DataVariable("broadbandSubscribersAsian", "Percent of Asian population with Broadband", "%");
export const broadbandSubscribersPacificIslander = new DataVariable("broadbandSubscribersPacificIslander", "Percent of Hawaiian and Pacific Islander population with Broadband", "%");
export const broadbandSubscribersOther = new DataVariable("broadbandSubscribersOtherRace", "Percent of Other race population with Broadband", "%");
export const broadbandSubscribersTwoOrMore = new DataVariable("broadbandSubscribersTwoOrMoreRaces", "Percent of Two or More Races population with Broadband", "%");
export const broadbandSubscribersHispanic = new DataVariable("broadbandSubscribersHispanic", "Percent of Hispanic population with Broadband", "%");
export const broadbandSubscribersNonHispanicWhite = new DataVariable("broadbandSubscribersNonHispanicWhite", "Percent of Non-Hispanic White population with Broadband", "%");
export const broadbandSubscribersLessThanHighSchool = new DataVariable("broadbandSubscribersLessThanHighSchool", "Percent of population with less than high school diploma with Broadband", "%");
export const broadbandSubscribersHighSchoolGraduate = new DataVariable("broadbandSubscribersHighSchoolGradOrSomeCollege", "Percent of population with high school diploma or some college with Broadband", "%");
export const broadbandSubscribersCollegeGradOrHigher = new DataVariable("broadbandSubscribersCollegeGradOrHigher", "Percent of population with college degree or higher with Broadband", "%");
export const broadbandSubscribersEmployed = new DataVariable("broadbandSubscribersEmployed", "Percent of population employed with Broadband", "%");
export const broadbandSubscribersUnemployed = new DataVariable("broadbandSubscribersUnemployed", "Percent of population unemployed with Broadband", "%");
export const broadbandSubscribersNotInLaborForce = new DataVariable("broadbandSubscribersNotInLaborForce", "Percent of population not in the labor force with Broadband", "%");
//Additional Variables
export const medianIncome = new DataVariable("medianIncome", "Median Household Income", "$");
export const population = new DataVariable("population", "Population", "");
export const populationDensity = new DataVariable("populationDensity", "Population Density", "people/sq mile");
export const whitePopulationPercent = new DataVariable("whitePopulationPercent", "White Percent of Population", "%");
export const blackPopulationPercent = new DataVariable("blackPopulationPercent", "Black Percent of Population", "%");
export const americanIndianPopulationPercent = new DataVariable("nativePopulationPercent", "Native American Percent of Population", "%");
export const asianPopulationPercent = new DataVariable("asianPopulationPercent", "Asian Percent of Population", "%");
export const pacificIslanderPopulationPercent = new DataVariable("pacificIslanderPopulationPercent", "Hawaiian and Pacific Islander Percent of Population", "%");
export const otherPopulationPercent = new DataVariable("otherPopulationPercent", "Other Race Percent of Population", "%");
export const twoOrMorePopulationPercent = new DataVariable("twoOrMorePopulationPercent", "Two or More Races Percent of Population", "%")
export const hispanicPopulationPercent = new DataVariable("hispanicPopulationPercent", "Hispanic Percent of Population", "%");
export const nonHispanicWhitePopulationPercent = new DataVariable("nonHispanicPopulationPercent", "Non-Hispanic White Percent of Population", "%");

//Year and Sample types
export const oneYear2015 = new CodeAndEnglish("20151y", "2015, 1 Year Sample");
export const oneYear2016 = new CodeAndEnglish("20161y", "2016, 1 Year Sample");
export const oneYear2017 = new CodeAndEnglish("20171y", "2017, 1 Year Sample");
export const oneYear2018 = new CodeAndEnglish("20181y", "2018, 1 Year Sample");
export const oneYear2019 = new CodeAndEnglish("20191y", "2019, 1 Year Sample");
export const oneYear2020 = new CodeAndEnglish("20201y", "2020, 1 Year Sample");
export const oneYear2021 = new CodeAndEnglish("20211y", "2021, 1 Year Sample");
export const oneYear2022 = new CodeAndEnglish("20221y", "2022, 1 Year Sample");
export const oneYear2023 = new CodeAndEnglish("20231y", "2023, 1 Year Sample");
export const fiveYear2017 = new CodeAndEnglish("20175y", "2017, 5 Year Sample");
export const fiveYear2018 = new CodeAndEnglish("20185y", "2018, 5 Year Sample");
export const fiveYear2019 = new CodeAndEnglish("20195y", "2019, 5 Year Sample");
export const fiveYear2020 = new CodeAndEnglish("20205y", "2020, 5 Year Sample");
export const fiveYear2021 = new CodeAndEnglish("20215y", "2021, 5 Year Sample");
export const fiveYear2022 = new CodeAndEnglish("20225y", "2022, 5 Year Sample");
//ACSDataSource objects
//These vary by year but not by variables, so I made a variables array
//TODO review these eventually to make sure we have everything correct
export const acsVariables = [internetSubscribers, internetSubscribersNoDialup, internetSubscribersNoDialupNoMobile, internetSubscribersNoDialupNoMobileNoSatt, fixedBroadbandSubscribers, broadbandSubscribersUnder20000, broadbandSubscribers20000To74999, broadbandSubscribersOver75000, broadbandSubscribersUnder18, broadbandSubscribers18To64, broadbandSubscribersOver65, broadbandSubscribersWhite, broadbandSubscribersBlack, broadbandSubscribersAmericanIndian, broadbandSubscribersAsian, broadbandSubscribersPacificIslander, broadbandSubscribersOther, broadbandSubscribersTwoOrMore, broadbandSubscribersHispanic, broadbandSubscribersNonHispanicWhite, broadbandSubscribersLessThanHighSchool, broadbandSubscribersHighSchoolGraduate, broadbandSubscribersCollegeGradOrHigher, broadbandSubscribersEmployed, broadbandSubscribersUnemployed, broadbandSubscribersNotInLaborForce];
//Zip Codes and Census Tracts
export const fiveYearACS = new DataSource("Adoption (American Community Survey)", acsVariables, [fiveYear2017, fiveYear2018, fiveYear2019, fiveYear2020, fiveYear2021, fiveYear2022], "%Y5y");
//For the ones that changed in 2020
export const fiveYearACSUpto2020 = new DataSource("Adoption (American Community Survey)", acsVariables, [fiveYear2017, fiveYear2018, fiveYear2019, fiveYear2020], "%Y5y");
export const allACSUpto2020 = new DataSource("Adoption (American Community Survey)", acsVariables, [oneYear2015, oneYear2016, oneYear2017, oneYear2018, oneYear2019, fiveYear2017, fiveYear2018, fiveYear2019, fiveYear2020], "%Y1y");
export const acs2021 = new DataSource("Adoption (American Community Survey)", acsVariables, [fiveYear2021, fiveYear2022], "%Y5y");
export const acs2022 = new DataSource("Adoption (American Community Survey)", acsVariables, [fiveYear2022], "%Y5y");
export const allACS2022 = new DataSource("Adoption (American Community Survey)", acsVariables, [oneYear2022, oneYear2023, fiveYear2022], "%Y1y");
//Counties, and States and CSAs
export const allACS = new DataSource("Adoption (American Community Survey)", acsVariables, [oneYear2015, oneYear2016, oneYear2017, oneYear2018, oneYear2019, oneYear2021, oneYear2022, oneYear2023, fiveYear2017, fiveYear2018, fiveYear2019, fiveYear2020, fiveYear2021, fiveYear2022], "%Y1y");
//States have 2020 data
export const stateACS = new DataSource("Adoption (American Community Survey)", acsVariables, [oneYear2015, oneYear2016, oneYear2017, oneYear2018, oneYear2019, oneYear2020, oneYear2021,oneYear2022, oneYear2023, fiveYear2017, fiveYear2018, fiveYear2019, fiveYear2020, fiveYear2021, fiveYear2022], "%Y1y");
export const simpleACS = new DataSource("Adoption", [fixedBroadbandSubscribers, broadbandSubscribersUnder20000], [fiveYear2017, fiveYear2018, fiveYear2019, fiveYear2020, fiveYear2021, fiveYear2022], "%Y5y");
export const simpleACSDivide = new DataSource("Adoption", [fixedBroadbandSubscribers, broadbandSubscribersUnder20000, broadbandSubscribersWhite, broadbandSubscribersBlack], [fiveYear2017, fiveYear2018, fiveYear2019, fiveYear2020, fiveYear2021, fiveYear2022], "%Y5y", true);

export const acsAdditionalVariables = [medianIncome, population, populationDensity, whitePopulationPercent, blackPopulationPercent, americanIndianPopulationPercent, asianPopulationPercent, pacificIslanderPopulationPercent, otherPopulationPercent, twoOrMorePopulationPercent, hispanicPopulationPercent, nonHispanicWhitePopulationPercent];
export const fiveYearACSAdditional = new DataSource("Demographic Data from the American Community Survey", acsAdditionalVariables, [fiveYear2017, fiveYear2018, fiveYear2019, fiveYear2020, fiveYear2021, fiveYear2022], "%Y5y");
export const fiveYearACSUpto2020Additional = new DataSource("Demographic Data from the American Community Survey", acsAdditionalVariables, [fiveYear2017, fiveYear2018, fiveYear2019, fiveYear2020], "%Y5y");
export const allACSUpto2020Additional = new DataSource("Demographic Data from the American Community Survey", acsAdditionalVariables, [oneYear2015, oneYear2016, oneYear2017, oneYear2018, oneYear2019, fiveYear2017, fiveYear2018, fiveYear2019, fiveYear2020], "%Y1y");
export const acs2021Additional = new DataSource("Demographic Data from the American Community Survey", acsAdditionalVariables, [fiveYear2021, fiveYear2022], "%Y5y");
export const acs2022Additional = new DataSource("Demographic Data from the American Community Survey", acsAdditionalVariables, [fiveYear2022], "%Y5y");
export const allACS2022Additional = new DataSource("Demographic Data from the American Community Survey", acsAdditionalVariables, [oneYear2022, oneYear2023, fiveYear2022], "%Y1y");
export const allACSAdditional = new DataSource("Demographic Data from the American Community Survey", acsAdditionalVariables, [oneYear2015, oneYear2016, oneYear2017, oneYear2018, oneYear2019, oneYear2021, oneYear2022, oneYear2023, fiveYear2017, fiveYear2018, fiveYear2019, fiveYear2020, fiveYear2021, fiveYear2022], "%Y1y");

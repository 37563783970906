import React from "react";
import { congressionalDistrict, county, usState } from "./GeographicAreaObjects";
import { getBaseURL } from "./getQuery";
import { DropdownForm } from "./SelectionComponents";
import { fips, countyFips, congressionalDistrictFips } from "./StateFIPS";
import { simpleFabric } from "./FCCFabricData";
import { OoklaData } from "./Ookla";
import { fiveYear2017, fiveYear2018, fiveYear2019, fiveYear2020, fiveYear2021, oneYear2015, oneYear2016, oneYear2017, oneYear2018, oneYear2019, oneYear2020, oneYear2021, simpleACS, simpleACSDivide, internetSubscribersNoDialup, broadbandSubscribersUnder20000, broadbandSubscribers20000To74999, broadbandSubscribersWhite, broadbandSubscribersBlack } from "./ACSInternetSubscribers";
import { simpleStateACP, simpleCountyACP } from "./AffordableConnectivityProgram";
import { usacData, usacYears } from "./USACData";
import { VictoryLine, VictoryChart, VictoryAxis, VictoryTooltip, VictoryVoronoiContainer, VictoryLegend, VictoryScatter } from "victory";
import { timeParse } from "d3-time-format";
import { getTickFormat } from "./GraphControls";

export class Login extends React.Component {
	constructor(props) { 
		super(props);
		this.state = {
			signUp: false,
			forgotPassword: false,
			email: "",
			password: "",
			confirmPassword: "",
			username: ""
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
		this.handleSignUp = this.handleSignUp.bind(this);
		this.handleForgotPassword = this.handleForgotPassword.bind(this);
	}

	handleChange(event) {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	}

	handleLogin(event) {
		event.preventDefault();
		const email = this.state.email;
		const password = this.state.password;
		const url = getBaseURL() + "/login";
		const data = { email: email, password: password };
		fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(data)
		})
			.then(response => {
				if (response.status === 200) {
					this.props.setLogin(true);
				}
				else{
					return response.text();
				}})
			.then(data => {
				if (data) {
					alert(data);
				}
			})
			.catch(error => {
				console.error("Error:", error);
			}
		);
	}

	handleSignUp(event) {
		event.preventDefault();
		const username = this.state.username;
		const email = this.state.email;
		const password = this.state.password;
		const confirmPassword = this.state.confirmPassword;
		if (password != confirmPassword) {
			alert("Passwords do not match");
			return;
		}
		const url = getBaseURL() + "/signup";
		const data = { email: email, password: password, username: username };
		fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(data)
		})
			.then(response => {
				console.log(response);
				if(response.status == 201) {
					alert("Account created successfully");
					this.setState({ signUp: false });
				}

				else {
					return response.text();
				}})
			.then(data => {
				if (data) {
					alert(data);
					this.setState({ signUp: false });
				}
			})
			.catch(error => {
				console.error("Error:", error);
			}
		);
	}

	handleForgotPassword(event) {
		event.preventDefault();
		const email = this.state.email;
		const url = getBaseURL() + "/forgot-password";
		const data = { email: email };
		fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(data)
		})
			.then(response => response.text())
			.then(data => {
				alert(data);
			})
			.catch(error => {
				console.error("Error:", error);
			}
		);
	}

	render(){
		if (this.state.signUp) {
			return (
				<div class={"simple-sign-in"}>
					<h1>Sign Up</h1>
					<button onClick={() => this.setState({ signUp: false })}>Back to Log In</button>
					<form onSubmit={this.handleSignUp}>
						<p>Email <input
							type="email"
							name="email"
							placeholder="Email"
							value={this.state.email}
							onChange={this.handleChange}
							required
						/></p>
						<p>Name <input
							type="text"
							name="username"
							placeholder="Name"
							value={this.state.username}
							onChange={this.handleChange}
							required
						/></p>
						<p>Password <input
							type="password"
							name="password"
							placeholder="password"
							value={this.state.password}
							onChange={this.handleChange}
							required
						/></p>
						<p>Confirm Password<input
							type="password"
							name="confirmPassword"
							placeholder="password"
							value={this.state.confirmPassword}
							onChange={this.handleChange}
							required
						/></p>
						<button type="submit">Sign Up</button>
					</form>
				</div>
			);
		} else if (this.state.forgotPassword) {
			return (
				<div class={"simple-sign-in"}>
					<h1>Forgot Password</h1>
					<button onClick={() => this.setState({ forgotPassword: false })}>Back to Log In</button>
					<form onSubmit={this.handleForgotPassword}>
						<p>Email <input
							type="email"
							name="email"
							placeholder="Email"
							value={this.state.email}
							onChange={this.handleChange}
							required
						/></p>
						<button type="submit">Send Password Reset Email</button>
					</form>
				</div>
			);
		} else {
			return (
				<div class={"simple-sign-in"}>
					<h1>Login</h1>
					<button onClick={() => this.setState({ signUp: true })}>Sign Up</button>
					<button onClick={() => this.setState({ forgotPassword: true })}>Forgot Password</button>
					<form onSubmit={this.handleLogin}>
						<p>Email <input
							type="email"
							name="email"
							placeholder="Email"
							value={this.state.email}
							onChange={this.handleChange}
							required
						/></p>
						<p>Password <input
							type="password"
							name="password"
							placeholder="password"
							value={this.state.password}
							onChange={this.handleChange}
							required
						/></p>
						<button type="submit">Login</button>
					</form>
				</div>
			);
		}
	}
}

export class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			password: "",
			confirmPassword: "",
			token: ""
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleResetPassword = this.handleResetPassword.bind(this);
	}

	handleChange(event) {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	}

	handleResetPassword(event) {
		event.preventDefault();
		console.log(this.state.token);
		console.log(window.location.href)
		if (this.state.password != this.state.confirmPassword) {
			alert("Passwords do not match");
			return;
		}
		const url = getBaseURL() + "/reset-password";
		const data = {
			password: this.state.password,
			token: this.state.token
		};
		fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(data)
		})
			.then(response => {
				return response.text(), response.success;
			})
			.then((responseText, status) => {
				alert(responseText);
				if (status) {
					window.location.href = "/simple";
				}
			})
			.catch(error => {
				console.error("Error:", error);
			}
		);
	}

	componentDidMount() {
		const url = window.location.href.split("/")
		const token = url[url.length - 1].split("=")[1];
		this.setState({ token: token });
	}


	render() {
		return (
		<div  class={"simple-sign-in"}>
		<h1>Reset Password</h1>
		<form onSubmit={this.handleResetPassword}>
			<p>Password <input
				type="password"
				name="password"
				placeholder="password"
				value={this.state.password}
				onChange={this.handleChange}
				required
			/></p>
			<p>Confirm Password <input
				type="password"
				name="confirmPassword"
				placeholder="password"
				value={this.state.confirmPassword}
				onChange={this.handleChange}
				required
			/></p>
			<button type="submit">Reset Password</button>
		</form>
		</div>
		);
	}
}

export class ChangePassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			oldPassword: "",
			newPassword: "",
			confirmPassword: ""
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleChangePassword = this.handleChangePassword.bind(this);
	}

	handleChange(event) {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	}

	handleChangePassword(event) {
		event.preventDefault();
		console.log(this.state.newPassword);
		console.log(this.state.confirmPassword)
		if (this.state.newPassword != this.state.confirmPassword) {
			alert("Passwords do not match");
			return;
		}
		const url = getBaseURL() + "/change-password";
		const data = {
			oldPassword: this.state.oldPassword,
			newPassword: this.state.newPassword
		};
		fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data)
		})
			.then(response => {
				return response.text(), response.success;
			})
			.then((responseText, status) => {
				alert(responseText);
				if (status) {
					this.props.setChangePassword(false);
				}
			})
			.catch(error => {
				console.error("Error:", error);
			}
		);
	}
		



	render() {
		return (
		<div  class={"simple-sign-in"}>
		<h1>ChangePassword </h1>
		<button onClick={() => this.props.setChangePassword(false)}>Return</button>
		<form onSubmit={this.handleChangePassword}>
			<p>Current Password <input
				type="password"
				name="oldPassword"
				placeholder="current password"
				value={this.state.oldPassword}
				onChange={this.handleChange}
				required
			/></p>
			<p>New Password <input
				type="password"
				name="newPassword"
				placeholder="new password"
				value={this.state.newPassword}
				onChange={this.handleChange}
				required
			/></p>
			<p>Confirm Password <input
				type="password"
				name="confirmPassword"
				placeholder="confirm new password"
				value={this.state.confirmPassword}
				onChange={this.handleChange}
				required
			/></p>
			<button type="submit">Change Password</button>
		</form>
		</div>
		);
	}
}
			

const colors = ["black", "red", "green", "blue", "yellow", "orange", "navy", "purple", "pink", "brown", "grey"];

const Timeseries = React.forwardRef(function Timeseries(props, ref) {
	// console.log(props.data);
	return (<div ref={ref}>
		<VictoryChart 
			//I'm pretty sure this needs to be dynamic
			height={props.height} width={props.height}
			domainPadding={{ x: 20 }}
			minDomain={{ y: 0 }}
			scale={{ x: "time" }}
			containerComponent={<VictoryVoronoiContainer/>}>
			<VictoryAxis label={props.xLabel} style={{tickLabels: { fill: (!props.data.length ? "transparent" : "black")}}}/>
			<VictoryAxis label={props.yLabel} tickFormat={(t) => getTickFormat(t)} dependentAxis style={{ tickLabels: { fill: (!props.data.length ? "transparent" : "black"), angle: 270 } }} />
			{props.data.map((dataElement, index) => <VictoryLine key={dataElement.id} data={dataElement.data}
				labels={({ datum }) => (datum.label)}
				labelComponent={<VictoryTooltip/>}
				style={{data: {stroke: colors[index % 11]}}} />)}
			{props.data.map((dataElement, index) => <VictoryScatter key={dataElement.id} data={dataElement.data}
				labels={({ datum }) => (datum.label)}
				labelComponent={<VictoryTooltip/>}
				style={{data: {fill: colors[index % 11]}}} />)}
		</VictoryChart>
		<VictoryLegend
			orientation="vertical"
			gutter={20}
			data={props.data.map((dataElement, index) => ({"name": dataElement.name, "symbol": {"fill": colors[index % 11], "type": "square" } }))} />
	</div>);
});

function getDataName(variable) {
	const variableDict = {"served253Jun2022": "Share of Locations with at Least 25/3 (%)", "served10020Jun2022": "Share of Locations with at Least 100/20 (%)", "ooklaDownload2022q4": "Mean Download Speed (Mbps)", "ooklaUpload2022q4": "Mean Upload Speed (Mbps)", "internetSubscribersNoDialup20215y": "Share of households with any broadband (%)", "broadbandSubscribersUnder2000020215y": "Share of households with <$20,000 with any broadband (%)", "internetSubscribersNoDialup20211y": "Share of households with any broadband (%)", "broadbandSubscribersUnder2000020211y": "Share of households with <$20,000 with any broadband (%)"}
	return variableDict[variable];
}

function getDataDate(variable) {
	const variableDict = {"served253Jun2022": "June 2022", "served10020Jun2022": "June 2022", "ooklaDownload2022q4": "Q4 2022", "ooklaUpload2022q4": "Q4 2022", "internetSubscribersNoDialup20215y": "2021", "broadbandSubscribersUnder2000020215y": "2021", "internetSubscribersNoDialup20211y": "2021", "broadbandSubscribersUnder2000020211y": "2021"}
	return variableDict[variable];
}

function getDataSource(variable) {
	const variableDict = {"served253Jun2022": "FCC Fabric", "served10020Jun2022": "FCC Fabric", "ooklaDownload2022q4": "Ookla", "ooklaUpload2022q4": "Ookla", "internetSubscribersNoDialup20215y": "America Community Survey", "broadbandSubscribersUnder2000020215y": "America Community Survey", "internetSubscribersNoDialup20211y": "America Community Survey", "broadbandSubscribersUnder2000020211y": "America Community Survey"}
	return variableDict[variable];
}

function DataRow(props) {
  const name = getDataName(props.variable);
  const date = getDataDate(props.variable);
  const source = getDataSource(props.variable);
  return (
	<tr>
	  <td className="simple-table-td">{name}</td>
	  <td className="simple-table-td">{props.value.toFixed(1)}</td>
	  {props.stateValue && <td className="simple-table-td">{props.stateValue.toFixed(1)}</td>}
	  <td className="simple-table-td">{date}</td>
	  <td className="simple-table-td">{source}</td>
	</tr>
  );
}

function DataTable(props) {
	const rows = props.data.map(d => ({"variable": d[0], "value": d[1]}));
	if (props.stateData) {
		//Match state data to row data on variable
		rows.forEach(r => {
			const stateRow = props.stateData.find(s => s[0] === r.variable);
			r.stateValue = stateRow[1];
		});
	}
	return (
		<table>
			<thead>
				<tr>
					<th className="simple-table-td">{props.name}</th>
					<th className="simple-table-td">{props.area} Value</th>
					{props.stateData && <th className="simple-table-td">State Value</th>}
					<th className="simple-table-td">Data Date</th>
					<th className="simple-table-td">Data Source</th>
				</tr>
			</thead>
			<tbody>
				{rows.map((d) => <DataRow key={d.variable} variable={d.variable} value={d.value} stateValue={d.stateValue} />)}
			</tbody>
		</table>
	);

}

function parseGraphData(data, variable, parser, area, label) {
	console.log(variable);
	console.log(data);
	if (variable === "percentBroadband"){
		return Object.keys(data[0]).map((key) => ({ "x": timeParse("%b%Y")(key), "y": data[0][key], "label": label}));
	}
	const retrivedData = Object.keys(data[area.plural][0]).map((key) => ({ "x": timeParse(variable + parser)(key), "y": data[area.plural][0][key], "label":  label}));
	return retrivedData;
}

async function getPercentBroadbandData(areaType, area, download, upload) {
	const url = getBaseURL() + "/broadbandPercent?model=" + areaType.schemaName + "&loc=" + area.id + "&download=" + download + "&upload=" + upload;
	const response = await fetch(url);
	const results = await response.json();
	const organized = {"id": download + "/" + upload + " Form 477", "name": "Form 477 % with " + download + "/" + upload, "data": parseGraphData(results.data, "percentBroadband", "%b%Y", areaType, "Form 477 % with " + download + "/" + upload)};
	return organized;
}

async function getFabricData(areaType, area, download, upload) {
	console.log(areaType);
	const url = getBaseURL() + "/simplefetch?query={" + areaType.plural + "(" + areaType.id + ":\"" + area.id + "\") { served" + download + upload + "Jun2022 }}";
	const response = await fetch(url);
	const results = await response.json();
	const organized = {"id": download + "/" + upload + " Fabric", "name": "Fabric % with " + download + "/" + upload, "data": parseGraphData(results.data, "served" + download + upload, "%b%Y", areaType, "Fabric % with " + download + "/" + upload)};
	return organized;
}

async function getAvailabilityData(areaType, area) {
	const organized253 = await getPercentBroadbandData(areaType, area, "25", "3");
	const organized10020 = await getPercentBroadbandData(areaType, area, "100", "20");
	const organized1000100 = await getPercentBroadbandData(areaType, area, "1000", "100");
	const organizedFabric253 = await getFabricData(areaType, area, "25", "3");
	const organizedFabric10020 = await getFabricData(areaType, area, "100", "20");
	const organizedFabric1000100 = await getFabricData(areaType, area, "1000", "100");
	return [organized253, organized10020, organized1000100, organizedFabric253, organizedFabric10020, organizedFabric1000100];
}

async function getSpeed(areaType, area, type) {
	const fields = OoklaData.dataYears.map((year) => "ookla" + type + year.code).reduce((a, b) => a + " " + b, "");
	const url = getBaseURL() + "/simplefetch?query={" + areaType.plural + "(" + areaType.id + ":\"" + area.id + "\") { " + fields + " }}";
	const response = await fetch(url);
	const results = await response.json();
	const organized = {"id": type, "name": type, "data": parseGraphData(results.data, "ookla" + type, OoklaData.parser, areaType, type)};
	return organized;
}

async function getSpeedData(areaType, area){
	const downloadSpeed = await getSpeed(areaType, area, "Download");
	const uploadSpeed = await getSpeed(areaType, area, "Upload");
	const mobileDownloadSpeed = await getSpeed(areaType, area, "MobileDownload");
	const mobileUploadSpeed = await getSpeed(areaType, area, "MobileUpload");
	return [downloadSpeed, uploadSpeed, mobileDownloadSpeed, mobileUploadSpeed];
}

async function getAdoption(areaType, area, type) {
	const years = areaType === usState ? [oneYear2015, oneYear2016, oneYear2017, oneYear2018, oneYear2019, oneYear2021] : [fiveYear2017, fiveYear2018, fiveYear2019, fiveYear2020, fiveYear2021];
	const parser = areaType === usState ? "%Y1y" : "%Y5y";
	const fields = years.map((year) => type.code + year.code).reduce((a, b) => a + " " + b, "");
	const url = getBaseURL() + "/simplefetch?query={" + areaType.plural + "(" + areaType.id + ":\"" + area.id + "\") { " + fields + " }}";
	const response = await fetch(url);
	const results = await response.json();
	const organized = {"id": type.name, "name": type.name, "data": parseGraphData(results.data, type.code, parser, areaType, type.name)};
	return organized;
}

async function getAdoptionData(areaType, area) {
	const types = [internetSubscribersNoDialup, broadbandSubscribersUnder20000, broadbandSubscribers20000To74999, broadbandSubscribersWhite, broadbandSubscribersBlack];
	const data = await Promise.all(types.map((type) => getAdoption(areaType, area, type)));
	return data;
}

async function getDigitalDivideData(areaType, area, dataType) {
	const fields = usacYears.map((year) => dataType.code + year.code).reduce((a, b) => a + " " + b, "");
	const url = getBaseURL() + "/simplefetch?query={" + areaType.plural + "(" + areaType.id + ":\"" + area.id + "\") { " + fields + " }}";
	const response = await fetch(url);
	const results = await response.json();
	const organized = {"id": dataType.name, "name": dataType.name, "data": parseGraphData(results.data, dataType.code, "%Y", areaType, dataType.name)};
	return [organized];
}


class MoreDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			area: props.area,
			location: props.location,
			dataSources: [simpleFabric, simpleACS, {name: "Subsidies"}, OoklaData],
			digital_divide: [{"name": "E-Rate", "code": "erate"}, {"name": "High Cost", "code": "highCost"}, {"name": "lifeline", "code": "lifeline"}, {"name": "Rural Health", "code": "ruralHealth"}],
			dataType: simpleFabric,
			digital_divide_type: {"name": "E-Rate", "code": "erate"},
			graphData: null,
			display: props.display
		};
		this.containerRef = React.createRef();
		this.updateState = this.updateState.bind(this);
		this.updateDataType = this.updateDataType.bind(this);
		this.updateDigitalDivideType = this.updateDigitalDivideType.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.currentDataType = this.currentDataType.bind(this);
	}

	updateState(variable, object){
		this.setState({[variable] : object}, this.fetchData);
	}

	updateDataType(event) {
		console.log(this.state.dataSources);
		console.log(this.state.dataSources.find(e => e.name == event.target.value));
		this.setState({"dataType": this.state.dataSources.find(e => e.name == event.target.value)}, this.fetchData);
	}

	updateDigitalDivideType(event) {
		this.setState({"digital_divide_type": this.state.digital_divide.find(e => e.name == event.target.value)}, this.fetchData);
	}

	fetchData() {
		if (this.state.dataType.name === "Availability") {
			getAvailabilityData(this.state.area, this.state.location).then(data => this.setState({graphData: data}));
		}
		else if (this.state.dataType.name === "Speed Test (Ookla)") {
			getSpeedData(this.state.area, this.state.location).then(data => this.setState({graphData: data}));
		}
		else if (this.state.dataType.name === "Adoption") {
			getAdoptionData(this.state.area, this.state.location).then(data => this.setState({graphData: data}));
		}
		else {
			getDigitalDivideData(this.state.area, this.state.location, this.state.digital_divide_type).then(data => this.setState({graphData: data}));
		}
	}

	currentDataType() {
		if (this.state.dataType.name === "Subsidies") {
			return this.state.digital_divide_type;
		} else {
			return this.state.dataType;
		}
	}

	getYLabel() {
		if (this.state.dataType.name === "Availability") {
			return "Percent of Population with Broadband";
		} else  if (this.state.dataType.name == "Adoption") {
			return "Percent of Population with Broadband Subscription";
		} else if (this.state.dataType.name == "Subsidies") {
			return "Dollars of Broadband Subsidy";
		} else {
			return "Ookla Average Speed";
		}
	}

	componentDidMount() {
		this.fetchData();
	}

	render() {
		if (this.state.display) {
			return (
				<div className="details-table">
					<h3>More Details</h3>
					<DropdownForm className={"simple-more-details-select"} id={"datatype"} name={"datatype"} value={this.state.dataType.name} data={this.state.dataSources} onChangeFunction={this.updateDataType} style={{ border: 'none', "text-align-last":"center" }} />
					{this.state.dataType.name === "Subsidies" && <DropdownForm className={"simple-more-details-select"} id={"digital_divide_type"} name={"digital_divide_type"} value={this.state.digital_divide_type.name} data={this.state.digital_divide} onChangeFunction={this.updateDigitalDivideType} style={{ border: 'none', "text-align-last":"center" }} />}
					{this.state.graphData && <div className={"simple-graph"}><div className={"simple-timeseries-chart"}><Timeseries gref={this.containerRef} height={window.innerWidth*0.28} data={this.state.graphData} xLabel="Time" yLabel={this.getYLabel()} /></div></div>}
				</div>
			);
		}
	}
}











export class SimpleForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//These are the 2010 census values, not the 2020 census values. 
			areas: [usState, county, congressionalDistrict],
			area: usState,
			stateId: fips[0],
			states: fips,
			counties: countyFips,
			congressionalDistricts: congressionalDistrictFips,
			county: countyFips.filter(e => e.state_id == "01")[0],
			congressionalDistrict: congressionalDistrictFips.filter(e => e.state_id == "01")[0],
			fetchedData: null,
			stateData: null,
			moreData: false,
			loggedIn: false,
			changePassword: false,
		};
		this.detailElement = React.createRef();
		this.handleAreaChange = this.handleAreaChange.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);
		this.handleCountyChange = this.handleCountyChange.bind(this);
		this.handleCongressionalDistrictChange = this.handleCongressionalDistrictChange.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.getAreaName = this.getAreaName.bind(this);
		this.flipMoreData = this.flipMoreData.bind(this);
		this.currentLocation = this.currentLocation.bind(this);
		this.updateLocation = this.updateLocation.bind(this);
		this.getFields = this.getFields.bind(this);
		this.logout = this.logout.bind(this);
		this.changePassword = this.changePassword.bind(this);
	}

	handleAreaChange(event) {
		const newArea = this.state.areas.find(area => area.name === event.target.value)
		this.setState({area: newArea, stateData: null, fetchedData:null}, this.updateLocation);

	}

	handleStateChange(event) {
		console.log(event.target.value);
		const newState = this.state.states.find(state => state.name === event.target.value);
		console.log(newState);
		this.setState({stateId: newState, county: countyFips.filter(e => e.state_id == newState.id)[0],
		congressionalDistrict: congressionalDistrictFips.filter(e => e.state_id == newState.id)[0], fetchedData:null}, this.updateLocation)
	}

	handleCountyChange(event) {
		const newCounty = this.state.counties.find(county => county.name === event.target.value)
		this.setState({county: newCounty, fetchedData:null}, this.updateLocation);
	}

	updateLocation(){
		this.detailElement.current.updateState("area", this.state.area);
		this.detailElement.current.updateState("location", this.currentLocation());
		this.fetchData();
	}

	handleCongressionalDistrictChange(event) {
		this.setState({congressionalDistrict: this.state.congressionalDistricts.find(congressionalDistrict => congressionalDistrict.name === event.target.value), fetchedData:null}, this.updateLocation);
	}

	getFields() {
		if (this.state.area.name === "State") {
			return "served253Jun2022 served10020Jun2022 ooklaDownload2022q4 ooklaUpload2022q4 internetSubscribersNoDialup20211y broadbandSubscribersUnder2000020211y"
		} else {
			return "served253Jun2022 served10020Jun2022 ooklaDownload2022q4 ooklaUpload2022q4 internetSubscribersNoDialup20215y broadbandSubscribersUnder2000020215y"
		}
	}

	fetchData() {
		const id = this.state.area.name === "State" ? this.state.stateId.id : this.state.area.name === "County" ? this.state.county.id : this.state.congressionalDistrict.id;
		console.log(id);
		console.log(this.state.stateId.id);
		const fields = this.getFields()
		const url = `${getBaseURL()}/simplefetch?query={${this.state.area.plural} (${this.state.area.id}:"${id}") {${fields}}}`;
		fetch(url)
			.then(response => response.json())
			.then(data => {
				const fetched = data.data[this.state.area.plural][0];
				const fetchedArray = Object.keys(fetched).map(key => [key, fetched[key]]);
				return fetchedArray;
			})
			.then(data => this.setState({fetchedData: data}), console.log(this.state.fetchedData))
			.catch(function (error) {
				console.log(error);
			});
		if (this.state.area.name !== "State") {
			const url = `${getBaseURL()}/simplefetch?query={states (stateId:"${this.state.stateId.id}") {${fields}}}`;
			fetch(url)
				.then(response => response.json())
				.then(data => {
					const fetched = data.data["states"][0];
					const fetchedArray = Object.keys(fetched).map(key => [key, fetched[key]]);
					return fetchedArray;
				})
				.then(data => this.setState({stateData: data}), console.log(this.state.stateData))
				.catch(function (error) {
					console.log(error);
				});
		}
	}

	componentDidMount() {
		fetch(getBaseURL() + "/login-check").
			then(response => {
				if (response.status === 200) {
					this.setState({loggedIn: true}, this.fetchData);
				}					
			}
		);
	}

	getAreaName() {
		return this.currentLocation().name;
	}

	flipMoreData() {
		const next = !this.state.moreData;
		this.setState({moreData: next});
		this.detailElement.current.updateState("display", next);
	}

	currentLocation() {
		return this.state.area.name === "State" ? this.state.stateId : this.state.area.name === "County" ? this.state.county : this.state.congressionalDistrict;
	}

	changePassword() {
		this.setState({changePassword: true});
	}

	logout() {
		fetch(getBaseURL() + "/logout").
			then(response => {
				if (response.status === 200) {
					this.setState({loggedIn: false, fetchedData: null, stateData: null});
				}					
			}
		);
	}





  render() {
	//Get current url after the base url
	const url = window.location.href;
	const urlArray = url.split("/");
	const currentUrl = urlArray[urlArray.length - 1].split("?")[0];
	if (currentUrl === "reset-password-modal"){
		return (
			<ResetPassword />
		)
	}
	 else if (!this.state.loggedIn) {
		return (
			<Login setLogin={(e) => {this.setState({loggedIn: e}, this.fetchData())}}/>
		)
	} else if (this.state.changePassword) {
		return (
			<ChangePassword setChangePassword={(e) => {this.setState({changePassword: e})}}  />
		)
	} else {
		return (
		<div>
			<div className={"simple-top-grid"}>
				<h1 className={"simple-header"}>Broadband Data Scorecard</h1>
				<button className={"simple-logout"} onClick={this.logout}>Log Out</button>
				<button className={"simple-change-password"} onClick={this.changePassword}>Change Password</button>
				<p className={"simple-description"}>Choose what you want to know and show about broadband by choosing a few parameters. <br />
				Each has lots of detailed options, but you can get a general picture or drill down as deep as you want.</p>
				<p className={"simple-state-label"}>Choose a State</p>
				<DropdownForm className={"simple-state-select"} id={"state-change"} value={this.state.stateId.name} data={fips} onChangeFunction={this.handleStateChange} style={{ border: 'none', "text-align-last":"center" }}/>
				<p className={"simple-area-label"}>Choose type of area you want to see</p>
				<DropdownForm className={"simple-area-select"} id={"area"} name={"area"} value={this.state.area.name} data={this.state.areas} onChangeFunction={this.handleAreaChange} style={{ border: 'none', "text-align-last":"center" }} />
				{this.state.area !== usState && <p className={"simple-subarea-label"}>Choose a {this.state.area === county ? "County" : "Congressional District"}</p>}
				{this.state.area === county && <DropdownForm className={"simple-subarea-select"} id={"county-change"} value={this.state.county.name} data={this.state.counties.filter(e => e.state_id == this.state.stateId.id)} onChangeFunction={this.handleCountyChange} style={{ border: 'none', "text-align-last":"center" }}/>}
				{this.state.area === congressionalDistrict && <DropdownForm className={"simple-subarea-select"} id={"congressional-district-change"} value={this.state.congressionalDistrict.name} data={this.state.congressionalDistricts.filter(e => e.state_id == this.state.stateId.id)} onChangeFunction={this.handleCongressionalDistrictChange} style={{ border: 'none', "text-align-last":"center" }}/>}
			</div>
			{this.state.fetchedData && <DataTable name={this.getAreaName()} area={this.state.area.name} data={this.state.fetchedData} stateData={this.state.stateData} />}
			<p><a href="/sources">See Dataset Descriptions</a></p>
			<button onClick={this.flipMoreData}>{this.state.moreData ? "Hide Details" : "More Detail"}</button>
			<MoreDetails display={this.state.moreData} area={this.state.area} location={this.currentLocation()} ref={this.detailElement} />
		</div>

		);
	}
  }
}
//URL for queries
export function getBaseURL() {
	return window.location.protocol + "//" + window.location.hostname + (window.location.port? ":"+window.location.port : "");
	// return "http://127.0.0.1:5000/";
}
//query retreiver
export function getQuery(query) {
	const queryURL = getBaseURL() + "/graphql?query=" + query;
	console.log(queryURL);
	return fetch(queryURL, { method: "get" })

		.then((response) => response.json())

		.then(function (results) {
			return results;
		})

		.catch(function (error) {
			console.log(error);
		});
}

/*async fetchData() {
	while(this.state.fetching === true){
		await sleep(1);
	}
	this.setState({fetching: true});
	this.setState({loading: true});
	const dataArea = zoomArea(this.state.zoom, this.state.area);
	const oldAreas = this.state.fetchedData === null ? [] : this.state.fetchedData.map(d => d[this.state.area.id]);
	// Remove oldAreas from current areas
	const newAreas = this.state.areas.filter(a => !oldAreas.includes(a));
	if(this.state.variable === percentBroadband){
		const response = await trackPromise(fetch("/broadband_percent", {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			method: "POST",
			body: JSON.stringify({area: this.state.area.name, state: this.state.stateId.code, areas: newAreas, download: this.state.download, upload: this.state.upload, time: this.state.time.code})
		}));
		const session = await response.json();
		const newFetchedData = this.state.fetchedData === null ? session.data : [...this.state.fetchedData, ...session.data]
		this.setState({ fetchedData: newFetchedData, fetching: false}, this.replaceJoinLayer);
	}
	// else if (this.state.area === censusBlock){
	// 	getQuery(`{${this.state.area.plural}(stateId: "${this.state.stateId.code}"){${this.state.area.id} ${this.state.variable.code}${this.state.time.code}}}`)
	// 		.then(results => this.setState({ fetchedData: results.data[this.state.area.plural] }, this.replaceJoinLayer));
	// }
	else {
		const response = await trackPromise(fetch("/graphql", {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			method: "POST",
			body: JSON.stringify({query:`{${dataArea.plural}(stateId: "${this.state.stateId.code}", ${dataArea.id}s: ${JSON.stringify(newAreas)}){${dataArea.id} ${this.state.variable.code}${this.state.time.code}}}`})
		}));
		const session = await response.json();
		const newFetchedData = this.state.fetchedData === null ? session.data[dataArea.plural] : [...this.state.fetchedData, ...session.data[dataArea.plural]]
		this.setState({ fetchedData: newFetchedData, fetching: false }, this.replaceJoinLayer);
	}
}

async fetchData() {
	console.log(this.state.variable);
	console.log(this.state.fetching);
	while(this.state.fetching === true){
		await sleep(1);
	}
	this.setState({fetching: true});
	this.setState({loading: true});
	const dataArea = zoomArea(this.state.zoom, this.state.area, this.state.dataName);
	console.log(dataArea);
	const oldAreas = this.state.fetchedData === null ? [] : this.state.fetchedData.map(d => d[this.state.area.id]);
	console.log(oldAreas);
	// Remove oldAreas from current areas
	const newAreas = this.state.areas.filter(a => !oldAreas.includes(a));
	console.log(newAreas);
	//Remove old areas from fetched data
	// But I kind of like keeping the data around
	// const oldData = this.state.fetchedData === null ? [] :this.state.fetchedData.filter(d => newAreas.includes(d[this.state.area.id]));
	if(this.state.variable === percentBroadband){
		if(newAreas.length > 0){
			const queryURL = `${getBaseURL()}/broadband_percent?area=${this.state.area.schemaName}&download=${this.state.download}&upload=${this.state.upload}&time=${this.state.time.code}`;
			console.log(queryURL);
			return fetch(queryURL, { method: "get" })
				.then((response) => response.json())

				.then(function (results) {
					return results;
				})
				.then(results => this.setState({ fetchedData: results.data, fetching: false }, this.replaceJoinLayer))

				.catch(function (error) {
					console.log(error);
				});
			}
		// const response = await trackPromise(fetch("/broadband_percent", {
		// 	headers: {
		// 		'Accept': 'application/json',
		// 		'Content-Type': 'application/json'
		// 	},
		// 	method: "POST",
		// 	body: JSON.stringify({area: this.state.area.name, areas: newAreas, download: this.state.download, upload: this.state.upload, time: this.state.time.code})
		// }));
		// const session = await response.json();
		// console.log(session);
		// const newFetchedData = this.state.fetchedData === null ? session.data : [...this.state.fetchedData, ...session.data]
		// this.setState({ fetchedData: newFetchedData, fetching: false}, this.replaceJoinLayer);
	}
	// else if (this.state.area === censusBlock){
	// 	getQuery(`{${this.state.area.plural}(stateId: "${this.state.stateId.code}"){${this.state.area.id} ${this.state.variable.code}${this.state.time.code}}}`)
	// 		.then(results => this.setState({ fetchedData: results.data[this.state.area.plural] }, this.replaceJoinLayer));
	// }
	else {
		console.log(JSON.stringify({query:`{${dataArea.plural}(${dataArea.id}s: ${JSON.stringify(newAreas)}){${dataArea.id} ${this.state.variable.code}${this.state.time.code}}}`})			)
		const response = await trackPromise(fetch("/graphql", {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			method: "POST",
			body: JSON.stringify({query:`{${dataArea.plural}(${dataArea.id}s: ${JSON.stringify(newAreas)}){${dataArea.id} ${this.state.variable.code}${this.state.time.code}}}`})
		}));
		const session = await response.json();
		console.log(session);
		const newFetchedData = this.state.fetchedData === null ? session.data[dataArea.plural] : [...this.state.fetchedData, ...session.data[dataArea.plural]]
		console.log(newFetchedData);
		this.setState({ fetchedData: newFetchedData, fetching: false }, this.replaceJoinLayer);
		// getQuery(`{${this.state.area.plural}(${this.state.area.id}s: ${JSON.stringify(this.state.areas)}){${this.state.area.id} ${this.state.variable.code}${this.state.time.code}}}`)
		// 	.then(results => this.setState({ fetchedData: results.data[this.state.area.plural] }, this.replaceJoinLayer));
	}
}


fetchData() {
	this.fetchOLS();
	if(this.state.xVariable !== percentBroadband && this.state.yVariable !== percentBroadband){
		const query = "{ " + this.state.regArea.plural + (this.state.stateId != null? '(stateId: "' + this.state.stateId.code + '")' : '') + "{" + this.state.xVariable.code + this.state.xTime.code + " " + this.state.yVariable.code + this.state.yTime.code + " name }}";
		console.log(query);
		getQuery(query)
			.then(results => this.setState({ fetchedData: results["data"][this.state.regArea.plural].map((element) => this.organizeScatterData(element)) }));
	}
	else{
		const query = getBaseURL() + "/graphql?query={ " + this.state.regArea.plural + (this.state.stateId != null? '(stateId: "' + this.state.stateId.code + '")' : '') + "{" + this.state.regArea.id + " name " + (this.state.xVariable.code !== "percentBroadband" ? this.state.xVariable.code + this.state.xTime.code + " " : "") + (this.state.yVariable.code !== "percentBroadband" ? this.state.yVariable.code + this.state.yTime.code + " " : "") + "}}";
		console.log(query);
		//If both are percent broadband for the same year, but with different down/up
		//We can't distingish which is which
		if(this.state.xVariable === percentBroadband && this.state.yVariable === percentBroadband){
			const xQuery = `${getBaseURL()}/broadband_percent?area=${this.state.regArea.name}${this.state.stateId != null ? "&state=" + this.state.stateId.code : ""}&download=${this.state.xDownload}&upload=${this.state.xUpload}&time=${this.state.xTime.code}${this.state.xTime.code === this.state.yTime.code ? "&same=true" : ""}`;
			const yQuery = `${getBaseURL()}/broadband_percent?area=${this.state.regArea.name}${this.state.stateId != null ? "&state=" + this.state.stateId.code : ""}&download=${this.state.yDownload}&upload=${this.state.yUpload}&time=${this.state.yTime.code}${this.state.xTime.code === this.state.yTime.code ? "&same=true" : ""}`;
			Promise.all([query, xQuery, yQuery].map(u => fetch(u, { method: "get" })))
				.then(function (responses) {
					return Promise.all(responses.map(function (response) {
						return response.json();
					}));
				}).then(results => this.mergeScatterData(results.map(e => e.data)));
		}
		else if(this.state.xVariable === percentBroadband && this.state.yVariable !== percentBroadband){
			const xQuery = `${getBaseURL()}/broadband_percent?area=${this.state.regArea.name}${this.state.stateId != null ? "&state=" + this.state.stateId.code : ""}&download=${this.state.xDownload}&upload=${this.state.xUpload}&time=${this.state.xTime.code}`;
			Promise.all([query, xQuery].map(u => fetch(u, { method: "get" })))
				.then(function (responses) {
					return Promise.all(responses.map(function (response) {
						return response.json();
					}));
				}).then(results => this.mergeScatterData(results.map(e => e.data)));
		}
		else if(this.state.yVariable === percentBroadband && this.state.xVariable !== percentBroadband){
			const yQuery = `${getBaseURL()}/broadband_percent?area=${this.state.regArea.name}${this.state.stateId != null ? "&state=" + this.state.stateId.code : ""}&download=${this.state.yDownload}&upload=${this.state.yUpload}&time=${this.state.yTime.code}`;
			console.log(yQuery);
			Promise.all([query, yQuery].map(u => fetch(u, { method: "get" })))
				.then(function (responses) {
					return Promise.all(responses.map(function (response) {
						const responseJson = response.json();
						return responseJson;
					}));
				}).then(results => this.mergeScatterData(results.map(e => e.data)));
		} 
	}
}*/